import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AdminManagement from './AdminManagement';
import Header from '../header/header';
import AdMdManagement from './AdMdManagement';

const Management = () => {

  const user = useSelector((state) => state.user);

  const [dropdownQuery, setDropdownQuery] = useState('name');
  const [searchInput, setSearchInput] = useState('');

  const fetchLatestSubscribersData = async () => {
    // setLoading(true);
    // try {
    // const queryParams = `?skip=${(paginationQuery.currentPage - 1) * paginationQuery.rowsPerPage}&limit=${paginationQuery.rowsPerPage}&${dropdownQuery}=${searchInput}`;
    // const response = await new WalletService().getActiveSubscribersData(user?._id, queryParams);
    // setLoading(false);
    // if (response) {
    // setLatestSubscribersData(response.subscribersList);
    // setPaginationQuery({ ...paginationQuery, totalRows: response?.totalCount });
    // }
    // } catch (error) {
    //   console.error('Error fetching latest subscribers data:', error);
    //   setLoading(false);
    // }
  };

  return (
    <div className="h-full w-full bg-[#E9F9FF]  font-jakarta">
      <div className="max-w-[1032px] mx-auto sticky top-0 mb-3 z-50">
        <Header
          dropdownQuery={dropdownQuery}
          setDropdownQuery={setDropdownQuery}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchLatestSubscribersData={fetchLatestSubscribersData}
        />
      </div>

      <div className="w-full max-w-[1240px] mx-auto  h-full">
        {user?.roleId?.name !== 'Admin' ? <AdMdManagement /> : <AdminManagement />}
      </div>
    </div>
  );
};

export default Management;
