import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';


function EditSubscriberDetails({
  subscriberAccountData,
  setSubscriberAccountData,
  setEditDetails,
}) {
  // Handle changes for fields
  const handleChange = (e) => {
    const { value, id } = e.target;
    if (id === 'zipCode' || id === 'street') {
      // Update address fields separately
      setSubscriberAccountData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [id]: value,
        },
      }));
    } else {
      // Update other fields
      setSubscriberAccountData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full mt-10">
        <FormSectionTitle heading={'Edit subscriber’s personal details'} />
        <div className="flex flex-col gap-8">
          {/* First Name and Last Name */}
          <CustomInputField
            id={'subscriberFirstName'}
            labelText={'First Name'}
            type={'text'}
            placeholder={'Enter first name'}
            value={subscriberAccountData.subscriberFirstName}
            onChange={handleChange}
          />
          <CustomInputField
            id={'subscriberLastName'}
            labelText={'Last Name'}
            type={'text'}
            placeholder={'Enter last name'}
            value={subscriberAccountData.subscriberLastName}
            onChange={handleChange}
          />

          {/* Contact Number */}
          <CustomInputField
            id={'contactNumber'}
            labelText={'Contact Number'}
            type={'number'}
            placeholder={'Enter your contact number'}
            value={subscriberAccountData.contactNumber}
            onChange={handleChange}
          />

          {/* Email */}
          <CustomInputField
            id={'email'}
            labelText={'Email'}
            type={'text'}
            placeholder={'Enter your email'}
            value={subscriberAccountData.email}
            onChange={handleChange}
          />

          {/* Zip Code */}
          <CustomInputField
            id={'zipCode'}
            labelText={'Zip Code'}
            type={'text'}
            placeholder={'Enter zip code'}
            value={subscriberAccountData.address.zipCode}
            onChange={handleChange}
          />

          {/* Street */}
          <CustomInputField
            id={'street'}
            labelText={'Street'}
            type={'text'}
            placeholder={'Enter street'}
            value={subscriberAccountData.address.street}
            onChange={handleChange}
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-between items-center mb-11 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setEditDetails(false)} // Cancel logic
          >
            Cancel
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => console.log('Update button clicked')}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
}

export default EditSubscriberDetails;
