import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../utility';
import { removeUser, updateUser } from '../slices/userSlice';
import WalletService from '../services/wallet';
import { saveAs } from 'file-saver';
import EarningList from './earning';
import Header from '../header/header';

const Earnings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [dropdownQuery, setDropdownQuery] = useState('MDN');
  const [searchInput, setSearchInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [WithdrawRequests, setWithdrawRequests] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalRequestedAmount, setTotalRequestedAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);

  const handleRequest = async (status, data) => {
    try {
      const payload = {
        withdrawalId: data._id,
        status: status,
        amount: data.amount,
      };

      await new WalletService().reviewWithdrawMoneyRequest(data.userId, payload);

      // Re-fetch data after the approve/reject action
      fetchEarningList();
    } catch (error) {
      console.log('Error approving/rejecting request:', error);
    }
  };
  async function fetchEarningList() {
    try {
      setLoading(true);
      // CHECKING IF TOKEN IS EXPIRE OR NOT
      if (Utils.checkTokenExpireOrNot()) {
        dispatch(removeUser({}));
        return;
      }
      const skip = (currentPage - 1) * rowsPerPage;
      const limit = rowsPerPage;
      if (user?.roleId?.name === 'Admin') {
        // const endpoint = `/admins/transactions?skip=${skip}&limit=${limit}`;
        const transactionsRes = await new WalletService().getWithdrawalRequestsHistory();
        const WithdrawRequest = await new WalletService().getWithdrawalRequestsNewData();
        setWithdrawRequests(WithdrawRequest);

        const totalAmount = WithdrawRequest.reduce((acc, request) => acc + request.amount, 0);
        const totalpaid = transactionsRes
          .filter((transaction) => transaction.status === 'approved') // Filter for approved transactions
          .reduce((total, transaction) => total + transaction.amount, 0); // Sum up the amounts
       
        setTotalPaidAmount(totalpaid)
        setTotalRequestedAmount(totalAmount);

        setTotalTransactions(transactionsRes?.length);
        setTransactions(transactionsRes);
      } else {
        const endpoint = `/transactions/${user?._id}?skip=${skip}&limit=${limit}`;
        const transactionsRes = await new WalletService().getTransactions(endpoint);

        setTotalTransactions(transactionsRes?.totalCount);
        setTransactions(transactionsRes?.transactions);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEarningList();
  }, [currentPage, dispatch, rowsPerPage, user?._id, user?.roleId?.name]);

  const exportAdminTransactionsToCSV = () => {
    const headers = [
      'Transaction ID',
      'Wallet ID',
      'Date & Time',
      'Description',
      'Type',
      'Amount',
      'Balance',
    ];
    const rows = transactions.map((trans) => [
      trans.transactionId === 'N/A' ? trans?._id : trans?.transactionId,
      trans?.walletId,
      `"${Utils.formatTimestamp(trans?.addedOn)}"`, // Wrap in double quotes
      `"${trans?.description}"`,
      trans?.type,
      `${trans?.type === 'Credit' ? '+' : '-'}${trans?.amount}`,
      trans?.balance,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transactions.csv');
  };

  const exportToCSV = () => {
    const headers = ['Transaction ID', 'Date & Time', 'Description', 'Type', 'Amount', 'Balance'];
    const rows = transactions.map((trans) => [
      trans.transactionId === 'N/A' ? trans?._id : trans?.transactionId,
      `"${Utils.formatTimestamp(trans?.addedOn)}"`, // Wrap in double quotes
      `"${trans?.description}"`,
      trans?.type,
      `${trans?.type === 'Credit' ? '+' : '-'}${trans?.amount}`,
      trans?.balance,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transactions.csv');
  };
  const fetchLatestSubscribersData = async () => {
    // setLoading(true);
    // try {
    //   const queryParams = `?skip=${(paginationQuery.currentPage - 1) * paginationQuery.rowsPerPage}&limit=${paginationQuery.rowsPerPage}&${dropdownQuery}=${searchInput}`;
    //   const response = await new WalletService().getActiveSubscribersData(user?._id, queryParams);
    //   setLoading(false);
    //   if (response) {
    //     setLatestSubscribersData(response.subscribersList);
    //     setPaginationQuery({ ...paginationQuery, totalRows: response?.totalCount });
    //   }
    // } catch (error) {
    //   console.error('Error fetching latest subscribers data:', error);
    //   setLoading(false);
    // }
  };

  return (
    <div className="h-full w-full bg-[#E9F9FF]  font-jakarta">
      <div className="max-w-[1032px] mx-auto sticky top-0 mb-3 z-50">
        <Header
          dropdownQuery={dropdownQuery}
          setDropdownQuery={setDropdownQuery}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchLatestSubscribersData={fetchLatestSubscribersData}
        />
      </div>
      <EarningList
        loading={loading}
        transactions={transactions}
        WithdrawRequests={WithdrawRequests}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalTransactions={totalTransactions}
        totalRequestedAmount={totalRequestedAmount}
        totalPaidAmount={totalPaidAmount}
        exportAdminTransactionsToCSV={exportAdminTransactionsToCSV}
        exportToCSV={exportToCSV}
        handleRequest={handleRequest}
        // earningCardsData={earningCardsData}
      />
    </div>
  );
};

export default Earnings;
