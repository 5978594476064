import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';

import Dropdown from '../common/onboarding/Dropdown';
import { familyOption } from '../constants';
import CustomInputField from '../common/onboarding/CustomInputField ';

function FamilyDetails({ selectedTab, formData, setFormData, handleChange, setSelectedTab }) {
  const handleFamilySelection = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      family: value,
    }));
  };
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle heading={'Provide family details'} />
        <div className="flex flex-col gap-8">
          <Dropdown
            label="Family"
            placeHolder="Select a family"
            selectedValue={formData.family}
            options={familyOption}
            onSelect={handleFamilySelection}
          />
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="name" className="text-Neutral-500">
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
          </div>
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 py-3 px-8 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default FamilyDetails;
