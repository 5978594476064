import React, { useState } from 'react';
import InformationWrapper from '../common/onboarding/InformationWrapper';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import BankDetails from './bank-details';
import ZelleDetails from './zelle-details';
import WireTransferDetails from './wire-details';
import StepIndicator from '../common/StepIndicator';

function PaymentDetails({
  selectedTab,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  activePaymentDetailsTab,
  setActivePaymentDetailsTab,
  totalSteps=5
}) {
  const [error, setError] = useState('');


  const isBankDetailsCompleted = formData?.accountNumber && formData?.routingNumber;
  const isWireDetailsCompleted = formData?.wireTransferAccountNumber && formData?.wireTransferRoutingNumber;
  const isZelleDetailsCompleted = formData?.zelleDetails;  

  const handleNext = () => {
    if (isBankDetailsCompleted) {
      setActivePaymentDetailsTab(1);
      setError(''); 
    } else if (isWireDetailsCompleted) {
      setActivePaymentDetailsTab(2);
      setError(''); 
    } else if (isZelleDetailsCompleted) {
      setActivePaymentDetailsTab(3);
      setError('');
    } else if (activePaymentDetailsTab === 0) {
      setError('Please select a payment method');
    }
  };
  return (
    <>
      {activePaymentDetailsTab == 0 && (
        <>
          <div className="w-full flex flex-col gap-12">
            <FormSectionTitle
              heading={'Provide personal details'}
              subHeading={'Enter the subscribers residential details'}
            />
            <div className='flex flex-col gap-4 items-start'>
              <div className="grid grid-cols-2 gap-4">
                <InformationWrapper
                  text={'Bank account'}
                  icon={'/Bank.svg'}
                  alt={'bank icon'}
                  onclick={() => setActivePaymentDetailsTab(1)}
                  completed={isBankDetailsCompleted}
                />
                <InformationWrapper
                  text={'Wire Transfer'}
                  icon={'/move_down.svg'}
                  alt={'bank icon'}
                  onclick={() => setActivePaymentDetailsTab(2)}
                  completed={isWireDetailsCompleted}
                />
                <InformationWrapper
                  text={'Connect Zelle'}
                  icon={'/zelle.svg'}
                  alt={'bank icon'}
                  onclick={() => setActivePaymentDetailsTab(3)}
                  completed={isZelleDetailsCompleted}
                />
              </div>
              {error && <p className="text-Error-500">{error}</p>}
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-0 justify-between items-center absolute w-[480px] bottom-[10%] lg:bottom-[8%]">
            <div className='lg:hidden'>
          <StepIndicator currentStep={selectedTab} totalSteps={totalSteps} />
              </div>
              <div className="flex justify-between items-center w-full">
              <button
                className="flex justify-center items-center rounded-3xl bg-white w-25 h-12 font-bold text-base text-Neutral-900"
                onClick={() => setSelectedTab((prev) => prev - 1)}>
                Back
              </button>
              <button
                className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
                onClick={handleNext}>
                Next
                </button>
                </div>
            </div>
          </div>
        </>
      )}

      {activePaymentDetailsTab === 1 && (
        <BankDetails
          selectedTab={selectedTab}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          setSelectedTab={setSelectedTab}
          setActivePaymentDetailsTab={setActivePaymentDetailsTab}
        />
      )}
      {activePaymentDetailsTab === 2 && (
        <WireTransferDetails
        selectedTab={selectedTab}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          setSelectedTab={setSelectedTab}
          setActivePaymentDetailsTab={setActivePaymentDetailsTab}
        />
      )}
      {activePaymentDetailsTab === 3 && (
        <ZelleDetails
        selectedTab={selectedTab}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          setSelectedTab={setSelectedTab}
          setActivePaymentDetailsTab={setActivePaymentDetailsTab}
        />
      )}
    </>
  );
}

export default PaymentDetails;
