import React, { useState, useEffect } from 'react';

import ProductInfo from './productCategories';
import Header from '../header/header';



const Product = () => {
  const [searchInput, setSearchInput] = useState('');
  const [dropdownQuery, setDropdownQuery] = useState('MDN');
  const fetchLatestSubscribersData = async () => {
    // setLoading(true);
    // try {
    //   const queryParams = `?skip=${(paginationQuery.currentPage - 1) * paginationQuery.rowsPerPage}&limit=${paginationQuery.rowsPerPage}&${dropdownQuery}=${searchInput}`;
    //   const response = await new WalletService().getActiveSubscribersData(user?._id, queryParams);
    //   setLoading(false);
    //   if (response) {
    //     setLatestSubscribersData(response.subscribersList);
    //     setPaginationQuery({ ...paginationQuery, totalRows: response?.totalCount });
    //   }
    // } catch (error) {
    //   console.error('Error fetching latest subscribers data:', error);
    //   setLoading(false);
    // }
  };
  return (
    <div className="h-full w-full bg-[#E9F9FF]  font-jakarta">
 <div className="max-w-[1032px] mx-auto sticky top-0 mb-3 z-50">
        <Header
          dropdownQuery={dropdownQuery}
          setDropdownQuery={setDropdownQuery}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchLatestSubscribersData={fetchLatestSubscribersData}
        />
      </div>
      <ProductInfo />
    </div>
  );
};

export default Product;
