import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EditPersonalDetails from './edit-personal-details';
import EditBankDetails from './edit-bank-details';
import EditWireDetails from './edit-wire-details';
import EditZelleDetails from './edit-zelle-details';
import PopupComponent from '../common/popup';

function Profile() {
  const user = useSelector((state) => state?.user);
  const userRole = user?.roleId?.name;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };



  const [selectTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({
    personalDetails: {
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      gender: user?.gender || '',
      dob: user?.dob || '',
      postalCode: user?.postalCode || '',
    },

    paymentDetails: {
      bankName: user?.bankName || '',
      paymentMethods: user?.paymentMethods || 'Bank',
      zelleDetails: user?.zelleDetails || '',
      accountNumber: user?.accountNumber || '',
      routingNumber: user?.routingNumber || '',
      accountType: user?.accountType || '',
      accountHolderName: user?.accountHolderName || '',
      wireTransferMethodType: user?.wireTransferMethodType || 'Domestic',
      wireTransferRecipientName: user?.wireTransferRecipientName || '',
      wireTransferBankName: user?.wireTransferBankName || '',
      wireTransferBankAddress: user?.wireTransferBankAddress || '',
      wireTransferAccountNumber: user?.wireTransferAccountNumber || '',
      wireTransferRoutingNumber: user?.wireTransferRoutingNumber || '',
      iban: user?.iban || '',
      bicCode: user?.bicCode || '',
      isPaymentAdded: user?.isPaymentAdded || false,
    },
  });
  const isBankDetailsCompleted =
    formData?.accountNumber &&
    formData?.routingNumber &&
    formData?.accountType &&
    formData?.accountHolderName;
  const isWireDetailsCompleted =
    formData?.paymentDetails?.wireTransferRecipientName && formData?.paymentDetails?.wireTransferBankName;
  const isZelleDetailsCompleted = formData?.zelleDetails;

  return (
    <div className={`flex min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta `}>
      <>
        {selectTab === 0 && (
          <div className="max-w-[1232px] w-full flex flex-col gap-8 items-start mt-5 py-6 px-8 mx-auto">
            {/* back button  */}
            <div className="flex gap-2 py-2 px-4  items-center rounded-3xl bg-white">
              <img src="/back-arrow-icon.svg" alt="back-arrow-icon" />
              <p className="text-Neutral-900 font-bold">Back</p>
            </div>
            {/* profile picture section  */}
            <div className="flex gap-3 items-center">
              {user && user?.image ? (
                <img
                  src={user?.image}
                  alt={user?.name}
                  className="w-16 h-16  object-cover cursor-pointer"
                />
              ) : (
                <div
                  className={`w-16 h-16 ${userRole === 'Admin' ? 'bg-Error-500' : userRole === 'Master Distributor' ? 'bg-purple-500' : 'bg-Primary-500'}  rounded-full flex items-center justify-center text-white text-3xl font-extrabold`}>
                  {user?.name
                    ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                        .split(' ')
                        .map((word) => word[0])
                        .join('')
                        .substring(1, 2)
                        .toUpperCase()}`
                    : ''}
                </div>
              )}
              <div className="flex flex-col gap-1 items-start">
                <div className="text-[25px] font-extrabold text-Neutral-900">{user?.name}</div>
                <div
                  className={`${userRole === 'Admin' ? 'bg-Error-50 text-Error-500' : userRole === 'Master Distributor' ? 'bg-purple-50 text-purple-500' : 'text-Primary-500  bg-Primary-100'}  w-fit px-3 py-2 rounded-2xl font-bold`}>
                  {user?.roleId?.name}
                </div>
              </div>
            </div>
            {/* personal details section  */}
            <div className="w-full flex flex-col gap-4">
              <div className="flex items-center justify-between w-full">
                <p className="font-extrabold text-Neutral-900 text-2xl">Personal details</p>
                <button
                  className="flex gap-1 items-center py-2 px-4 font-bold text-Primary-500 rounded-3xl bg-white"
                  onClick={() => setSelectedTab(1)}>
                  <img src="/edit-icon.svg" alt="edit-icon" /> Edit
                </button>
              </div>
              <div className="grid grid-cols-3 px-6 gap-4">
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Name</span>
                  <span className="text-base text-Neutral-900">{user?.name || '__'}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Email</span>
                  <span className="text-base text-Neutral-900">{user?.email || '__'}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Phone</span>
                  <span className="text-base text-Neutral-900">{user?.phone || '__'}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Address</span>
                  <span className="text-base text-Neutral-900">{user?.address || '__'}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Gender</span>
                  <span className="text-base text-Neutral-900">{user?.gender || '__'}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm text-Neutral-400">Date of birth</span>
                  <span className="text-base text-Neutral-900">{user?.dob || '__'}</span>
                </div>
              </div>
            </div>
            {/* payment details  */}
            {user?.roleId?.name !== 'Admin' && (
              <div className="flex flex-col gap-4 w-full">
                <p className="font-extrabold text-Neutral-900 text-2xl">Payment details</p>
                {/* bank details  */}
                <div className="bg-white rounded-2xl flex flex-col items-start p-6 gap-6 w-full">
                  <div className="flex items-center gap-4">
                    <img src="/Bank-dark-icon.svg" alt="bank-icon" />
                    <p className="text-Neutral-900 font-semibold text-xl">Bank details</p>
                  </div>
                  {isBankDetailsCompleted ? (
                    <button
                      className="flex  items-center py-2 px-4 font-bold bg-Error-500 rounded-3xl text-white"
                      onClick={handleOpenModal}>
                      Delete
                    </button>
                  ) : (
                    <button
                      className="flex gap-1 items-center py-2 px-4 font-bold rounded-3xl bg-Primary-400 text-black"
                      onClick={() => setSelectedTab(2)}>
                      Connect Bank
                    </button>
                  )}
                </div>
                {/* zelle details  */}

                <div className="bg-white rounded-2xl flex flex-col items-start p-6 gap-6 w-full">
                  <div className="flex items-center gap-4">
                    <img src="/zelle-dark-icon.svg" alt="bank-icon" />
                    <p className="text-Neutral-900 font-semibold text-xl">Zelle</p>
                  </div>
                  {formData.paymentDetails.zelleDetails && (
                    <div className="flex items-center justify-between w-full">
                      <p className="font-bold text-Neutral-700">
                        {formData.paymentDetails.zelleDetails}
                      </p>
                      <button
                        className="flex gap-1 items-center py-2 px-4 font-bold text-Primary-500 rounded-3xl bg-white"
                        onClick={() => setSelectedTab(4)}>
                        <img src="/edit-icon.svg" alt="edit-icon" /> Edit
                      </button>
                    </div>
                  )}
                  {formData.paymentDetails.zelleDetails ? (
                    <button
                      className="flex  items-center py-2 px-4 font-bold bg-Error-500 rounded-3xl text-white"
                      onClick={handleOpenModal}>
                      Delete
                    </button>
                  ) : (
                    <button
                      className="flex gap-1 items-center py-2 px-4 font-bold rounded-3xl bg-Primary-400 text-black"
                      onClick={() => setSelectedTab(4)}>
                      Connect Zelle
                    </button>
                  )}
                </div>
                {/* wire transfer  */}

                <div className="bg-white rounded-2xl flex flex-col items-start p-6 gap-6 w-full">
                  <div className="flex items-center gap-4">
                    <img src="/wire-transfer-dark-icon.svg" alt="wire-transfer-icon" />
                    <p className="text-Neutral-900 font-semibold text-xl">Wire transfer</p>
                  </div>
                  {isWireDetailsCompleted ? (
                    <button
                      className="flex  items-center py-2 px-4 font-bold bg-Error-500 rounded-3xl text-white"
                      onClick={handleOpenModal}>
                      Delete
                    </button>
                  ) : (
                    <button
                      className="flex gap-1 items-center py-2 px-4 font-bold rounded-3xl bg-Primary-400 text-black"
                      onClick={() => setSelectedTab(3)}>
                      Connect Wire
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {selectTab === 1 && (
          <EditPersonalDetails
            formData={formData}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectTab === 2 && (
          <EditBankDetails
            formData={formData.paymentDetails}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectTab === 3 && (
          <EditWireDetails
            formData={formData.paymentDetails}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectTab === 4 && (
          <EditZelleDetails
            formData={formData.paymentDetails}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
          />
        )}
        {isModalOpen && (
          <PopupComponent>
            <img src="red-close-icon.svg" alt="close-Icon" className="w-16 h-16" />
            <div className="flex flex-col items-center gap-4 w-full">
              <h5 className="text-Neutral-900 font-semibold text-xl">Are your sure to delete?</h5>
              <p className="text-Neutral-500 text-base ">This can’t be undone. If you want, you can add again later.</p>
            </div>

            <div className="flex items-center justify-between gap-2 w-full">
              <button
                onClick={() => handleCloseModal()}
                className="py-3 basis-1/2 px-6 text-center bg-Primary-100 rounded-3xl text-black text-base font-bold">
                No
              </button>
              <button
                className="py-3 basis-1/2 px-6 text-center whitespace-nowrap bg-Error-500 rounded-3xl text-white text-base font-bold">
                Yes delete
              </button>
            </div>
          </PopupComponent>
        )}
      </>
    </div>
  );
}

export default Profile;
