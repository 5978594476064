import React from 'react';
import { streetDirectionOption } from '../constants';
import CustomInputField from '../common/onboarding/CustomInputField ';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import Dropdown from '../common/onboarding/Dropdown';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';

function AddressDetails({ formData, setFormData, handleChange, setSelectedTab, setSubscriber }) {
  const user = useSelector((state) => state.user);

  const handleStreetDirectionSelection = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      streetDirection: value,
    }));
  };

  const handleNext = async () => {
    try {
      const response = await new WalletService().registerSubscriber({
        ...formData,
        mvnoId: 11,
        addedBy: user?._id,
      });

      if (response) {
        setSubscriber(response);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Subscriber’s address details'}
          subHeading={'Enter the subscribers residential details'}
        />
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'zipCode'}
            labelText={'Zip code'}
            type={'number'}
            placeholder={'Enter your zip code'}
            value={formData.zipCode}
            onChange={handleChange}
          />
          <CustomInputField
            id={'city'}
            labelText={'City'}
            type={'text'}
            placeholder={'Enter your city'}
            value={formData.city}
            onChange={handleChange}
          />
          <CustomInputField
            id={'state'}
            labelText={'State'}
            type={'text'}
            placeholder={'Enter your state'}
            value={formData.state}
            onChange={handleChange}
          />
          <CustomInputField
            id={'streetName'}
            labelText={'Street'}
            type={'text'}
            placeholder={'Enter your street'}
            value={formData.streetName}
            onChange={handleChange}
          />
          <Dropdown
            label="Street direction (Optional)"
            placeHolder="Select a street direction"
            selectedValue={formData.streetDirection}
            options={streetDirectionOption}
            onSelect={handleStreetDirectionSelection}
          />
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default AddressDetails;
