import React, { useEffect, useState } from 'react';
import FormDashboard from './form-dashboard';
import PersonalDetails from './personal-details';
import PaymentDetails from './payment-details';
import IdentityDetails from './identity-details';
import AddressDetails from './address-details';
import Sidebar from './Sidebar';
import Declaration from './declaration';
import { useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import {
  toggleSkip,
  updateFormIndexCompleted,
  updateOnboardingFormIndex,
} from '../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import CancelPopup from '../common/onboarding/CancelPopup';

const NewAddADComponent = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(user.currentOnboardingFormIndex || 0);
  const [activePaymentDetailsTab, setActivePaymentDetailsTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleYes = () => {
    dispatch(toggleSkip(true));
    dispatch(updateOnboardingFormIndex(selectedTab));
    navigate('/dashboard');
  };

  const [formData, setFormData] = useState({
    personalDetails: {
      name: user?.name || '',
      gender: user?.gender || '',
      genderRefer: user?.genderRefer || '',
      dob: user?.dob || '',
      postalCode: user?.postalCode || '',
    },
    identityDetails: {
      identities: user?.identities || [],
    },
    addressDetails: {
      proofOfAddress: user?.proofOfAddress || [],
    },
    paymentDetails: {
      bankName: user?.bankName || '',
      paymentMethods: user?.paymentMethods || 'Bank',
      zelleDetails: user?.zelleDetails || '',
      accountNumber: user?.accountNumber || '',
      routingNumber: user?.routingNumber || '',
      accountType: user?.accountType || '',
      accountHolderName: user?.accountHolderName || '',
      wireTransferMethodType: user?.wireTransferMethodType || 'Domestic',
      wireTransferRecipientName: user?.wireTransferRecipientName || '',
      wireTransferBankName: user?.wireTransferBankName || '',
      wireTransferBankAddress: user?.wireTransferBankAddress || '',
      wireTransferAccountNumber: user?.wireTransferAccountNumber || '',
      wireTransferRoutingNumber: user?.wireTransferRoutingNumber || '',
      iban: user?.iban || '',
      bicCode: user?.bicCode || '',
      isPaymentAdded: user?.isPaymentAdded || false,
    },
    declarationDetails: {
      signatureName: user?.signatureName || '',
      signature: user?.signature || '',
      isTermsAccepted: user?.isTermsAccepted || false,
    },
  });

  const handleChange = (section, e) => {
    const { id, value } = e.target;
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [id]: value,
      },
    }));
  };

  useEffect(() => {
    dispatch(updateOnboardingFormIndex(selectedTab));
    if (selectedTab > user?.highestFormIndexCompleted) {
      dispatch(updateFormIndexCompleted(selectedTab));
    }
  }, [selectedTab]);

  return (
    <div className="mx-auto w-full  min-h-screen flex  bg-[#E9F9FF] relative font-jakarta">
      <div
        className="absolute top-8 right-8 p-3 rounded-lg bg-white hover:cursor-pointer"
        onClick={handleOpenModal}>
        <MdClose className="w-5 h-5" />
      </div>
      <div className="hidden lg:block fixed top-0 left-0 h-full lg:w-[22.5rem] xxl:w-[30rem] max-w-[480px] z-40 bg-white overflow-y-auto custom-scrollbar">
        <Sidebar
          highestFormIndexCompleted={user?.highestFormIndexCompleted}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          activePaymentDetailsTab={activePaymentDetailsTab}
          setActivePaymentDetailsTab={setActivePaymentDetailsTab}
        />
      </div>
      <div className="flex-1 flex justify-center lg:ml-[22.5rem] xxl:ml-[480px] mt-[5%] xxl:mt-[8%] relative">
        <div className="w-full max-w-[480px]">
          {selectedTab === 0 && (
            <FormDashboard
              user={user}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 1 && (
            <PersonalDetails
            selectedTab={selectedTab}
              formData={formData.personalDetails}
              setFormData={setFormData}
              handleChange={(e) => handleChange('personalDetails', e)}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 2 && (
            <IdentityDetails
            selectedTab={selectedTab}
              formData={formData.identityDetails}
              setFormData={setFormData}
              handleChange={(e) => handleChange('identityDetails', e)}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 3 && (
            <AddressDetails
            selectedTab={selectedTab}
              formData={formData.addressDetails}
              setFormData={setFormData}
              handleChange={(e) => handleChange('addressDetails', e)}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === 4 && (
            <PaymentDetails
            selectedTab={selectedTab}
              formData={formData.paymentDetails}
              setFormData={setFormData}
              handleChange={(e) => handleChange('paymentDetails', e)}
              setSelectedTab={setSelectedTab}
              activePaymentDetailsTab={activePaymentDetailsTab}
              setActivePaymentDetailsTab={setActivePaymentDetailsTab}
            />
          )}

          {selectedTab === 5 && (
            <Declaration
              formData={formData.declarationDetails}
              setFormData={setFormData}
              handleChange={(e) => handleChange('declarationDetails', e)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
          {/* {selectedTab === 6 && <FormSubmitted />} */}
        </div>
      </div>
      {isModalOpen && <CancelPopup handleYes={handleYes} handleNo={handleCloseModal} />}
    </div>
  );
};

export default NewAddADComponent;
