import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa'; // Import FaCheck for the checkmark icon

function Sidebar({ selectedTab, setSelectedTab }) {
  const [tabDetails, setTabDetails] = useState(['Select method', 'Withdraw amount', 'Confirm']);
  return (
    <>
      <div className="flex flex-col h-full w-full  px-10 pb-10 lg:px-16 lg:py-6 relative">
        <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="max-w-[171px] w-full" />

        <div className="flex flex-col gap-4 lg:mt-12 mt-8">
          {tabDetails.map((item, index) => (
            <div key={index}>
              <div className="flex items-center gap-4 py-3 cursor-pointer relative">
                <div
                  className={`h-6 w-6 border-2 box-border rounded-full text-sm font-bold flex justify-center items-center ${
                    selectedTab === index 
                      ? 'border-[#009DFF] text-[#009DFF]' // Blue circle for current tab
                      : selectedTab > index 
                        ? 'border-green-600 text-white bg-green-600' // Green checkmark for completed tab
                        : 'border-Neutral-100 text-Neutral-500' // Grey for incomplete
                  }`}>
                  {selectedTab > index  ? <FaCheck /> : index + 1}
                  {/* Display checkmark or number */}
                </div>

                <p
                  className={`text-base ${selectedTab === index  ? 'text-Primary-500 font-bold' : ' text-Neutral-200 font-normal'}`}>
                  {item}
                </p>

                { selectedTab === index  && (
                  <img
                    src="/onboarding-sidebar-right-arrow.svg"
                    alt="Right-Arrow Icon"
                    className="h-6 w-6 hover:opacity-80 ml-auto"
                  />
                )}
              </div>
            </div>
          ))}

        
        </div>
      </div>
    </>
  );
}

export default Sidebar;
