import React from 'react';

const CancelPopup = ({ handleNo, handleYes }) => {
  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="h-[376px] w-[480px] p-[48px] bg-[#FFFFFF] rounded-[24px] flex flex-col items-center gap-[30px] font-jakarta">
        <img
          src="./onboarding-warning-circle-icon.svg"
          alt="Warning-Icon"
          className="h-[64px] w-[64px] rounded-full"
        />

        <div className="flex flex-col gap-[8px]">
          <h3 className="text-[#141415] text-[20px] text-center font-semibold">
            Are you sure to cancel?
          </h3>
          <p className="text-[#6C6F75] text-[16px] text-center font-normal">
            Don't worry the information you submitted are saved. When you get back, you can start
            where you left.
          </p>
        </div>

        <div className="flex items-center gap-[8px]">
          <button
            onClick={() => handleNo()}
            className="h-[48px] w-[188px] bg-[#EBF7FF] rounded-[24px] text-[#000000] text-[16px] font-extrabold">
            No
          </button>
          <button
            onClick={() => handleYes()}
            className="h-[48px] w-[188px] bg-[#F83A5D] rounded-[24px] text-[#FFFFFF] text-[16px] font-extrabold">
            Yes cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelPopup;
