import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import Cookies from '../managers/sessionManager';
import Utils from '../utility';
import { updateUser } from '../slices/userSlice';
import UserService from '../services/user';
import { TbLoader3 } from 'react-icons/tb';
import CenterWrapper from '../common/CenterWrapper';
import axios from 'axios';

function InitialSignup({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setLoading(true);

        // OAuth token response (get access and refresh tokens)
        const { code } = tokenResponse;

        // Exchange authorization code for tokens (using your backend API or Google API)
        const tokensResponse = await axios.post(
          `${process.env.REACT_APP_USER_MICROSERVICE}/auth/google`,
          { code }
        );
        const { tokens, decoded } = tokensResponse.data.responseData;

        // const decoded = jwtDecode(tokens);
        localStorage.setItem('ACCESS_TOKEN', tokens?.id_token);
        new Cookies().set('ACCESS_TOKEN', tokens?.id_token);

        const userResponse = await new UserService().getUserByEmail(decoded?.email);
        const userId = userResponse?._id;
        const updateUserData = {
          name: decoded?.name,
          firstName: decoded?.given_name,
          lastName: decoded?.family_name,
          emailVerified: decoded?.email_verified,
        };

        if (userResponse?.roleId?.name === 'Master Distributor') updateUserData.status = 'active';

        await new UserService().updateUser(userId, updateUserData);

        // Update Redux state with the user data
        dispatch(updateUser({ ...decoded, ...userResponse, isLoggedIn: true }));
        // Navigate to the dashboard
        navigate('/dashboard');
      } catch (error) {
        Utils.failureToastMessage(error?.response?.data?.responseData?.message);
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      Utils.failureToastMessage('Login Failed');
    },
    flow: 'auth-code', // Use auth-code flow to get authorization code
  });

  return (
    <>
      <div className="w-full min-h-screen flex bg-white font-jakarta">
        <div className="xxl:max-w-[480px] hidden lg:flex lg:max-w-[360px] w-full  justify-between flex-col lg:px-10 lg:pt-4 lg:pb-10 xxl:px-16 xxl:py-6">
          <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="  max-w-[171px] w-full" />

          <img
            src="/sidebar-login-image.png"
            alt="desktop-image"
            className="lg:max-w-[232px] xxl:max-w-[432px]"
          />
        </div>
        <CenterWrapper>
          {
            <div className=" max-h-[182px] w-full max-w-[480px] h-full flex flex-col justify-center items-center gap-8">
              <h3 className="text-[39px] mt-[58px] font-extrabold font-jakarta text-center leading-[50.7px] -tracking-[0.5%]">
                {type === 'signup' ? (
                  'Sign up as distributor'
                ) : (
                 'Run your business anytime, anywhere with EZ Mobile Portal.'
                )}
              </h3>

              {loading ? (
                <div className="flex items-center">
                  <TbLoader3 className="animate-spin text-black h-13 w-13" />
                </div>
              ) : (
                <div
                  onClick={login}
                  className="flex items-center gap-2 py-3 pl-3 pr-11 w-[339px] h-[48px] bg-[#1D2224]  text-[#FDFDFD] rounded-[44px] hover:bg-black cursor-pointer">
                  <img src="/google-icon.svg" alt="Google Logo" className="w-6 h-6" />
                  <p className="text-base font-semibold leading-6 font-jakarta text-center text-[#FDFDFD] w-[251px] h-[24px]">
                    Sign in with Google
                  </p>
                </div>
              )}
            </div>
          }
        </CenterWrapper>
      </div>
    </>
  );
}

export default InitialSignup;
