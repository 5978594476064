import React, { useEffect, useMemo, useState } from 'react';
import InformationWrapper from '../common/onboarding/InformationWrapper';
import { FormTabs } from '../constants';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import { useDispatch } from 'react-redux';
import { updateFormIndexCompleted } from '../slices/userSlice';

function FormDashboard({
  user,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
}) {
  const dispatch = useDispatch();
  const { completed, incomplete } = useMemo(() => {
    const completedTabs = [];
    const incompleteTabs = [];

    // Check each tab in FormTabs and compare with formData
    FormTabs.forEach((tab) => {
      switch (tab.text) {
        case 'Personal details':
          if (
            formData?.personalDetails.name &&
            formData?.personalDetails.gender &&
            formData?.personalDetails.dob &&
            formData.personalDetails.postalCode
          ) {
            completedTabs.push(tab);
          } else {
            incompleteTabs.push(tab);
          }
          break;
        case 'Identity details':
          if (formData?.identityDetails.identities?.length > 0) {
            completedTabs.push(tab);
          } else {
            incompleteTabs.push(tab);
          }
          break;
        case 'Proof of address':
          if (formData?.addressDetails.proofOfAddress?.length > 0) {
            completedTabs.push(tab);
          } else {
            incompleteTabs.push(tab);
          }
          break;
        case 'Payment details':
          if (formData?.paymentDetails.isPaymentAdded) {
            completedTabs.push(tab);
          } else {
            incompleteTabs.push(tab);
          }
          break;
        case 'Declaration':
          if (
            formData?.declarationDetails.signature &&
            formData?.declarationDetails.signatureName
          ) {
            completedTabs.push(tab);
          } else {
            incompleteTabs.push(tab);
          }
          break;
        default:
          break;
      }
    });

    return { completed: completedTabs, incomplete: incompleteTabs };
  }, [formData]); // Dependencies - recalculate if formData changes

  // Dispatch the number of completed tabs only when completed.length changes
  useEffect(() => {
    dispatch(updateFormIndexCompleted(completed.length));
  }, [completed.length, dispatch]); // Correct dependency array

  const isFirstTime = completed.length === 0;
  return (
    <>
      <div className="w-full flex my-auto flex-col gap-12">
      <img src="./onboarding-form-dashboard-banner.svg" alt="Onboarding-Banner" className='h-[266px] w-[266px] lg:hidden' />
        <FormSectionTitle
          heading={
            isFirstTime ? 'Fill the form to operate all features of ezmobile' : 'Welcome back!'
          }
          subHeading={
            isFirstTime
              ? 'Please provide your information to operate ezmobile features. Tap to continue.'
              : 'Fill the form to operate all features of ezmobile'
          }
        />
        {/* Display "Need to complete" sections */}
        {incomplete.length > 0 && (
          <div className="flex flex-col gap-4">
            <p className=" text-[#6C6F75]">Need to complete</p>
            <div className="grid grid-cols-2 gap-4">
              {incomplete.map(({ text, icon }) => (
                <InformationWrapper key={icon} text={text} icon={icon} />
              ))}
            </div>
          </div>
        )}

        {/* Display "Completed" sections */}
        {completed.length > 0 && (
          <div className="flex flex-col gap-4">
            <p className="text-[#6C6F75]">Completed</p>
            <div className="grid grid-cols-2 gap-4">
              {completed.map(({ text, icon }) => (
                <InformationWrapper key={icon} text={text} icon={icon} completed />
              ))}
            </div>
          </div>
        )}
        <button
          onClick={() => setSelectedTab((prev) => prev + 1)}
          className="flex justify-center items-center rounded-3xl py-3 bg-[#39B8E4]">
          <p className="font-bold text-base leading-6 cursor-pointer">Continue</p>
        </button>
      </div>
    </>
  );
}

export default FormDashboard;
