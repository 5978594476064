const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="flex mx-auto gap-2 items-center">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className="w-4 h-[6px] rounded-[10px]"
        >
          {/* Conditionally render the blue inner div for the current step */}
          {index + 1 === currentStep && (
            <div className="w-4 h-full rounded-[10px] bg-Primary-500" />
          )}
          {/* Full blue background for completed steps */}
          {index + 1 < currentStep && (
            <div className="w-full h-full bg-Success-400 rounded-[10px]" />
          )}
          {index + 1 > currentStep && (
            <div className="w-full h-full bg-Primary-200 rounded-[10px]" />
          )}
        </div>
      ))}
    </div>
  );
};
export default StepIndicator;
