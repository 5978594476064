import { useSelector } from 'react-redux';

const WelcomeCard = ({ responseData }) => {
  const user = useSelector((state) => state.user);
  console.log('Response Data:', responseData);
  console.log('Subscribers Today:', responseData?.subscribersToday);
  console.log('AD Today:', responseData?.adToday);

  return (
    <div className="w-full h-full bg-[#86E0FF] rounded-2xl pr-4 flex justify-between items-center">
      <div className="flex flex-col gap-4 p-6">
        <h3 className="text-2xl text-Neutral-900 font-extrabold">Welcome back {user.name}!</h3>

        <div className="flex items-center gap-6">
          <div className="flex flex-col gap-[2px]">
            <h4 className="text-lg text-Neutral-900 font-bold">
              {user?.roleId?.name === 'Authorized Distributor'
                ? responseData?.subscribersToday != null
                  ? responseData.subscribersToday
                  : responseData?.adToday != null
                    ? responseData.adToday
                    : '_'
                : responseData?.adToday != null
                  ? responseData.adToday
                  : '_'}
            </h4>
            <p className="text-sm text-Neutral-700 font-normal">
              {user?.roleId?.name === 'Authorized Distributor'
                ? 'Subscribed today'
                : 'AD invited today'}
            </p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <h4 className="text-lg text-Neutral-900 font-bold">
              {user?.roleId?.name === 'Authorized Distributor'
                ? responseData?.earnedToday !== undefined && responseData?.earnedToday !== null
                  ? `$${responseData.earnedToday}`
                  : '_'
                : responseData?.Signeduptoday !== undefined && responseData?.Signeduptoday !== null
                  ? responseData.Signeduptoday
                  : '_'}
            </h4>
            <p className="text-sm text-Neutral-700 font-normal">
              {user?.roleId?.name === 'Authorized Distributor' ? 'Earned today' : 'Signed up today'}
            </p>
          </div>
        </div>

        <div
          className="py-2 px-3 mr-auto
       bg-Primary-50 flex justify-center items-center text-Primary-600 text-base font-normal rounded-[37px]">
          {responseData?.percentIncreaseADYesterday || '_'}% than yesterday
        </div>
      </div>
      <img
        src="./dashboard-welcome-banner.svg"
        alt="Dashboard-Welcome Banner"
        className="w-full max-w-[271px]"
      />
    </div>
  );
};
export default WelcomeCard;
