import React from 'react';
import { MdDone } from 'react-icons/md';

function InformationWrapper({ text, icon, alt, completed, onclick }) {
  return (
    <div
      onClick={onclick ? onclick : null}
      className={`cursor-pointer w-233px h-14 xl:h-12 flex 2xl:h-14 justify-between items-center hover:bg-Primary-100 rounded-2xl p-4  ${completed ? 'bg-Primary-100' : 'bg-white'}`}>
      <p className="font-semibold w-40 text-base leading-6 font-jakarta text-Neutral-900">{text}</p>
      {completed ? <MdDone className="text-Success-400 w-6 h-6" /> : <img src={icon} alt={alt} />}
    </div>
  );
}

export default InformationWrapper;
