import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import MessageWrapper from '../common/MessageWrapper';
import SuccessMessage from '../message-pages/success-message';

function ProtectedRoutes({ children }) {
  const user = useSelector((state) => state.user);
  const location = useLocation();

  // Redirect to login if the user is not logged in
  if (!user?.isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Allow access if the user is an admin
  if (user?.roleId?.name === 'Admin') return children;

  // Check if the token is accepted, if not show a message
  if ('tokenAccepted' in user && !user.tokenAccepted) {
    console.log('inside token');
    return (
      <MessageWrapper
        heading={'The invitation is not Accepted.'}
        subheading={'Please accept our invitation first'}
        isError={true}
      />
    );
  }

  // Check for approval and render success message if needed
  if ('isApprove' in user && !user.isApprove && user.roleId?.name !== 'Master Distributor') {
    return  <Navigate to="/pending-approval" replace />;
  }

  // Check if user information is submitted or skipped
  if (!user.isInformationSubmitted && !user.isSkip) {
    if (user?.roleId?.name === 'Authorized Distributor') {
      // Only navigate to "/ad" if we are not already on that route
      if (location.pathname !== '/ad') {
        return <Navigate to="/ad" replace />;
      }
    } else {
      // Only navigate to "/md" if we are not already on that route
      if (location.pathname !== '/md') {
        return <Navigate to="/md" replace />;
      }
    }
  }

  // Render children if all checks are passed
  return children;
}

export default ProtectedRoutes;
