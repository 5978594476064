import React, { useState, useEffect } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import UserService from '../services/user';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import MessageWrapper from '../common/MessageWrapper';
function AddNewDistributor() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const location = useLocation();

  // Parse query params
  const searchParams = new URLSearchParams(location.search);
  const role = searchParams.get('role') || 'ad';

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError('');
    validateEmail(value);
  };

  const validateEmail = (value) => {
    const gmailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    setIsValid(value ? gmailPattern.test(value) : null);
  };
  
  const getRoleIdByDistributorType = (type) => {
    const roleMap = {
      md: '664305cd491bf03e983f0ade',
      ad: '664305dc491bf03e983f0ae1',
    };
    return roleMap[type];
  };

  const handleSubmit = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (isValid) {

      try {
        setLoading(true);
        let payload = {
          email: email,
          // roleId: roleId,
          roleId: getRoleIdByDistributorType(role),
          invitedBy: user?._id,
        };
        const response = await new UserService().addUser(payload);
        toast.success(`Added ${role === 'ad' ? "Authorized" : 'Master'} distributor successfully`, {
          autoClose: 2000,
        });
      } catch (error) {
        console.error('Error submitting form:', error);
        if (
          error.responseData &&
          error.responseData.code === 11000 &&
          error.responseData.keyPattern &&
          error.responseData.keyPattern.email
        ) {
          toast.error('Email already exists', {
            autoClose: 2000,
          });
        } else {
          toast.error(`Error adding distributor: ${error.message}`, {
            autoClose: 2000,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center  bg-[#E9F9FF] relative font-jakarta">
      <>
        {selectedTab === 0 && (
          <div className="w-full max-w-[480px] flex flex-col gap-8">
            <img
              src="/personalDetails-sidebar-image.png"
              alt="banner image"
              className="w-75 h-75 mr-auto"
            />

            <h2 className="text-Neutral-900 font-extrabold text-2xl">
              {role === 'ad' ? 'Add a new authorized distributor' : 'Invite new master distributor'}
            </h2>
            <div className="flex flex-col items-start gap-2">
              <label htmlFor="ad" className="text-Neutral-500">
                Enter Gmail Account
              </label>
              <input
                id="ad"
                type="text"
                placeholder={`Enter gmail account of ${role === 'ad' ? 'AD' : 'MD'}`}
                value={email}
                onChange={handleEmailChange}
                className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border ${ isValid === false ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
              />
                {emailError && (
                <p className="text-Error-500 text-sm mt-2">{emailError}</p>
              )}
             {isValid === true && (
                <p className="text-green-500 text-sm mt-2">Account is eligible.</p>
              )}
              {isValid === false && (
                <p className="text-Error-500 text-sm mt-2">Provide Gmail account only.</p>
              )}
            </div>

            <div className=" flex gap-4 justify-between items-center">
              <button
                onClick={() => navigate(-1)}
                className={`py-3 px-8 rounded-3xl bg-white hover:bg-opacity-85  text-Error-500 font-bold text-base`}>
                Cancel
              </button>
              <button
                className={`py-3 px-8 flex items-center justify-center rounded-3xl bg-Primary-400 hover:bg-opacity-85  text-white font-bold text-base`}
                onClick={handleSubmit}>
                {loading ? <TbLoader3 className="animate-spin text-black mx-4" size={25} /> : 'Invite'}
              </button>
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <MessageWrapper
            heading={'Invitation sent successfully'}
            subheading={`${email} is added and pending for submission. Once ${role === 'ad' ? 'AD' : 'MD'} submits their information with admin approval, the user will be active.`}>
            <div className="flex items-center justify-start gap-4">
              <NavLink
                to={`/management?role=${role}`}
                className="flex items-center rounded-3xl justify-center bg-white px-6 py-3 text-black font-bold cursor-pointer">
                {role === 'ad' ? 'AD management' : 'MD management'}
              </NavLink>
              <NavLink
                to="/dashboard"
                className="flex items-center justify-center  rounded-3xl bg-Primary-400 px-6 py-3 text-white font-bold cursor-pointer">
                Go to dashboard
              </NavLink>
            </div>
          </MessageWrapper>
        )}
      </>
    </div>
  );
}

export default AddNewDistributor;
