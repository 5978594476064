import React, { useState, useRef, useEffect } from 'react';
import { MdKeyboardArrowDown, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { IoIosCheckbox } from 'react-icons/io';

// Reusable Dropdown Component
function Dropdown({
  label,
  selectedValue,
  placeHolder = '',
  ShowCheckBox,
  options,
  onSelect,
  isDisabledOption = (option) => false,
  isSelectedOption = (option) => selectedValue === option,
  error,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownChildRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      dropdownChildRef.current &&
      !dropdownChildRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle the selection of an option
  const handleSelect = (option) => {
    onSelect(option); // Call the onSelect callback
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="flex flex-col gap-2 relative cursor-default" ref={dropdownRef}>
      <label className="text-[#6C6F75]">{label}</label>
      <div className="w-full flex flex-col items-start">
        <div
          className={`h-12 bg-white w-full py-4.5 pl-4 pr-3 rounded-xl flex justify-between items-center cursor-pointer 
          ${isOpen ? 'border-2 border-Primary-400' : `border ${error ? 'border-Error-500' : 'border-Neutral-100'}`} `}
          onClick={() => setIsOpen(!isOpen)}>
          <p className={`${selectedValue ? 'text-[#141415]' : 'text-[#999C9C]'}`}>
            {selectedValue || placeHolder}
          </p>
          <div className="h-6 w-6">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        </div>
        {error && <p className="text-Error-500 text-sm">{error}</p>}
      </div>

      {isOpen && (
        <div
          className="absolute top-22 w-full box-border bg-white rounded-xl shadow-lg z-10 overflow-y-auto max-h-35"
          ref={dropdownChildRef}>
          {options.map((option) => {
            const isDisabled = isDisabledOption(option);
            const isSelected = isSelectedOption(option);

            return (
              <div
                key={option}
                className={`flex items-center gap-2 p-3 hover:bg-Primary-50 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => !isDisabled && handleSelect(option)}>
                {ShowCheckBox &&
                  (isSelected || isDisabled ? (
                    <IoIosCheckbox className="text-Neutral-900 w-5 h-5" />
                  ) : (
                    <MdCheckBoxOutlineBlank className="text-Neutral-900 w-5 h-5" />
                  ))}
                {/* <p className={`text-Neutral-900 ${isSelected || isDisabled ? 'font-bold' : 'font-normal'}`}> */}
                <p className={`text-Neutral-900`}>{option}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
