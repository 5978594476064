import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import FamilyDetails from './family-details';
import CommercialDetails from './commercial-details';
import Price from './price';
import Commission from './commission';
import Confirmation from './confirmation';
import { useSelector } from 'react-redux';
import Sidebar from './sidebar';
import UserService from '../services/user';

function AddProduct() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({
    family: '',
    name: '',
    commercialName: '',
    commercialDescription: '',
    size: '',
    wholesalePrice: '',
    retailPrice: '',
    retailPriceAutopay: '',
    ADCommission: '',
    ADCommissionType: '%',
    MDCommission: '',
    MDCommissionType: '$',
  });
  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const createProduct = async () => {
    try {
      const payload = {
        name: '',
        description: '',
        currency: '',
        wholeSalePrice: formData?.wholesalePrice,
        retailPrice: formData?.retailPrice,
        priceAutoPay: formData?.retailPriceAutopay,
      };
      const response = await new UserService().addProduct(payload);
    } catch (error) {
      console.log("error in create product",error);
      
    }
  };

  return (
    <div className="mx-auto w-full  min-h-screen flex  bg-[#E9F9FF] relative font-jakarta">
      <div
        className="absolute top-8 right-8 p-3 rounded-lg bg-white hover:cursor-pointer"
        onClick={() => navigate('/product')}>
        <MdClose className="w-5 h-5" />
      </div>
      <div className="hidden lg:block fixed top-0 left-0 h-full lg:w-[22.5rem] xxl:w-[30rem] max-w-[480px] z-40 bg-white overflow-y-auto custom-scrollbar">
        <Sidebar selectedTab={selectedTab} />
      </div>
      <div className="flex-1 flex justify-center lg:ml-[22.5rem] xxl:ml-[480px] mt-[5%] xxl:mt-[8%] relative">
        {selectedTab === 0 && (
          <FamilyDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 1 && (
          <CommercialDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 2 && (
          <Price
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 3 && (
          <Commission
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 4 && (
          <Confirmation
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
      </div>
    </div>
  );
}

export default AddProduct;
