import React from 'react';
import { useNavigate } from 'react-router-dom';

function ViewAllInfo() {
  const navigate = useNavigate();
  return (
    <div className={`flex justify-center min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta`}>
      <div className="grow shrink max-w-[776px] basis-0 self-stretch rounded-2xl flex-col justify-start items-start gap-6 inline-flex">
        {/* back button and title  */}
        <div className="self-stretch justify-start items-center gap-4 inline-flex">
          {/* back button  */}
          <div
            className="flex gap-2 py-2 px-4  items-center rounded-3xl bg-white cursor-pointer"
            onClick={() => navigate(-1)}>
            <img src="/back-arrow-icon.svg" alt="back-arrow-icon" />
            <p className="text-Neutral-900 font-bold">Back</p>
          </div>

          <div className="grow shrink basis-0 text-[#141415] text-[25px] font-extrabold ">
            AD details
          </div>
        </div>
        {/* personal details  */}
        <div className="p-6 bg-white rounded-2xl flex-col justify-start items-start gap-4 w-full flex">
          <div className="self-stretch text-Neutral-900 text-base font-bold ">Personal details</div>
          <div className="self-stretch justify-start items-start gap-4 inline-flex w-full">
            <div className="self-stretch justify-start gap-4 inline-flex flex-col basis-1/2">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">
                  Submitted on
                </div>
                <div className="self-stretch text-Neutral-700 text-base">June 16, 2024</div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Name</div>
                <div className="self-stretch text-Neutral-700 text-base">Al Mamun</div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Phone</div>
                <div className="self-stretch text-Neutral-700 text-base">+1 212 733 532</div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Gender</div>
                <div className="self-stretch text-Neutral-700 text-base">Male</div>
              </div>
            </div>
            <div className="self-stretch justify-start gap-4 inline-flex flex-col basis-1/2">
              <div className="grow shrink basis-0 flex-col justify-start items-start  inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Added by</div>
                <div className="self-stretch text-Neutral-700 text-base">MD name</div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Email</div>
                <div className="self-stretch text-Neutral-700 text-base">example@gmail.com</div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">Address</div>
                <div className="self-stretch text-Neutral-700 text-base">
                  Jacksonville, Florida -32207
                </div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <div className="self-stretch text-Neutral-500 text-sm font-normal">
                  Date of birth
                </div>
                <div className="self-stretch text-Neutral-700 text-base">Sep 25, 1996</div>
              </div>
            </div>
          </div>
        </div>
        {/* identity proof  */}
        <div className=" p-6 bg-white rounded-2xl flex-col justify-start items-start gap-4 inline-flex w-full">
          <div className="self-stretch text-[#141415] text-base font-bold font-['Plus Jakarta Sans'] leading-normal">
            Identity proof
          </div>
          <div className="w-[360px] h-[243px] flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch text-[#6c6f75] text-sm font-normal font-['Plus Jakarta Sans'] leading-[21px]">
              Driving license
            </div>
            <div className="self-stretch h-[220px] p-6 bg-[#d6efff] rounded-2xl flex-col justify-center items-center gap-2 flex">
              <div className="w-16 h-16 relative">
                <div className="w-16 h-16 left-0 top-0 absolute bg-[#d9d9d9]" />
              </div>
              <div className="self-stretch text-center text-[#008ae0] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
                Graphics
              </div>
            </div>
          </div>
        </div>
        {/* address proof  */}
        <div className=" p-6 bg-white rounded-2xl flex-col justify-start items-start gap-4 inline-flex w-full">
          <div className="self-stretch text-[#141415] text-base font-bold font-['Plus Jakarta Sans'] leading-normal">
            Address proof
          </div>
          <div className="w-[360px] h-[243px] flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch text-[#6c6f75] text-sm font-normal font-['Plus Jakarta Sans'] leading-[21px]">
              Driving license
            </div>
            <div className="self-stretch h-[220px] p-6 bg-[#d6efff] rounded-2xl flex-col justify-center items-center gap-2 flex">
              <div className="w-16 h-16 relative">
                <div className="w-16 h-16 left-0 top-0 absolute bg-[#d9d9d9]" />
              </div>
              <div className="self-stretch text-center text-[#008ae0] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
                Graphics
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAllInfo;
