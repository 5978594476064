import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountTypeOptions, OnboardingInputMaxLength } from '../constants';
import { updateUser } from '../slices/userSlice';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';
import Dropdown from '../common/onboarding/Dropdown';
import UserService from '../services/user';
import { toast } from 'react-toastify';

function EditBankDetails({ formData, setFormData, setSelectedTab }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { accountHolderName, accountNumber, routingNumber } = OnboardingInputMaxLength.bankDetails;

  const [initialFormData, setInitialFormData] = useState(formData);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const handleInputChange = (field, name) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      paymentDetails: {
        ...prevData.paymentDetails,
        [field]: value,
      },
    }));

    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${name} is required`, // Set the error if the input is empty
      }));
    } else {
      // Clear the specific error for the input being changed
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null, // Clear the error for this specific field
      }));
    }
  };

  const handleAccountType = (selectedAccountType) => {
    setFormData((prevData) => ({
      ...prevData,
      paymentDetails: {
        ...prevData.paymentDetails, // Spread the existing personal details
        accountType: selectedAccountType, // Update gender with the new value
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      accountType: null, // Set the error if the input is empty
    }));
  };

  const handleSubmit = async () => {
    setErrors({});
    const newErrors = {};
    if (!formData.accountNumber) {
      newErrors.accountNumber = 'Account number is required';
    }
    if (!formData.routingNumber) {
      newErrors.routingNumber = 'Routing number is required';
    }
    if (!formData.accountType) {
      newErrors.accountType = 'Account type is required';
    }
    if (!formData.accountHolderName) {
      newErrors.accountHolderName = 'Account holder name is required';
    }

    // If there are errors, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      // No changes, don't call API
      console.log('No changes detected, skipping API call');
      setSelectedTab(0);
      return;
    }
    const updatedFormData = {
      userId: user?._id,
      paymentMethods: [
        {
          paymentMethodType: 'Bank',
          bankDetails: {
            bankName: 'Bank of America',
            accountNumber: formData.accountNumber,
            routingNumber: formData.routingNumber,
            accountType: formData.accountType,
            accountHolderName: formData.accountHolderName,
          },
        },
      ],
    };
    try {
      setLoading(true);
      const response = await new UserService().updatePaymentDetails(updatedFormData);
      console.log('response', response);

      const updatedUserData = { ...user, ...updatedFormData, isPaymentAdded: true };
      if (response) {
        setInitialFormData(formData);
        dispatch(updateUser(updatedUserData));
        setSelectedTab(0);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className=" max-w-[480px] w-full flex flex-col gap-8 mx-auto  mt-5">
      <FormSectionTitle heading={'Edit bank information'} />
      <div className="flex flex-col gap-8">
        <CustomInputField
          id={'accountNumber'}
          labelText={'Account Number'}
          maxLength={accountNumber}
          type={'number'}
          placeholder={'Enter your account number'}
          value={formData.accountNumber}
          onChange={handleInputChange('accountNumber', 'Account number')}
          error={errors?.accountNumber}
        />
        <CustomInputField
          id={'routingNumber'}
          labelText={'Routing Number'}
          maxLength={routingNumber}
          type={'number'}
          placeholder={'Enter your routing number'}
          value={formData.routingNumber}
          onChange={handleInputChange('routingNumber', 'Routing Number')}
          error={errors?.routingNumber}
        />
        <Dropdown
          label="Account Type"
          placeHolder="Select your account type"
          selectedValue={formData.accountType}
          options={AccountTypeOptions}
          onSelect={handleAccountType}
          error={errors?.accountType}
        />
        <CustomInputField
          id={'accountHolderName'}
          labelText={'Account holder name'}
          maxLength={accountHolderName}
          type={'text'}
          placeholder={'Enter your account holder name'}
          value={formData.accountHolderName}
          onChange={handleInputChange('accountHolderName', 'Account holder name')}
          error={errors?.accountHolderName}
        />
      </div>
      <div className="flex justify-between items-center  mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Error-500"
          onClick={() => setSelectedTab(0)}>
          Cancel
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default EditBankDetails;
