import React from 'react';

function FormSectionTitle({ heading, subHeading }) {
  return (
    <div className="w-full flex flex-col gap-2">
      <h5 className="font-extrabold text-2xl leading-[2.18rem] text-Neutral-900">{heading}</h5>
      <p className="text-Neutral-500 text-base leading-6">{subHeading}</p>
    </div>
  );
}

export default FormSectionTitle;
