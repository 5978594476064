import React from 'react'
import { formatTimestamp } from '../utils/dateUtils'
import { statusClasses } from '../constants'
import { TbLoader3 } from 'react-icons/tb'

function MdTable({loading,showTabledata}) {
  return (
    <div className="w-full h-full flex flex-col bg-white rounded-2xl px-2">
    <div className=" w-full p-6 flex justify-between items-center">
      <h2 className="text-xl font-semibold text-Neutral-500">Latest ADs </h2>

      <button
        className="pl-4 py-3 pr-3 bg-Primary-50 rounded-3xl flex justify-center items-center gap-3 cursor-pointer hover:bg-opacity-90"
       >
        <p className="text-base text-Primary-500 font-semibold">Export</p>
        <img src="./chevron-down-blue.svg" alt="Chevron-Down Icon" className="h-4 w-4" />
      </button>
    </div>

    <table className="w-full px-2 relative border-collapse">
      <thead className="">
        <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
          <th className="text-left text-xs w-1/5  p-4 font-medium cursor-pointer">AD Name</th>
          <th className="text-left text-xs w-1/5 p-4 font-medium cursor-pointer">Email</th>
          <th className="text-left text-xs w-1/5  p-4 font-medium cursor-pointer">
            Subscribers
          </th>
          <th className="text-left text-xs w-1/5  p-4 font-medium cursor-pointer">
            Commissions
          </th>
          <th className="text-left text-xs w-1/5  p-4 font-medium cursor-pointer">Added on</th>
          <th className="text-left text-xs w-1/5 p-4 font-medium cursor-pointer">Status</th>
        </tr>
      </thead>
      {loading ? (
        <div className="flex justify-center items-center h-80">
          <TbLoader3 className="animate-spin text-black h-13 w-13  absolute left-45per top-45per" />
        </div>
      ) : (
        <tbody>
          {showTabledata?.length > 0 ? (
            showTabledata.map((distributor, index) => (
              <tr key={index} className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                <td className="p-4 text-Neutral-700 text-base">
                  {distributor?.firstName
                    ? distributor?.firstName + ' ' + distributor?.lastName
                    : 'N/A'}
                </td>
                <td className="p-4 text-Neutral-700 text-base truncate">{distributor.email}</td>

                <td className="p-4 text-Neutral-700 text-base">
                  {distributor.activeSubscribers || 0}
                </td>
                <td className="p-4 text-Neutral-700 text-base">
                  $
                  {distributor.myCommissions !== undefined && distributor.myCommissions !== null
                    ? distributor.myCommissions.toFixed(2)
                    : '0.00'}
                </td>
                <td className="p-4 text-Neutral-700 text-base">
                  {formatTimestamp(distributor.addedOn)}
                </td>
                <td className="p-4">
                  <div
                    className={`inline-flex items-center gap-1 p-2 rounded-5-5xl ${statusClasses[distributor?.status]?.bg || ''}`}>
                    <div
                      className={`w-2 h-2 rounded-full ${statusClasses[distributor?.status]?.dot || ''}`}></div>
                    <div
                      className={`font-semibold text-base capitalize ${statusClasses[distributor?.status]?.text || ''}`}>
                      {distributor?.status}
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-3">
                No data found'
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  </div>
  )
}

export default MdTable