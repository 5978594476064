import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
function AddMoney({ amount, setAmount, setSelectedTab,handleGoBack,onSubmit }) {
  return (
    <div className="w-full flex flex-col gap-8 h-full">
      <FormSectionTitle
        heading={'Enter amount'}
        subHeading={'Provide the amount you want to add in your EZ wallet'}
      />

      <div className="flex flex-col gap-8">
        <div className="flex flex-col items-start gap-2">
          <label htmlFor="amount" className="text-Neutral-500">
            Enter amount
          </label>
          <input
            id="amount"
            type="number"
            placeholder="Enter amount in USD"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
          />

          <p className="text-Neutral-500">Minimum amount $50</p>
        </div>
      </div>

      <div className="flex justify-between items-center   mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
          onClick={handleGoBack}>
          Back
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={() => onSubmit()}>
         
          Next
        </button>
      </div>
    </div>
  );
}

export default AddMoney;
