import React from 'react';
import PopupComponent from '../common/popup';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function ApproveModal({ email,onClose }) {
  const navigate = useNavigate();
  return (
    <PopupComponent>
      {/* close button  */}
      <div className="ml-auto p-3  hover:cursor-pointer" onClick={onClose}>
        <MdClose className="w-6 h-6" />
      </div>
      <div className="flex flex-col gap-2 items-center">
        <img src="/done-icon.svg" alt="done icon" className="w-12 h-12" />

        <p className={`font-semibold text-Success-600 text-xl `}>Approved successfully</p>
        <p className={` text-Neutral-500 text-base `}>{`${email} has been approved as AD.`}</p>
      </div>

      <div className="flex flex-col gap-4 w-full">
        <button className="py-3 px-6  text-center bg-Primary-400 rounded-3xl text-black text-base font-bold">
          AD profile
        </button>
        <button
          className="py-3 px-6  text-center bg-Primary-50 rounded-3xl text-Primary-500 text-base font-bold"
          onClick={() => navigate('/management?role=ad')}>
          Go to AD Management
        </button>
      </div>
    </PopupComponent>
  );
}

export default ApproveModal;
