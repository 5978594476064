import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

const Sidebar = ({ collapsed, toggleSidebar }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation();

  const roleBasedMenus = {
    'Authorized Distributor': [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'Subscribers',
        blackIcon: './subscribers-tab-icon-black.svg',
        greyIcon: './subscribers-tab-icon-grey.svg',
        path: '/management?role=ad',
      },
      {
        title: 'EZ Earnings',
        blackIcon: './earnings-tab-icon-black.svg',
        greyIcon: './earnings-tab-icon-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
    'Master Distributor': [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },
      {
        title: 'ADs',
        blackIcon: '/ad-black.svg',
        greyIcon: '/ad-grey.svg',
        path: '/management?role=md',
      },
      {
        title: 'EZ Earnings',
        blackIcon: './earnings-tab-icon-black.svg',
        greyIcon: './earnings-tab-icon-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
    Admin: [
      {
        title: 'Dashboard',
        blackIcon: './dashboard-tab-icon-black.svg',
        greyIcon: './dashboard-tab-icon-grey.svg',
        path: '/dashboard',
      },

      {
        title: 'MDs',
        blackIcon: '/md-black.svg',
        greyIcon: '/md-grey.svg',
        path: '/management?role=md',
      },
      {
        title: 'ADs',
        blackIcon: '/ad-black.svg',
        greyIcon: '/ad-grey.svg',
        path: '/management?role=ad',
      },
      {
        title: 'Payments',
        blackIcon: '/paid-black.svg',
        greyIcon: '/paid-grey.svg',
        path: '/earning',
      },
      {
        title: 'Product Catalogue',
        blackIcon: './products-tab-icon-black.svg',
        greyIcon: './products-tab-icon-grey.svg',
        path: '/product',
      },
    ],
  };

  const menuItems = roleBasedMenus[user?.roleId?.name] || [];
  const isMenuItemActive = (itemPath) => {
    const currentPathname = location.pathname;
    const currentSearchParams = new URLSearchParams(location.search);
    const role = currentSearchParams.get('role');

    // Check for both pathname and role in the query
    if (itemPath.includes('role=ad') && role === 'ad') {
      return true; // Activate AD menu item
    }
    if (itemPath.includes('role=md') && role === 'md') {
      return true; // Activate MD menu item
    }
    return currentPathname === itemPath; // Default path matching
  };

  return (
    <div
      className={`fixed left-0 max-w-[280px] top-0 h-screen bg-white z-40 font-jakarta p-3 transition-all duration-300 ${
        collapsed ? 'w-25' : 'w-[280px]'
      }`}>
      <div className={`flex items-center mb-8 px-3 ${collapsed ? 'justify-center' : ''}`}>
        <img
          src={collapsed ? '/EZ Mobile-logo.svg' : '/tittle-logo.svg'}
          alt="EZMobile Logo"
          className="h-8"
        />
      </div>
      <ul className={`flex flex-col gap-3 ${collapsed ? 'items-center' : ''}`}>
        {menuItems.map((item) => (
          <li key={item.title} className={`w-full ${collapsed ? 'justify-center' : ''}`}>
            <NavLink
              to={item.path}
              className={`flex items-center p-3 gap-2 justify-start rounded-xl cursor-pointer hover:bg-Primary-50 ${
                isMenuItemActive(item.path)
                  ? 'bg-Primary-100 text-Neutral-700 font-bold'
                  : 'bg-white text-Neutral-600 font-normal'
              } ${collapsed ? 'justify-center' : 'w-full'}`}>
              <img
                src={isMenuItemActive(item.path) ? item.blackIcon : item.greyIcon}
                alt={`${item.title} Icon`}
                className="w-6 h-6"
              />
              {!collapsed && (
                <>
                  <p className={`whitespace-nowrap`}>{item.title}</p>
                  {item.hasNotification && (
                    <span className="ml-auto w-2.5 h-2.5 bg-red-500 rounded-full"></span>
                  )}
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
      <button
        className={`flex p-3 gap-2 bg-Primary-200 rounded-[45px] absolute bottom-3 ${
          collapsed ? 'left-1/2 translate-x-[-50%]' : 'left-3'
        }`}
        onClick={toggleSidebar}>
        <div className="flex items-center justify-center px-1 py-[6px] w-6 h-6">
          <img
            src={'/arrow-back-icon.svg'}
            alt="Collapse Icon"
            className={`${collapsed ? 'rotate-180' : 'rotate-0'}`}
          />
        </div>
        {!collapsed && <p className="text-Neutral-900 w-44 text-left">Collapse</p>}
      </button>
    </div>
  );
};

export default Sidebar;
