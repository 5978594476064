import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { useSelector } from 'react-redux';
import LineChartComponent from './line-chart-component';
import LatestMdTable from './latest-md-table';
import {
  blueChart,
  earningsData,
  statusClasses,
  greenChart,
  purpleChart,
  whiteChart,
} from '../constants';
import MdTable from './md-table';
import AdTable from './ad-table';
import LatestAdTable from './latest-ad-table';
import WelcomeCard from './welcome-card';
import EarningSection from './earning-section';
import { getGradient } from '../utils/lineChart';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardComponent = ({ responseData = {}, latestMdsData, latestAdsData = [], loading }) => {
  const user = useSelector((state) => state.user);

  const showTabledata =
    user?.roleId?.name === 'Authorized Distributor' ? latestMdsData : latestAdsData;
  const showdata =
    user?.roleId?.name === 'Authorized Distributor' ? earningsData.ad : earningsData.md;

  const TotalMDData = {
    labels: responseData?.chartDataMD?.labels,
    datasets: [
      {
        label: 'Sales',
        data: responseData?.chartDataMD?.data,
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, blueChart);
        },
        borderColor: blueChart.color,
        tension: 0.4,
        pointBackgroundColor: blueChart.color,
        pointBorderColor: blueChart.color,
        pointHoverBackgroundColor: blueChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const totalADData = {
    labels: responseData?.chartDataAD?.labels,
    datasets: [
      {
        label: 'Sales',
        data: responseData?.chartDataAD?.data,
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const totalSubscribersData = {
    labels: responseData?.chartDataSubscribers?.labels,
    datasets: [
      {
        label: 'Sales',
        data: responseData?.chartDataSubscribers?.data,
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, purpleChart);
        },
        borderColor: purpleChart.color,
        tension: 0.4,
        pointBackgroundColor: purpleChart.color,
        pointBorderColor: purpleChart.color,
        pointHoverBackgroundColor: purpleChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const activeSubscribersData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 40, 20, 60, 30, 55],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const subscriberRetentionData = {
    labels: ['January', 'February', 'March', 'April'],
    datasets: [
      {
        label: 'Sales',
        data: [80, 50, 60, 0],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, whiteChart);
        },
        borderColor: whiteChart.color,
        tension: 0.4,
        pointBackgroundColor: whiteChart.color,
        pointBorderColor: whiteChart.color,
        pointHoverBackgroundColor: whiteChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const popularPlansData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
    datasets: [
      {
        label: 'Sales',
        data: [90, 80, 60, 50, 40, 30, 20, 10],
        backgroundColor: [
          '#009DFF',
          '#BFC1C4',
          '#BFC1C4',
          '#BFC1C4',
          '#BFC1C4',
          '#BFC1C4',
          '#BFC1C4',
          '#BFC1C4',
        ],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 0,
        borderRadius: 50,
        borderSkipped: false,
      },
    ],
  };

  const popularPlansOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
        text: 'Sales Data (2024)',
      },
    },
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        categoryPercentage: 0.1,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
  };

  const TotalMdChart = () => {
    return (
      <LineChartComponent
        title={'Total MD'}
        activeData={responseData?.activeMDCount}
        totalData={responseData?.totalMD}
        percent={responseData?.percentIncreaseMDMonth}
        chartData={TotalMDData}
        chartColour={blueChart}
      />
    );
  };
  const TotalAdChart = () => {
    return (
      <LineChartComponent
        title={'Total AD'}
        activeData={responseData?.activeADCount}
        totalData={responseData?.totalAD}
        percent={responseData?.percentIncreaseADMonth}
        chartData={totalADData}
        chartColour={greenChart}
      />
    );
  };
  const TotalSubscriberChart = () => {
    return (
      <LineChartComponent
        title={'Total Subscribers'}
        activeData={
          user?.roleId?.name === 'Authorized Distributor'
            ? responseData?.activeSubscribersCount
            : responseData?.activeSubscriberCount
        }
        totalData={
          user?.roleId?.name === 'Authorized Distributor'
            ? responseData?.totalSubscribersCount
            : responseData?.totalSubscribers
        }
        percent={responseData?.percentIncreaseSubscribersMonth}
        chartData={totalSubscribersData}
        chartColour={purpleChart}
      />
    );
  };
  const ActiveSubscriberChart = () => {
    return (
      <LineChartComponent
        title={'Active subscribers'}
        activeData={responseData?.activeSubscribersCount}
        totalData={responseData?.activeSubscribersCount}
        percent={responseData?.percentIncreaseMDMonth}
        chartData={activeSubscribersData}
        chartColour={greenChart}
      />
    );
  };

  const RetentionRate = () => {
    return (
      <LineChartComponent
        title={'Retention rate'}
        activeData={responseData?.activeMDCount}
        totalData={responseData?.totalMD}
        percent={responseData?.percentIncreaseMDMonth}
        chartData={subscriberRetentionData}
        chartColour={whiteChart}
      />
    );
  };

  const PopularPlansSection = () => {
    return (
      <div className="h-full w-full p-[24px] bg-[#FFFFFF] rounded-[16px] flex flex-col justify-between gap-[24px]">
        <div className="w-full flex justify-between items-center">
          <h4 className="text-[20px] text-[#585B5F] font-semibold">Popular plans</h4>
          <img
            src="./three-dots-vertical-icon.svg"
            alt="Three-Dots Icon"
            className="h-[24px] w-[24px] cursor-pointer hover:opacity-90"
          />
        </div>

        <div className="h-[111px] w-[250px]">
          <Bar data={popularPlansData} options={popularPlansOptions} />
        </div>

        <div className="flex justify-between items-center gap-[20px]">
          <div className="flex flex-col">
            <h3 className="text-[20px] text-[#0EA8DE] font-semibold">EZ Starter</h3>
            <p className="text-[14px] text-[#A0A2A7] font-normal">Retail price $29</p>
          </div>

          <div className="flex flex-col items-end">
            <div className="flex items-center gap-[2px]">
              <img src="./arrow-down-red.svg" alt="Arrow-Down Icon" className="h-[24px] w-[24px]" />
              <p className="text-[14px] text-[#EE797E] font-medium">1%</p>
            </div>
            <p className="text-[14px] text-[#A0A2A7] font-normal">1298 Subscribers</p>
          </div>
        </div>
      </div>
    );
  };

  const AdminUI = () => {
    return (
      <>
        {/* First Row: Welcome Card (spans 2 columns) and Popular Plans (spans 1 column) */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <WelcomeCard responseData={responseData} />
          </div>
          <div className="lg:col-span-1">
            <PopularPlansSection />
          </div>
        </div>

        {/* Second Row: Each chart component occupies a single column */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <TotalMdChart />
          <TotalAdChart />
          <TotalSubscriberChart />
        </div>
        <div className="flex overflow-x-auto  w-full gap-6 justify-between">
          <div className="basis-1/2">
            <LatestAdTable
              statusClasses={statusClasses}
              loading={loading}
              latestAdsData={latestAdsData}
            />
          </div>
          <div className="basis-1/2">
            <LatestMdTable
              statusClasses={statusClasses}
              loading={loading}
              latestMdsData={latestMdsData}
            />
          </div>
        </div>
      </>
    );
  };
  const MasterUI = () => {
    return (
      <>
        {/* First Row: Welcome Card (spans 2 columns) and Popular Plans (spans 1 column) */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* First Column: Welcome Card and Chart Components */}
          <div className="flex flex-col gap-4 lg:w-2/3">
            <WelcomeCard responseData={responseData} />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <TotalAdChart />
              <TotalSubscriberChart />
            </div>
          </div>

          {/* Second Column: Earning Section */}
          <div className="lg:w-1/3">
            <EarningSection responseData={responseData} showdata={showdata} />
          </div>
        </div>
        <div className="flex overflow-x-auto  w-full gap-6 justify-between">
          <MdTable showTabledata={showTabledata} loading={loading} />
        </div>
      </>
    );
  };
  const AuthorizedUI = () => {
    return (
      <>
        <div className="flex flex-col lg:flex-row gap-6">
          {/* First Column: Welcome Card and Chart Components */}
          <div className="flex flex-col gap-4 lg:w-2/3">
            <WelcomeCard responseData={responseData} />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <ActiveSubscriberChart />
              <TotalSubscriberChart />
            </div>
          </div>

          {/* Second Column: Earning Section */}
          <div className="lg:w-1/3">
            <EarningSection responseData={responseData} showdata={showdata} />
          </div>
        </div>
        <div className="flex  w-full gap-6 justify-between items-start">
          <div className="lg:w-2/3">
            <AdTable showTabledata={responseData?.latestSubscribers} loading={loading} />
          </div>

          <div className="lg:w-1/3 flex flex-col gap-6">
            <RetentionRate />
            <PopularPlansSection />
          </div>
        </div>
      </>
    );
  };

  const handleExport = () => {
    const headers = ['MDN', 'Plan', 'Creation Date', 'Billing Cycle'];
    const rows = showTabledata.map((subscriber) => [
      subscriber.mid,
      subscriber.plan,
      `"${subscriber.creationDate}"`,
      `"${subscriber.billingCycle}"`,
    ]);

    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Latest Subscribers.csv');
  };

  return (
    <div className="flex flex-col justify-center gap-6 w-full h-full">
      {user?.roleId?.name === 'Admin' && <AdminUI />}
      {user?.roleId?.name === 'Master Distributor' && <MasterUI />}
      {user?.roleId?.name === 'Authorized Distributor' && <AuthorizedUI />}
    </div>
  );
};

export default DashboardComponent;
