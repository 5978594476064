import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';

function WireLessPlan({
  sortedPlans,
  selectedPlan,
  setSelectedPlan,
  setSelectedTab,
  wirelessPlanType,
  setWirelessPlanType,
  handlePlansSorting,
  startSubscriberActivation,
}) {
  const handleSelectPlan = (product) => {
    console.log("product",product);
    
    setSelectedPlan(product); // Store the selected product index
  };

  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[824px] h-full">
        <FormSectionTitle
          heading={'Select a wireless plan'}
          subHeading={'Choose the most suitable wireless plan for your subscriber.'}
        />
        <div className="relative flex items-center justify-start gap-2 rounded-[3rem] bg-white mr-auto w-max">
          {/* Background slider */}
          <div
            className={`absolute top-0 bottom-0 left-0 w-1/2 bg-Neutral-900 rounded-[3rem] transition-all duration-300 ${wirelessPlanType === 1 ? 'translate-x-full' : 'translate-x-0'}`}></div>

          {/* Buttons */}
          <button
            className={`relative py-3 text-center px-8 rounded-[27px] transition-all duration-300 z-10 ${wirelessPlanType === 0 ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => handlePlansSorting(0)}>
            Tether
          </button>
          <button
            className={`relative text-center py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${wirelessPlanType === 1 ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => handlePlansSorting(1)}>
            No tether
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4 max-h-[733px]  overflow-y-scroll">
          {sortedPlans?.length > 0 &&
            sortedPlans.map((product, index) => (
              <div
                key={product._id}
                className={`p-4  rounded-3xl cursor-pointer bg-white  ${selectedPlan?._id === product._id ? 'border-Primary-500 border-2 ' : ''}`}
                onClick={() => handleSelectPlan(product)}>
                {/* Render your product content here */}
                <div className="flex flex-col items-start gap-4">
                  <p className="font-bold text-Neutral-700 text-base">{product.displayName}</p>
                  <p className="text-Primary-500 font-bold">{product.size}</p>
                  <p className="text-Neutral-300">{product.description}</p>
                  <p>
                    {' '}
                    <span className="font-bold text-Neutral-700">$ {product.amount}</span> / month
                  </p>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => startSubscriberActivation()}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default WireLessPlan;
