import React, { useState, useEffect } from 'react';
import ADTableComp from './AdTable';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MDTableComp from './MdTable';
import UserService from '../services/user';
import LineChartComponent from '../dashboard/line-chart-component';
import { getGradient } from '../utils/lineChart';

const AdminManagement = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const role = searchParams.get('role') || 'ad';

  const [Adpagination, setAdPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    sortConfig: { key: null, direction: 'ascending' },
  });
  const [Mdpagination, setMdPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    sortConfig: { key: null, direction: 'ascending' },
  });
  const [mdDistributors, setMdDistributors] = useState([]);
  const [adDistributors, setAdDistributors] = useState([]);
  const [totalMdDistributors, setTotalMdDistributors] = useState(0);
  const [totalAdDistributors, setTotalAdDistributors] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchMdData = async () => {
    try {
      setLoading(true);
      const defaultQueryParams = `?skip=${
        (Mdpagination.currentPage - 1) * Mdpagination.rowsPerPage
      }&limit=${Mdpagination.rowsPerPage}&role=Master Distributor`;
      const response = await new UserService().getDistributorsData(defaultQueryParams);
      setTotalMdDistributors(response.totalCount);
      setMdDistributors(response.distributorsList);
    } catch (err) {
      console.error('Error fetching user data:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdData = async () => {
    try {
      setLoading(true);
      const defaultQueryParams = `?skip=${
        (Adpagination.currentPage - 1) * Adpagination.rowsPerPage
      }&limit=${Adpagination.rowsPerPage}&role=Authorized Distributor`;
      const response = await new UserService().getDistributorsData(defaultQueryParams);
      setTotalAdDistributors(response.totalCount);
      setAdDistributors(response.distributorsList);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.roleId?.name === 'Admin' && role === 'md') {
      fetchMdData();
    }
    if (user?.roleId?.name === 'Admin' && role === 'ad') {
      fetchAdData();
    }
  }, [Adpagination, Mdpagination, role]);
  const greenChart = {
    color: '#19C84D',
    bg: '#FFF',
    gridLineColour: '#DFE0E2',
    gradientStart: 'rgba(25, 200, 77, 0.05)',
    gradientEnd: ' rgba(25, 200, 77, 0.30)',
  };
  const redChart = {
    color: '#F83A5D',
    bg: '#FFF',
    gridLineColour: '#DFE0E2',
    gradientStart: 'rgba(245, 96, 125, 0.05)',
    gradientEnd: ' rgba(245, 96, 125, 0.30)',
  };

  const TotalADData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const ActiveSubscribersData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, redChart);
        },
        borderColor: redChart.color,
        tension: 0.4,
        pointBackgroundColor: redChart.color,
        pointBorderColor: redChart.color,
        pointHoverBackgroundColor: redChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };
  const ActiveSubscriptionsData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const TotalAdChart = () => {
    return (
      <LineChartComponent
        title={'Total ADs'}
        activeData={0}
        totalData={0}
        percent={'0'}
        chartData={TotalADData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };
  const ActiveSubscribers = () => {
    return (
      <LineChartComponent
        title={'Active subscribers'}
        activeData={0}
        totalData={0}
        percent={'0'}
        chartData={ActiveSubscribersData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };
  const ActiveSubscriptions = () => {
    return (
      <LineChartComponent
        title={'Active subscriptions'}
        activeData={0}
        totalData={0}
        percent={'0%'}
        chartData={ActiveSubscriptionsData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };

  const renderMDCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <TotalAdChart />
      <ActiveSubscribers />
      <ActiveSubscriptions />
    </div>
  );
  const renderADCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <TotalAdChart />
      <ActiveSubscribers />
      <ActiveSubscriptions />
    </div>
  );

  return (
    <div className="p-4 z-10 font-jakarta w-full h-full bg-[#E9F9FF]">
      <div className="flex flex-col">
        <div className="flex  items-center justify-between mb-4">
          <h2 className="text-2xl font-extrabold text-black">
            {role === 'ad' ? 'AD management' : 'MD management'}
          </h2>
          {role === 'md' && (
            <button
              className="bg-Primary-400 font-bold hover:bg-opacity-85 text-black px-6 py-3 rounded-3xl"
              onClick={() => navigate('/add?role=md')}>
              Invite new MD
            </button>
          )}
        </div>

        {role === 'ad' && renderADCards()}
        {role === 'md' && renderMDCards()}
        {role === 'ad' && (
          <ADTableComp
            distributors={adDistributors}
            totalDistributors={totalAdDistributors}
            pagination={Adpagination}
            setPagination={setAdPagination}
            loading={loading}
          />
        )}
        {role === 'md' && (
          <MDTableComp
            distributors={mdDistributors}
            totalDistributors={totalMdDistributors}
            pagination={Mdpagination}
            setPagination={setMdPagination}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default AdminManagement;
