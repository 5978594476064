import React from 'react';
import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { createDottedGridLinesPlugin, externalTooltipHandler } from '../utils/lineChart';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function LineChartComponent({
  title,
  activeData = 0,
  totalData = 0,
  percent = 0,
  chartData,
  chartColour,
  YEAR = '2024',
  yearBGColour,
}) {
  const user = useSelector((state) => state.user);

  const lineChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        border: {
          color: chartColour?.gridLineColour, // Set x-axis border color here
        },
        ticks: {
          display: true, // Ensure ticks are displayed
          padding: 0, // Adjust padding if needed
          color: '#80848A', // Change tick color if desired
          font: {
            size: 10, // Set font size
            family: 'Plus Jakarta Sans, sans-serif', // Set font family
            weight: '400', // Set font weight
            style: 'normal', // Ensure normal style, not italic
          },
          align: 'center', // Center align the text
          minRotation: 0, // Prevent rotation
          maxRotation: 0, // Keep labels horizontal
        },
      },
      y: {
        grid: { display: false },
        ticks: { display: false, maxTicksLimit: 4 },
        border: { display: false },
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
    },
    elements: {
      line: {
        borderWidth: 5,
      },
      point: {
        radius: 0,
        hoverRadius: 10,
        hoverBorderWidth: 6,
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };
  return (
    <div className={`w-full rounded-2xl p-2`} style={{ backgroundColor: chartColour?.bg }}>
      <div className="w-full p-4 flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <h4 className="text-xl text-Neutral-700 font-semibold">{title}</h4>

          <div
            className={`py-1 px-3 mr-auto bg-white text-center  text-base font-medium rounded-5-5xl leading-normal capitalize`}
            style={{
              backgroundColor: yearBGColour || 'white', // Default neutral color if not 'Admin'
            }}>
            {user?.roleId?.name === 'Admin' ? `Active ${activeData || '_'}` : `YEAR ${YEAR || '_'}`}
          </div>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-Neutral-900 text-2xl font-extrabold">{totalData || '_'}</p>
          <div className="flex items-center">
            <p>{percent}%</p>
            <img
              src={percent >= 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg'}
              alt={percent >= 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon'}
              className="h-6 w-6"
            />
          </div>
        </div>
      </div>

      <div className="h-full max-h-30 my-3 w-full p-2">
        <Line
          style={{ width: '100%', height: '100%' }}
          data={chartData}
          options={lineChartOptions}
          plugins={[createDottedGridLinesPlugin(chartColour?.gridLineColour)]}
        />
      </div>
    </div>
  );
}

export default LineChartComponent;
