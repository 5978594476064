import React from 'react'
import { TbLoader3 } from 'react-icons/tb'

function LatestAdTable({statusClasses, latestAdsData, loading}) {
  return (
 
        <div className="flex flex-col bg-white rounded-2xl px-2">
          <div className=" w-full p-6 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-Neutral-900">Latest ADs </h2>
  
          </div>
  
          <table className="w-full px-2 relative table-fixed border-collapse">
            <thead className="">
              <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                {['Email', 'Subscribers', 'Status'].map((item) => (
                  <th className="text-left text-xs w-1/3  p-4 font-medium cursor-pointer">{item}</th>
                ))}
              </tr>
            </thead>
            {loading ? (
              <div className="flex justify-center items-center h-80">
                <TbLoader3 className="animate-spin text-black h-13 w-13  absolute left-45per top-45per" />
              </div>
            ) : (
              <tbody>
                {latestAdsData?.length > 0 ? (
                  latestAdsData.map((distributor, index) => (
                    <tr key={index} className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                      <td className="p-4 text-Neutral-700  text-base truncate">
                        {distributor?.email || '_'}
                      </td>
  
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {distributor?.subscriberCount || '_'}
                      </td>
                      <td className="p-4">
                        <div
                          className={`inline-flex items-center gap-1 p-2 rounded-5-5xl ${statusClasses[distributor?.status]?.bg || ''}`}>
                          <div
                            className={`min-w-2 min-h-2 max-w-2 max-h-2 rounded-full ${statusClasses[distributor?.status]?.dot || ''}`}></div>
                          <div
                            className={`font-semibold text-base capitalize whitespace-nowrap ${statusClasses[distributor?.status]?.text || ''}`}>
                            {distributor?.status}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-3">
                      No data found'
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
  )
}

export default LatestAdTable