import React, { useEffect, useState } from 'react';
import WithdrawalMethod from './withdrawalMethod';
import WithdrawalAmount from './withdrawalAmount';
import { NavLink, useNavigate } from 'react-router-dom';
import { withdrawalMoneyFormData } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import { MdClose } from 'react-icons/md';
import Utils from '../utility';
import WalletService from '../service/walletService';
import { removeUser } from '../slices/userSlice';
import ConfirmWithdraw from './confirmWithdraw';
import MessageWrapper from '../common/MessageWrapper';
import PopupComponent from '../common/popup';

const WithdrawMoneyComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState(withdrawalMoneyFormData);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const [walletDetails, setWalletDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    async function fetchWalletDetails() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }

        const walletDetails = await new WalletService().getUserWallet(user?._id);

        setWalletDetails(walletDetails);
      } catch (error) {
        console.error(error);
      }
    }
    fetchWalletDetails();
  }, [dispatch, user?._id]);

  return (
    <div className="flex min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta">
      <div
        className="absolute top-8 right-8 p-3 rounded-lg bg-white hover:cursor-pointer"
        onClick={handleOpenModal}>
        <MdClose className="w-5 h-5" />
      </div>
      {selectedTab < 3 && (
        <>
          <div className="hidden lg:block fixed top-0 left-0 h-full lg:w-[22.5rem] xxl:w-[30rem] max-w-[480px] z-40 bg-white overflow-y-auto custom-scrollbar">
            <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </div>

          <div className="flex-1 flex justify-center lg:ml-[22.5rem] xxl:ml-[480px] mt-[5%] xxl:mt-[8%] relative">
            <div className="w-full max-w-[480px]">
              {selectedTab === 0 && (
                <WithdrawalMethod
                  formData={formData}
                  setFormData={setFormData}
                  setSelectedTab={setSelectedTab}
                />
              )}

              {selectedTab === 1 && (
                <WithdrawalAmount
                  formData={formData}
                  setFormData={setFormData}
                  walletDetails={walletDetails}
                  setSelectedTab={setSelectedTab}
                />
              )}
              {selectedTab === 2 && (
                <ConfirmWithdraw
                  formData={formData}
                  setFormData={setFormData}
                  walletDetails={walletDetails}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </div>
          </div>
        </>
      )}
      {selectedTab === 3 && (
        <MessageWrapper
          heading={'Withdraw request successful'}
          subheading={
            'Admin will review the request and the amount will be transferred to the selected method within 3 days.'
          }>
          <div className="flex items-center justify-start gap-4">
            <NavLink
              to="/earning"
              className="flex items-center rounded-3xl justify-center bg-white px-6 py-3 text-black font-bold cursor-pointer">
              View earnings
            </NavLink>
            <NavLink
              to="/dashboard"
              className="flex items-center justify-center  rounded-3xl bg-Primary-400 px-6 py-3 text-white font-bold cursor-pointer">
              Go to dashboard
            </NavLink>
          </div>
        </MessageWrapper>
      )}

      {isModalOpen && (
        <PopupComponent>
          <img src="red-close-icon.svg" alt="close-Icon" className="w-16 h-16" />

          <p className="text-Neutral-900 font-semibold text-xl text-center">
            Want to cancel withdraw?
          </p>

          <div className="flex items-center justify-between gap-2 w-full">
            <button
              onClick={() => handleCloseModal()}
              className="py-3 basis-1/2 px-6 text-center bg-Primary-100 rounded-3xl text-black text-base font-bold">
              No
            </button>
            <button
              onClick={() => navigate('/earning')}
              className="py-3 basis-1/2 px-6 text-center whitespace-nowrap bg-Error-500 rounded-3xl text-white text-base font-bold">
              Yes cancel
            </button>
          </div>
        </PopupComponent>
      )}
    </div>
  );
};

export default WithdrawMoneyComponent;
