import React, { useState, useEffect, useRef } from 'react';
import { TbLoader3 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, updateDistributors } from '../slices/userSlice';
import Utils from '../utility';
import WalletService from '../services/wallet';
import UserService from '../services/user';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from '../utils/dateUtils';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import ApproveModal from './approved-modal';

function DistributorDetails() {
  const user = useSelector((state) => state.user);
  const { distributors, selectedDistributor } = user;
  const [showTransactionPopup, setShowTransactionPopup] = useState(false);
  const [selectedTransactionData, setSelectedTransactionData] = useState(null);
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeADs, setActiveADs] = useState(0);
  const [activeSubscribers, setActiveSubscribers] = useState(0);
  const [commission, setCommission] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [loadingApproval, setloadingApproval] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    sortConfig: { key: null, direction: 'ascending' },
  });

  const roleName = selectedDistributor?.roleId?.name || selectedDistributor?.role?.name;

  const MDName = selectedDistributor?.invitedBy?.name;

  const status = selectedDistributor?.status;
  const statusClassesTransactions = {
    rejected: {
      bg: 'bg-Error-50',
      text: 'text-Error-500',
    },
    completed: {
      bg: 'bg-Success-100',
      text: 'text-Success-500',
    },
  };
  const statusClasses = {
    active: {
      bg: 'bg-Success-100',
      dot: 'bg-Success-500',
      text: 'text-Success-500',
    },
    inactive: {
      bg: 'bg-Neutral-100',
      dot: 'bg-Neutral-400',
      text: 'text-Neutral-400',
    },
    pending: {
      bg: 'bg-Warning-50',
      dot: 'bg-Warning-500',
      text: 'text-Warning-500',
    },

    'Invitation Sent': {
      bg: 'bg-Neutral-100',
      dot: 'bg-Neutral-400',
      text: 'text-Neutral-400',
    },
    rejected: {
      bg: 'bg-Error-50',
      dot: 'bg-Error-500',
      text: 'text-Error-500',
    },
  };
  {
    /* subscriber and commission details section  */
  }

  const statusContent = {
    active: [
      { label: 'Approved on', value: formatTimestamp(selectedDistributor?.addedOn) },
      { label: 'Phone', value: selectedDistributor?.phone || '__' },
      { label: 'Gender', value: selectedDistributor?.Gender || '__' },
      { label: 'Email', value: selectedDistributor?.email || '__' },
      { label: 'Address', value: selectedDistributor?.Address || '__' },
      { label: 'Date of birth', value: selectedDistributor?.dob || '__' },
    ],
    rejected: [
      { label: 'Invite sent', value: formatTimestamp(selectedDistributor?.addedOn) },
      { label: 'Phone', value: selectedDistributor?.phone || '__' },
      { label: 'Gender', value: selectedDistributor?.Gender || '__' },
      { label: 'Email', value: selectedDistributor?.email || '__' },
      { label: 'Address', value: selectedDistributor?.Address || '__' },
      { label: 'Date of birth', value: selectedDistributor?.dob || '__' },
    ],
    'Pending approval': [
      { label: 'Invite sent', value: formatTimestamp(selectedDistributor?.addedOn) },
      { label: 'Phone', value: selectedDistributor?.phone || '__' },
      { label: 'Gender', value: selectedDistributor?.Gender || '__' },
      { label: 'Email', value: selectedDistributor?.email || '__' },
      { label: 'Address', value: selectedDistributor?.Address || '__' },
      { label: 'Date of birth', value: selectedDistributor?.dob || '__' },
    ],
    inactive: [
      {
        label: 'Inactive after',
        value: formatTimestamp(selectedDistributor?.inactiveAfter) || '__',
      },
      { label: 'Phone', value: selectedDistributor?.phone || '__' },
      { label: 'Gender', value: selectedDistributor?.Gender || '__' },
      { label: 'Email', value: selectedDistributor?.email || '__' },
      { label: 'Address', value: selectedDistributor?.Address || '__' },
      { label: 'Date of birth', value: selectedDistributor?.dob || '__' },
    ],
    'Invitation Sent': [
      { label: 'Invite sent', value: selectedDistributor?.sent || '__' },
      { label: 'Email', value: selectedDistributor?.email || '__' },
    ],
  };

  const statusFields = statusContent[selectedDistributor?.status] || [];
  // Dummy values for counts, growth, and transactions
  const totalSubscriptions = 80;
  const totalADs = 5;
  const totalSubscribers = 200;

  // Separate growth values for each condition
  const activeSubscribersGrowth = -5; // Negative for loss
  const totalSubscriptionsGrowth = 2; // Positive for growth
  const totalADsGrowth = 3; // Positive for growth
  const totalSubscribersGrowth = -1; // Negative for loss
  const commissionGrowth = 10; // Positive for growth

  // Dynamic data mapping based on role and distributor type
  const dataMapping = [
    {
      condition: user.roleId.name === 'Master Distributor',
      left: {
        title: 'Active subscribers',
        value: activeSubscribers || '__',
        growth: activeSubscribersGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? activeSubscribersGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: activeSubscribersGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: activeSubscribersGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
      right: {
        title: 'My Commissions',
        value: commission || '__',
        growth: commissionGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? commissionGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: commissionGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: commissionGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
    },
    {
      condition:
        user.roleId.name === 'Admin' &&
        selectedDistributor?.role?.name === 'Authorized Distributor',
      left: {
        title: 'Active subscribers',
        value: activeSubscribers || '__',
        growth: activeSubscribersGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? activeSubscribersGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: activeSubscribersGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: activeSubscribersGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
      right: {
        title: 'Active subscriptions',
        value: totalSubscriptions || '__',
        growth: totalSubscriptionsGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? totalSubscriptionsGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: totalSubscriptionsGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: totalSubscriptionsGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
    },
    {
      condition:
        user.roleId.name === 'Admin' && selectedDistributor?.role?.name === 'Master Distributor',
      left: {
        title: 'Total ADs',
        value: totalADs || '__',
        growth: totalADsGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? totalADsGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: totalADsGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: totalADsGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
      right: {
        title: 'Total subscribers',
        value: totalSubscribers || '__',
        growth: totalSubscribersGrowth,
        bgColor:
          selectedDistributor?.status === 'active'
            ? totalSubscribersGrowth > 0
              ? 'bg-Success-100'
              : 'bg-Error-50'
            : 'bg-white',
        arrowIcon: totalSubscribersGrowth > 0 ? '/arrow-up-green.svg' : 'arrow-down-red.svg',
        arrowAltText: totalSubscribersGrowth > 0 ? 'Arrow-Up Icon' : 'Arrow-Down Icon',
      },
    },
  ];
  // Select the appropriate config based on role and distributor type
  const config = dataMapping.find((item) => item.condition);
  console.log('config', config);
  console.log(user.roleId.name);

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalTransactions) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  useEffect(() => {
    if (!selectedDistributor) {
      navigate(-1);
      return;
    }
    async function fetchData() {
      try {
        setLoading(true);
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }
        const queryParams = `?skip=${
          (pagination.currentPage - 1) * pagination.rowsPerPage
        }&limit=${pagination.rowsPerPage}`;
        const response =
          user?.roleId?.name === 'Admin'
            ? await new WalletService().getDistributorTransactionsList(selectedDistributor?._id,queryParams)
            : await new WalletService().fetchDistributorTransactionsList(
                selectedDistributor?._id,
                queryParams
              );
        setTotalTransactions(response?.totalCount);
        setTransactions(response?.transactions);

        const summaryResponse =
          user?.roleId?.name === 'Admin'
            ? await new WalletService().getDistributorSummary(selectedDistributor?._id)
            : await new WalletService().fetchDistributorSummary(selectedDistributor?._id);
        setApiResponse(summaryResponse);
        setActiveADs(summaryResponse?.activeAuthorizedDistributorsCount);
        setActiveSubscribers(summaryResponse?.activeSubscribersCount);
        setCommission(summaryResponse?.commission);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [selectedDistributor?._id,pagination.currentPage]);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleApprove = async (selectedStatus) => {
    try {
      setloadingApproval(true);
      let userId = selectedDistributor?._id;
      let payload = {
        email: selectedDistributor?.email,
        roleId: selectedDistributor?.roleId,
        status: selectedStatus === 'Approved' ? 'active' : 'rejected',
        userId: selectedDistributor?.userId,
        isApprove: selectedStatus === 'Approved' ? true : false,
        approvedOn: Date.now(),
      };
      const response = await new UserService().updateUserByAdmin(userId, payload);
      if (response) {
        setShowApprovalModal(true);
      }
    } catch (error) {
      toast.error(`Error approving distributor:${error}`, {
        autoClose: 2000,
      });
    } finally {
      setloadingApproval(false);
    }
  };

  return (
    <>
      <div className={`flex justify-center min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta`}>
        <div className="max-w-200.5 w-full flex flex-col gap-4 items-start mt-5">
          {loading ? (
            <div className="absolute inset-0 flex items-center justify-center rounded-[24px] bg-white bg-opacity-75 z-50">
              <TbLoader3 className="animate-spin h-10 w-10 text-black" />
            </div>
          ) : (
            <>
              <div
                className="flex gap-2 py-2 px-4  items-center rounded-3xl bg-white cursor-pointer"
                onClick={() => navigate(-1)}>
                <img src="/back-arrow-icon.svg" alt="back-arrow-icon" />
                <p className="text-Neutral-900 font-bold">Back</p>
              </div>
              <div className="w-full bg-white rounded-3xl flex flex-col gap-6 justify-between p-8 mt-5">
                {/* profile picture section  */}
                <div className="flex gap-3 items-center">
                  {selectedDistributor?.image ? (
                    <img
                      src={selectedDistributor?.image}
                      alt={selectedDistributor?.name}
                      className="w-16 h-16  object-cover cursor-pointer"
                    />
                  ) : (
                    <div
                      className={`w-16 h-16 ${roleName === 'Master Distributor' ? 'bg-purple-500' : 'bg-Primary-500'}  rounded-full flex items-center justify-center text-white text-3xl font-extrabold`}>
                      {selectedDistributor?.name
                        ? `${selectedDistributor?.name.substring(0, 1).toUpperCase()}${selectedDistributor?.name
                            .split(' ')
                            .map((word) => word[0])
                            .join('')
                            .substring(1, 2)
                            .toUpperCase()}`
                        : ''}
                    </div>
                  )}
                  <div className="flex flex-col gap-1 items-start">
                    <div className="text-[25px] font-extrabold text-Neutral-900">
                      {selectedDistributor?.name || selectedDistributor?.email}
                    </div>
                    <div
                      className={`${roleName === 'Master Distributor' ? 'bg-purple-50 text-purple-500' : 'text-Primary-500  bg-Primary-100'}  w-fit px-3 py-2 rounded-2xl font-bold`}>
                      {roleName}
                    </div>
                  </div>
                  <div
                    className={`ml-auto p-2 flex items-center gap-1 rounded-5-5xl ${status ? statusClasses[status]?.bg : ''}`}>
                    <div
                      className={`w-2 h-2 rounded-full ${status ? statusClasses[status]?.dot : ''}`}></div>
                    <p
                      className={`font-semibold text-base capitalize ${status ? statusClasses[status]?.text : ''}`}>
                      {status}
                    </p>
                  </div>
                </div>
                {/* details section  */}
                <div className="flex px-6">
                  <div className="flex flex-col gap-4 basis-1/2">
                    {statusFields
                      .slice(0, Math.ceil(statusFields.length / 2))
                      .map((field, index) => (
                        <div key={index} className="flex flex-col">
                          <p className="text-Neutral-500 text-sm">{field.label}</p>
                          <p className="text-Neutral-900 text-base">{field.value}</p>
                        </div>
                      ))}
                  </div>
                  <div className="flex flex-col gap-4 basis-1/2">
                    {statusFields.slice(Math.ceil(statusFields.length / 2)).map((field, index) => (
                      <div key={index} className="flex flex-col">
                        <p className="text-Neutral-500 text-sm">{field.label}</p>
                        <p className="text-Neutral-900 text-base">{field.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {user.roleId.name === 'Admin' && (
                  <div className="flex items-center justify-between w-full">
                    {roleName === 'Authorized Distributor' && (
                      <div className=" pl-[9px] py-2 pr-6 bg-Neutral-50 rounded-[36px] justify-start items-center gap-2 inline-flex">
                        <div
                          className={`w-8 h-8 bg-purple-500  rounded-full flex items-center justify-center text-white text-sm font-extrabold`}>
                          {MDName
                            ? `${MDName.substring(0, 1).toUpperCase()}${MDName.split(' ')
                                .map((word) => word[0])
                                .join('')
                                .substring(1, 2)
                                .toUpperCase()}`
                            : ''}
                        </div>
                        <div className="flex-col justify-start items-start gap-1 inline-flex">
                          <div className="text-Neutral-400 text-xs font-normal ">Added by</div>
                          <div className="text-Neutral-900 text-base font-bold">{MDName}</div>
                        </div>
                      </div>
                    )}
                    {status === 'active' && (
                      <div className="flex items-center gap-2 ml-auto">
                        <div
                          onClick={() => navigate('/view-all-info')}
                          className={`text-Neutral-700  bg-Neutral-50  w-fit px-6 py-3 rounded-2xl font-bold`}>
                          View all info
                        </div>
                        <div
                          className={`text-Primary-500  bg-Primary-100  w-fit px-6 py-3 rounded-2xl font-bold`}>
                          Edit status
                        </div>
                      </div>
                    )}
                    {status === 'Invitation Sent' && (
                      <div className="text-Neutral-500">Waiting for updated submission</div>
                    )}
                    {status === 'pending' && (
                      <div
                        className="py-3 px-6 rounded-3xl bg-Primary-400 text-Neutral-900 font-bold cursor-pointer"
                        onClick={() => handleApprove('Approved')}>
                        {loadingApproval ? (
                          <TbLoader3 className="animate-spin text-black mx-6" size={24} />
                        ) : (
                          'Approve'
                        )}
                      </div>
                    )}
                    {status === 'rejected' && (
                      <div className="py-3 px-6 rounded-3xl bg-Primary-400 text-Neutral-900 font-bold">
                        Check submission
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* subscriber and commission details section  */}
              <div className="flex gap-4 w-full">
                {/* Left section */}
                <div
                  className={`basis-1/2 p-6 rounded-2xl flex items-center gap-4 ${config?.left.bgColor}`}>
                  <div className="flex flex-col grow gap-3">
                    <p className="text-xl text-Neutral-700 font-semibold">{config?.left.title}</p>
                    <p className="text-Neutral-900 text-3xl font-extrabold">{config?.left.value}</p>
                  </div>
                  {selectedDistributor?.status === 'active' && (
                    <div className="flex items-center">
                      <p>{config?.left.growth}%</p>
                      <img
                        src={config?.left.arrowIcon}
                        alt={config?.left.arrowAltText}
                        className="h-6 w-6"
                      />
                    </div>
                  )}
                </div>

                {/* Right section */}
                <div
                  className={`basis-1/2 p-6 rounded-2xl flex items-center gap-4 ${config?.right.bgColor}`}>
                  <div className="flex flex-col grow gap-3">
                    <p className="text-xl text-Neutral-700 font-semibold">{config?.right.title}</p>
                    <p className="text-Neutral-900 text-3xl font-extrabold">
                      {config?.right.value}
                    </p>
                  </div>
                  {selectedDistributor?.status === 'active' && (
                    <div className="flex items-center">
                      <p>{config?.right.growth}%</p>
                      <img
                        src={config?.right.arrowIcon}
                        alt={config?.right.arrowAltText}
                        className="h-6 w-6"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full pt-8 px-4 pb-4 bg-white rounded-3xl">
                <h2 className="text-Neutral-900 text-2xl font-extrabold">
                  {user?.roleId?.name === 'Admin' ? 'Payment' : 'Transactions'}
                </h2>
                {transactions.length === 0 ? (
                  <div className="flex flex-col gap-3 items-center justify-center">
                    <img src="/receipt_long.svg" alt="receipt image" />
                    <p className="text-Neutral-500">
                      {user?.roleId?.name === 'Admin'
                        ? 'Once the MD approved transactions will be available here'
                        : 'Once the AD approved transactions will be available here'}
                    </p>
                  </div>
                ) : (
                  <div
                    className="size-full pt-6 flex flex-col overflow-auto"
                    style={{ scrollbarWidth: 'thin' }}>
                    <table className="w-full table-fixed border-collapse">
                      <thead>
                      <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                          <th className="text-left text-xs p-4 font-medium cursor-pointer uppercase">
                            Date
                          </th>
                          <th className="text-left  text-xs p-4 font-medium cursor-pointer uppercase">
                            Description
                          </th>
                          <th className="text-left  text-xs p-4 font-medium cursor-pointer uppercase">
                            Trx. ID
                          </th>

                          <th className="text-left  text-xs p-4 font-medium cursor-pointer">
                            {user?.roleId?.name === 'Admin' ? 'Amount' : 'My commission'}
                          </th>
                          {user?.roleId?.name === 'Admin' &&
                            roleName === 'Authorized Distributor' && (
                              <th className="text-left  text-xs p-4 font-medium cursor-pointer uppercase">
                                AD commission
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody className="font-jakarta font-normal overflow-y-auto">
                        {transactions.length === 0 ? (
                          <tr>
                            <td colSpan="4" className="py-2 mt-10 text-[#6C6F71] text-center">
                              No Data Found
                            </td>
                          </tr>
                        ) : (
                          transactions.map((transaction, index) => (
                            <tr
                              key={index}
                              className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50 grid grid-cols-[repeat(auto-fit,_minmax(150px,_1fr))]"
                              onClick={() => {
                                setShowTransactionPopup(true);
                                setSelectedTransactionData(transaction);
                              }}>
                              <td className="p-4 text-Neutral-700 text-base ">
                                {formatTimestamp(transaction?.addedOn)}
                              </td>

                              <td className="p-4 text-Neutral-700 text-base truncate">
                                {transaction?.description}
                              </td>
                              <td className="p-4 text-Neutral-700 text-base min-w-0 truncate">
                                {transaction?.transactionId}
                              </td>

                              <td className="p-4 text-Neutral-700 text-base min-w-0 truncate">
                                <span
                                  className={`${
                                    transaction?.type === 'Credit'
                                      ? 'text-green-500'
                                      : 'text-red-500'
                                  }`}>
                                  {transaction?.type === 'Credit'
                                    ? `+$${transaction?.amount}`
                                    : `-$${transaction?.amount}`}
                                </span>
                              </td>
                              {user?.roleId?.name === 'Admin' &&
                                roleName === 'Authorized Distributor' && (
                                  <td className="p-4 text-Neutral-700 text-base">
                                    {transaction?.AdCommission || '_'}
                                  </td>
                                )}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <div className="flex justify-end items-center mt-4">
                      <div className="flex items-center gap-10">
                        <div className="flex items-center">
                          <span>Rows per page:</span>
                          <div
                            className="relative"
                            onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                            ref={paginationRef}>
                            <img
                              src="./chevron_backward.svg"
                              alt="Chevron-Down Icon"
                              className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                              style={{ transitionDuration: '100ms' }}
                            />
                            <select
                              value={pagination.rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none">
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                        </div>
                        <span>
                          {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
                          {pagination.currentPage * pagination.rowsPerPage > totalTransactions
                            ? totalTransactions
                            : pagination.currentPage * pagination.rowsPerPage}{' '}
                          of {totalTransactions}
                        </span>
                        <div>
                          <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                            &lt;
                          </button>
                          <button className="p-2 rounded-lg" onClick={handleNextPage}>
                            &gt;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* transaction popup  */}
      {user?.roleId?.name === 'Admin' && showTransactionPopup && selectedTransactionData && (
        <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
          <div className="p-6 w-136 relative bg-white rounded-3xl flex flex-col  gap-12 font-jakarta">
            <div className="flex flex-col items-start gap-2">
              <div
                className={` inline-flex p-2 items-center justify-center rounded-8 ${statusClassesTransactions['completed']?.bg ? `${statusClassesTransactions['completed'].bg} ${statusClassesTransactions['completed'].text}` : ''}`}>
                <p
                  className={`text-base  font-semibold  capitalize ${statusClassesTransactions['completed']?.text ? `${statusClassesTransactions['completed'].text}` : ''} `}>
                  completed
                </p>
              </div>
              <p className="text-Neutral-900 text-2xl font-extrabold">-$55</p>
              <div>
                <span className="text-[#141415] text-base font-normal leading-normal">
                  User activation by
                </span>
                <span className="text-[#009dff] pl-1 text-base font-normal underline leading-normal">
                  {MDName}
                </span>
              </div>
            </div>
            <div className=" flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch text-[#141415] text-xl font-extrabold leading-7">
                Transaction details
              </div>
              <div className="flex w-full gap-4">
                <div className="w-1/2 flex flex-col items-start gap-4">
                  <div className="">
                    <p className="text-Neutral-400 text-sm">Transaction ID</p>
                    <p className="text-Neutral-900 text-base">228294</p>
                  </div>
                  <div className="">
                    <p className="text-Neutral-400 text-sm">Subscription fee</p>
                    <p className="text-Neutral-900 text-base">$1</p>
                  </div>
                  <div className="">
                    <p className="text-Neutral-400 text-sm">MD Commission</p>
                    <p className="text-Neutral-900 text-base">$1.99</p>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col items-start gap-4">
                  <div className="">
                    <p className="text-Neutral-400 text-sm">Plan fee</p>
                    <p className="text-Neutral-900 text-base">$48.99</p>
                  </div>
                  <div className="">
                    <p className="text-Neutral-400 text-sm">AD Commission</p>
                    <p className="text-Neutral-900 text-base">$1</p>
                  </div>
                  <div className="">
                    <p className="text-Neutral-400 text-sm">Taxes</p>
                    <p className="text-Neutral-900 text-base">$1.99</p>
                  </div>
                </div>
              </div>
            </div>
            {/* close button  */}
            <div
              className="absolute top-2 right-2 p-3  hover:cursor-pointer"
              onClick={() => setShowTransactionPopup(false)}>
              <MdClose className="w-6 h-6" />
            </div>
          </div>
        </div>
      )}
      {showApprovalModal && (
        <ApproveModal
          email={selectedDistributor?.email}
          onClose={() => setShowApprovalModal(false)}
        />
      )}
    </>
  );
}

export default DistributorDetails;
