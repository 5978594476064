import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import WalletService from '../service/walletService';
import AddMoney from './addMoney';
import { useSelector } from 'react-redux';
import MessageWrapper from '../common/MessageWrapper';

function AddMoneyComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showSuccess, setSuccess] = useState(false);

  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const user = useSelector((state) => state?.user);
  useEffect(() => {
    // Check if the URL contains the success parameter
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');

    const amountFromUrl = searchParams.get('amount');

    // If payment is successful and amount is present, set selectedTab to 2 and update the amount
    if (success && amountFromUrl) {
      setSelectedTab(2);
      setSuccess(true)
      setAmount(amountFromUrl);
    }
  }, [location.search]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        productName: 'Transfer',
        amount: parseFloat(amount),
        successUrl: `https://myezmobile.info/add-money?success=true&amount=${amount}`,
        cancelUrl: 'https://myezmobile.info/add-money',
        userID: user?._id,
      };
      const response = await new WalletService().createCheckoutSession(payload);
      window.location.href = response;
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating session:', error);
      setIsLoading(false);
    }
  };
  return (
    <div className="flex min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta">
      {selectedTab < 1 && (
        <div className="w-full max-w-[480px] mx-auto mt-[5%] xxl:mt-[8%] relative">
          {selectedTab === 0 && (
            <AddMoney
              amount={amount}
              setAmount={setAmount}
              setSelectedTab={setSelectedTab}
              handleGoBack={handleGoBack}
              onSubmit={onSubmit}
            />
          )}
        </div>
      )}
      {showSuccess && (
        <MessageWrapper heading={`$${amount} added your wallet successfully`}>
          <div className="flex items-center justify-start gap-4">
            <NavLink
              to="/earning"
              className="flex items-center rounded-3xl justify-center bg-white px-6 py-3 text-black font-bold cursor-pointer">
              View transactions
            </NavLink>
            <NavLink
              to="/dashboard"
              className="flex items-center justify-center  rounded-3xl bg-Primary-400 px-6 py-3 text-white font-bold cursor-pointer">
              Go to dashboard
            </NavLink>
          </div>
        </MessageWrapper>
      )}
    </div>
  );
}

export default AddMoneyComponent;
