
export function getGradient(ctx, chartArea, chartColour) {
    let width, height, gradient;

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, chartColour?.gradientStart);
      gradient.addColorStop(1, chartColour?.gradientEnd);
    }

    return gradient;
}
  
export const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('.custom-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.classList.add('custom-tooltip');

      // Set tooltip styles
      tooltipEl.style.backgroundColor = 'white';
      tooltipEl.style.borderRadius = '16px';
      tooltipEl.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)';
      tooltipEl.style.padding = '4px 12px';
      tooltipEl.style.color = '#000';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, -100%)';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.transition = 'opacity 0.3s ease, left 0.3s ease, top 0.3s ease';
      tooltipEl.style.display = 'flex';
      tooltipEl.style.alignItems = 'center';
      tooltipEl.style.justifyContent = 'center';
      tooltipEl.style.height = '26px';
      tooltipEl.style.width = '41px';
      tooltipEl.style.fontFamily = 'Plus Jakarta Sans, sans-serif';
      tooltipEl.style.fontSize = '16px';
      tooltipEl.style.fontWeight = '600';
      tooltipEl.style.lineHeight = '1';
      tooltipEl.style.textAlign = 'center';

      // Create content element
      const contentEl = document.createElement('div');
      contentEl.classList.add('tooltip-content');
      tooltipEl.appendChild(contentEl); // Add contentEl to tooltipEl

      // Create arrow element
      const arrowEl = document.createElement('div');
      arrowEl.classList.add('tooltip-arrow');
      arrowEl.style.position = 'absolute';
      arrowEl.style.width = '0px';
      arrowEl.style.height = '0px';
      arrowEl.style.borderLeft = '6px solid transparent';
      arrowEl.style.borderRight = '6px solid transparent';
      arrowEl.style.borderTop = '6px solid white';
      arrowEl.style.top = '100%';
      arrowEl.style.left = '50%';
      arrowEl.style.transform = 'translateX(-50%)';
      tooltipEl.appendChild(arrowEl); // Append arrow to tooltipEl
      // Attach the tooltip element to the DOM
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  export const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set tooltip text
    if (tooltip.body) {
      const value = tooltip.body[0].lines[0].replace(/[^0-9.]/g, '');
      const contentEl = tooltipEl.querySelector('.tooltip-content');
      contentEl.textContent = value; // Update only the contentEl text
    }

    // Position tooltip
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY - 25 + 'px';
  };

 export  const createDottedGridLinesPlugin = (gridLineColour) => ({
    id: 'dottedGridLines',
    beforeDatasetsDraw: (chart) => {
      const { ctx, chartArea, scales } = chart;
      const yScale = scales.y;

      ctx.save();
      ctx.strokeStyle = gridLineColour; // Grid line color
      ctx.lineWidth = 1; // Line width
      ctx.setLineDash([3, 3]); // Dotted line style

      // Define the number of grid lines you want
      const numberOfGridLines = 3;
      const stepSize = (yScale.max - yScale.min) / (numberOfGridLines + 1);

      // Draw each grid line, avoiding overlap with the x-axis
      for (let i = 1; i <= numberOfGridLines; i++) {
        const yValue = yScale.min + i * stepSize;
        const y = yScale.getPixelForValue(yValue);

        // Skip drawing if the line is at the x-axis position
        if (y !== chartArea.bottom) {
          ctx.beginPath();
          ctx.moveTo(chartArea.left, y);
          ctx.lineTo(chartArea.right, y);
          ctx.stroke();
        }
      }

      ctx.restore();
    },
  });