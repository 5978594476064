import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../slices/userSlice';
import UserService from '../services/user';
import { toast } from 'react-toastify';

function EditZelleDetails({ formData, setFormData, setSelectedTab }) {
    const [initialFormData, setInitialFormData] = useState(formData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
  
    const handleInputChange = (e) => {
      const value = e.target.value;
  
      // Determine the max length based on input type
      // const maxLength = value.includes('@') ? email : phone;
  
      // // If the current input value exceeds max length, truncate it
      // if (value.length > maxLength) {
      //   return; // Prevent further input
      // }
  
      setFormData((prevData) => ({
        ...prevData,
        paymentDetails: {
          ...prevData.paymentDetails,
          zelleDetails: value,
        },
      }));
  
      if (!value) {
        setErrors({
          zelleDetails: 'Email or phone is Required',
        });
      } else {
        // Clear the specific error for the input being changed
        setErrors(null);
      }
    };
  
    const handleSubmit = async () => {
      const newErrors = {};
      if (formData.zelleDetails.trim() === '') {
        newErrors.zelleDetails = 'detail is required';
      }
      // If there are errors, set them and stop submission
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
      if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
        // No changes, don't call API
        console.log("form data", formData);
        console.log("initial formdata", initialFormData);
        
        setSelectedTab(0);
        return;
      }
      const updatedFormData = {
        userId: user._id,
        paymentMethods: [
          {
            paymentMethodType: 'Zelle',
            zelleDetails: {
              email: formData.zelleDetails,
            },
          },
        ],
      };
      try {
        setLoading(true);
        const response = await new UserService().updatePaymentDetails(updatedFormData);
        const updatedUserData = { ...user, ...updatedFormData, zelleDetails: formData.zelleDetails };
        if (response) {
          dispatch(updateUser(updatedUserData));
          setInitialFormData(formData);
          setSelectedTab(0);
        }
      } catch (error) {
        console.error(error);
        toast.error('Unable to Submit Details', 4000);
      } finally {
        setLoading(false);
      }
    };
  return (
    <div className=" max-w-[480px] w-full flex flex-col gap-8 mx-auto  mt-5">
      <FormSectionTitle
        heading={'Provide your Zelle information'}
        subHeading={
          'Provide email or phone of your Zelle account to get access all primary features and withdraw earnings'
        }
      />
      <div className="flex flex-col gap-8">
      <div className="flex flex-col items-start gap-2">
            <label htmlFor="zelleDetails" className="text-Neutral-500">Email or phone</label>
            <input
              id="zelleDetails"
              type="text"
              placeholder="Enter your email or phone"
              value={formData.zelleDetails}
              onChange={handleInputChange}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border ${errors?.zelleDetails ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
            {errors?.zelleDetails && (
              <p className="text-Error-500 text-sm first-letter:capitalize">
                {errors?.zelleDetails}
              </p>
            )}
          </div>

      </div>
      <div className="flex justify-between items-center  mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Error-500"
          onClick={() => setSelectedTab(0)}>
          Cancel
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default EditZelleDetails;
