import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IoMdCalendar } from 'react-icons/io';
import { GenderOptions, ReferOptions } from '../constants';
import { parseDate, formatDate, isValidDate, calculateAge } from '../utils/dateUtils';
import Calendar from '../utils/Calender';
import { debounce } from '../utils/Debounce';
import UserService from '../services/user';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../slices/userSlice';
import CustomInputField from '../common/onboarding/CustomInputField ';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import Dropdown from '../common/onboarding/Dropdown';
import { OnboardingInputMaxLength } from '../constants/index';

import { TbLoader3 } from 'react-icons/tb';
import StepIndicator from '../common/StepIndicator';

function PersonalDetails({
  selectedTab,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  totalSteps = 5,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { name, gender, postalCode } = OnboardingInputMaxLength.personalDetails;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [initialFormData, setInitialFormData] = useState(formData);
  const [isCustomGender, setIsCustomGender] = useState(false);
  const [customGenderInput, setCustomGenderInput] = useState(''); // Track custom input

  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  const dateFromString = new Date(formData?.dob?.split('T')[0]);
  const formattedDate = formatDate(dateFromString, 'dd-MM-yyyy');
  const [dobInput, setDobInput] = useState(formattedDate || '');

  const handleInputChange = (field, name) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        [field]: value,
      },
    }));

    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${name} is required`, // Set the error if the input is empty
      }));
    } else {
      // Clear the specific error for the input being changed
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null, // Clear the error for this specific field
      }));
    }
  };

  const handleCustomGenderInput = (e) => {
    const value = e.target.value;
    setCustomGenderInput(value);
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        gender: value,
      },
    }));
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: 'Gender is required',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: null,
      }));
    }
  };

  const handlePronounSelection = (pronoun) => {
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        genderRefer: pronoun,
      },
    }));
  };

  const handleDateInputChange = (e) => {
    let value = e.target.value;

    // Allow only numbers and dashes to be entered, and prevent other characters
    const regex = /^[0-9-]*$/;

    // If input does not match the regex, return and do not update the state
    if (!regex.test(value)) {
      return;
    }

    // Restrict input to a maximum length of 10 characters (mm-dd-yyyy)
    if (value.length > 10) {
      return;
    }

    // Validate format (dd-mm-yyyy) when the input reaches 10 characters
    if (value.length === 10) {
      const isValidFormat = /^\d{2}-\d{2}-\d{4}$/.test(value);
      if (!isValidFormat) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: 'Invalid date format. Use mm-dd-yyyy.',
        }));
        return; // Do not update the state if the format is invalid
      } else {
        const age = calculateAge(value);
        if (age < 18) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dob: "DOB can't be less than 18 years",
          }));
          return;
        }

        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: null, // Clear the error if the format is valid
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: 'Please enter the date in mm-dd-yyyy format.',
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails, // Spread the existing personal details
        dob: value, // Update gender with the new value
      },
    }));
    // Set the value back to state if it matches the expected input
    setDobInput(value);
  };

  const handleGender = (selectedGender) => {
    if (selectedGender === 'Custom') {
      setFormData((prevData) => ({
        ...prevData,
        personalDetails: {
          ...prevData.personalDetails, // Spread the existing personal details
          gender: '', // Update gender with the new value
        },
      }));
      setIsCustomGender(true); // Show custom gender inputs
    } else {
      setIsCustomGender(false); // Hide custom gender inputs
      setFormData((prevData) => ({
        ...prevData,
        personalDetails: {
          ...prevData.personalDetails, // Spread the existing personal details
          gender: selectedGender, // Update gender with the new value
        },
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: null, // Set the error if the input is empty
      }));
    }
  };


  const handleDateSelection = (selectedDate) => {
    const age = calculateAge(selectedDate);
    if (age < 18) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "DOB can't be less than 18 years",
      }));
      return; // Stop further execution if age is invalid
    }
    const formattedDate = formatDate(selectedDate, 'dd-MM-yyyy');

    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        dob: formattedDate,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      dob: null,
    }));
    setDobInput(formattedDate); // Update local input state
    setCalendarOpen(false);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalendarOpen(false); // Close the calendar if clicked outside
      }
    },
    [calendarRef]
  );

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup on unmount
    };
  }, [calendarOpen, handleClickOutside]);

  const handleSubmit = async () => {
    const parsedDate = parseDate(dobInput, 'dd-MM-yyyy');
    const formattedDate = formatDate(parsedDate, 'yyyy-MM-dd');

    // Reset errors
    setErrors({});
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
    if (!formData.gender) {
      newErrors.gender = 'Gender is required';
    }
    // if (!formData.genderRefer) {
    //   newErrors.genderRefer="Please refer me as"
    // }
    if (!formData.dob) {
      newErrors.dob = 'Dob is required';
    }
    if (!formattedDate) {
      newErrors.dob = 'Invalid date format (use dd-MM-yyyy)';
    }
    if (!formData.postalCode) {
      newErrors.postalCode = 'Postal code is required';
    }

    // If there are errors, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      // No changes, don't call API
      setSelectedTab((prev) => prev + 1);
      return;
    }

    const updatedFormData = {
      userId: user._id,
      name: formData.name,
      gender: formData.gender,
      dob: formattedDate,
      postalCode: formData.postalCode,
    };
    if (formData.genderRefer && formData.genderRefer.trim() !== '') {
      updatedFormData.genderRefer = formData.genderRefer;
    }
    try {
      setLoading(true);
      const response = await new UserService().updateFirstTimeProfile(updatedFormData);
      const updatedUserData = { ...user, ...updatedFormData };

      if (response) {
        dispatch(updateUser(updatedUserData));
        setInitialFormData(formData);
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      setLoading(false);
    }
  };

  const dateObject = parseDate(formData.dob, 'dd-MM-yyyy') || new Date(); // Fallback to current date

  return (
    <>
      <div className="w-full flex flex-col gap-10">
        <FormSectionTitle
          heading={'Provide personal details'}
          subHeading={'Enter the subscribers residential details'}
        />
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'name'}
            labelText={'Name'}
            maxLength={name}
            type={'text'}
            placeholder={'Enter your name'}
            value={formData.name}
            onChange={handleInputChange('name', 'name')}
            error={errors?.name}
          />
          <Dropdown
            label="Gender"
            placeHolder="Select your gender"
            selectedValue={isCustomGender ? 'Custom' : formData.gender}
            options={GenderOptions}
            onSelect={handleGender}
            error={errors?.gender}
          />

          {/* Render custom input and pronoun dropdown if "Custom" is selected */}
          {isCustomGender && (
            <>
              {/* Custom Gender Input */}
              <CustomInputField
                id={'customGender'}
                labelText={"What's your gender?"}
                maxLength={gender}
                type={'text'}
                placeholder={'Enter your gender'}
                value={customGenderInput}
                onChange={handleCustomGenderInput}
              />

              {/* Pronoun Selection Dropdown */}
              <Dropdown
                label="Please refer me as"
                placeHolder="Select a pronoun"
                selectedValue={formData.genderRefer}
                options={ReferOptions}
                onSelect={handlePronounSelection}
                error={errors?.genderRefer}
              />
            </>
          )}

          <div className="flex flex-col gap-2 relative" ref={calendarRef}>
            <label htmlFor="dob" className="text-Neutral-500">
              Date of Birth
            </label>

            <div className="flex flex-col items-start w-full">
              <div
                className={`flex items-center h-12 bg-white w-full px-4 py-4.5 rounded-xl  ${errors?.dob ? 'border-2 border-Error-500' : ' border border-Neutral-100'}`}>
                <input
                  type="text"
                  id="dob"
                  placeholder="DD-MM-YYYY"
                  value={dobInput}
                  onChange={handleDateInputChange}
                  className="fl  w-full bg-white focus:bg-white  outline-none  text-Neutral-900"
                />
                <IoMdCalendar
                  className="h-6 w-6 ml-2 cursor-pointer"
                  onClick={() => setCalendarOpen(!calendarOpen)}
                />
              </div>
              {errors?.dob && <p className="text-Error-500 text-sm">{errors?.dob}</p>}
            </div>

            {calendarOpen && (
              <div className="absolute bottom-[10px] right-0 z-10 mt-2">
                <Calendar
                  currentDate={dateObject} // Pass the native Date object to the Calendar
                  setCurrentDate={handleDateSelection}
                />
              </div>
            )}
          </div>
          <CustomInputField
            id={'postalCode'}
            labelText={'Postal code'}
            maxLength={postalCode}
            type={'number'}
            placeholder={'Enter your postal code'}
            value={formData.postalCode}
            onChange={handleInputChange('postalCode', 'Postal code')}
            error={errors?.postalCode}
          />
        </div>
        <div
          className={`flex flex-col gap-4 justify-between items-center md:absolute lg:flex-row lg:gap-0 lg:relative ${isCustomGender ? `` : `desktop:absolute`} md:bottom-[10%] lg:bottom-[8%] w-[480px] lg:mb-10 desktop:mb-0`}>
          <div className="lg:hidden">
            <StepIndicator currentStep={selectedTab} totalSteps={totalSteps} />
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              className="flex justify-center items-center rounded-3xl bg-white w-25 h-12 font-bold text-base text-Neutral-900"
              onClick={() => setSelectedTab((prev) => prev - 1)}>
              Back
            </button>
            <button
              className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
              onClick={handleSubmit}>
              {loading ? <TbLoader3 className="animate-spin text-black" /> : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
