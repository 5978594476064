import React from 'react';

function Checkout({ formData, products, selectedPlan, setSelectedTab }) {
  return (
    <div className="flex flex-col items-center gap-10 xxl:gap-12 w-full h-full max-w-150">
    
      <div className="relative">
        <div className="w-[32.5rem] h-80 rounded-3xl bg-white relative overflow-hidden z-10">
          <img src="/Group 4.svg" alt="group 4" className="absolute top-8 left-12 z-10" />
          <div className="z-10 flex flex-col absolute bottom-10 left-12 text-sm gap-3">
            <div className="flex flex-col items-start z-10 gap-[2px]">
              <p className='text-Neutral-500 text-sm'>MDN</p>
              <p className="font-medium text-Neutral-900 text-sm">0987654321</p>
            </div>
            <div className="flex flex-col items-start z-10 gap-[2px]">
              <p className='text-Neutral-500 text-sm'>BAN</p>
              <p className="font-medium text-Neutral-900 text-sm">0987654321</p>
            </div>
            <div className="flex flex-col items-start z-10 gap-[2px]">
              <p className='text-Neutral-500 text-sm'>ICCID</p>
              <p className="font-medium text-Neutral-900 text-sm">3584739847398473944</p>
            </div>
          </div>
          <img
            src="/Vector1.svg"
            alt="vector1"
            className="absolute right-0 top-1/2 -translate-y-1/2"
          />
          <img
            src="/Vector2.svg"
            alt="vector2"
            className="absolute right-7 top-1/2 -translate-y-1/2"
          />
          <img
            src="/Vector3.svg"
            alt="vector2"
            className="absolute right-20 top-1/2 -translate-y-1/2"
          />
          <img
            src="/Group 13.svg"
            alt="vector2"
            className="absolute right-14 top-1/2 -translate-y-1/2 z-10"
          />
          <div className="w-[32rem] h-[30.68rem] rounded-full  translate-y-[-50%]">
            <div className="w-full h-full rounded-full bg-gradient-to-r from-[#B5FCAE] to-[#79C9FF] blur-[100px]"></div>
          </div>
        </div>
        <div className="w-[36.80rem] h-[13.6rem] rounded-[36.80rem] translate-y-[-50%]  absolute -left-7">
          <div class="w-full h-full rounded-[36.80rem] bg-gradient-to-r from-[#B5FCAE] to-[#79C9FF] blur-[100px]"></div>
        </div>
      </div>
      <div className=" z-10 flex gap-11 mx-auto">
        <div className='flex flex-col gap-[2px]'>
        <p className='text-Neutral-500 text-sm'>IMEI</p>
        <p className="font-medium text-Neutral-900 text-sm">358473984739847394</p>
        </div>
        <div className='flex flex-col gap-[2px]'>
        <p className='text-Neutral-500 text-sm'> PLAN</p>
        <p className="font-medium text-Neutral-900 text-sm">
            {products[selectedPlan]?.displayName}( {products[selectedPlan]?.size} )
          </p>
        </div>
        </div>
    </div>
  );
}

export default Checkout;
