export const httpConstants = {
  METHOD_TYPE: {
    POST: 'POST',
    PUT: 'PUT',
    GET: 'GET',
    DELETE: 'DELETE',
  },

  CONTENT_TYPE: {
    APPLICATION_JSON: 'application/json',
    MULTIPART_FORM_DATA: 'multipart/form-data',
    APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
    IMAGE_PNG: 'image/png',
  },
  DEVICE_TYPE: {
    WEB: 'web',
  },
};

export const regexConstant = {
  DIGIT_REGEX: new RegExp(/^\d+$/),
  EMAIL_REGEX: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  PASSWORD_REGEX: new RegExp(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,}$/
  ),
};

export const formattedDateTypes = {
  NUMERIC: 'numeric',
  SHORT: 'short',
  DIGIT_TYPE: '2-digit',
};

export const keyConstants = {
  USER: 'user',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  LOGIN_THROUGH: 'loginThrough',
  FIRST_TIME_LOGGED_IN: 'firstTimeLoggedIn',
};

export const apiEndPointConstants = {
  LOGIN: '/login',
  ROLES: '/roles',
  ADMINS: '/admins',
  USERS: '/users',
  USERS_EMAIL: '/users/email',
  ORDERS: '/orders',
  CREATE_CHECKOUT_SESSION: '/create-checkout-session',
  WALLET: 'wallet',
  PRODUCTS: '/products',
  REGISTER_SUBSCRIBER: '/register',
  AVAILABLE_PLANS: '/plans/available-plans',
  PLANS: '/plans',
  SUBSCRIBERS: '/subscribers',
  ACCOUNTS: '/accounts',
  START_SUBSCRIBER_ACTIVATION: '/start-plan-activation',
  SUBSCRIBER_PLAN_ACTIVATION: '/plan-activation',
  CRM_PRODUCTS: '/crm-products',
  PORT_IN_CHECK: '/portin/check',
  PORT_IN_MAKE_START: '/portin/make/start',
  PORT_IN_MAKE: '/portin/make',
  WEEKLY_ACTIVE_SUBSCRIBERS: '/weekly-active-subscribers',
  WEEKLY_SUBSCRIBERS_SUMMARY: '/weekly-subscribers-summary',
  WEEKLY_ORDERS_SUMMARY: '/weekly-orders-summary',
  TRANSACTIONS: '/transactions',
  DISTRIBUTORS: '/distributors',
  SUMMARY: '/summary',
  AUTHORIZED_DISTRIBUTORS: '/authorized-distributors',
  CHANGE_PASSWORD: '/change-password',
  AD: '/ad',
  CREATE_ACCOUNT: '/user/connect',
  CHECK_STRIPE_STATUS: '/account-status/',
  GET_ONBOARD: '/account-link/',
  TRANSFER_AMOUNT: '/transfer-amount',
  INVITATION: '/invitation',
  ADMIN_DASHBOARD_DATA: '/admin/dashboard',
  AUTHORIZED_DASHBOARD_DATA: '/ad/dashboard',
};

export const sideBarPaths = [
  {
    name: 'Products',
    pathname: '/dashboard/products',
    selectedPathNames: ['/dashboard/products', '/dashboard'],
  },
  {
    name: 'Users',
    pathname: '/dashboard/users',
    selectedPathNames: ['/dashboard/users'],
  },
  {
    name: 'Subscriber Management',
    pathname: '/dashboard/subscribers',
    selectedPathNames: ['/dashboard/subscribers'],
  },
  {
    name: 'Transactions',
    pathname: '/dashboard/transactions',
    selectedPathNames: ['/dashboard/transactions'],
  },
];

export const failureMessageConstant = {
  PASSWORD_REQUIRED: 'Password is required',
  INVALID_PASSWORD:
    'Password must be at least 8 characters including letters, numbers and special characters',
  EMAIL_REQUIRED: 'Email is required',
  INVALID_EMAIL: 'Please enter valid email',
  AMOUNT_REQUIRED: 'Amount is required',
  INVALID_AMOUNT: 'Invalid amount',
  PRODUCT_NAME_REQUIRED: 'Product name is required',
  TAX_REQUIRED: 'Tax is required',
  DESCRIPTION_REQUIRED: 'Description is required',
  PLEASE_ENTER_FIRST_NAME: 'Please enter first name',
  SESSION_EXPIRED_LOGIN_AGAIN: 'Session expired, Please login again',
};

export const formValidationMessages = {
  PLEASE_ENTER_FIRST_NAME: 'Please enter first name',
  PLEASE_ENTER_LAST_NAME: 'Please enter last name',
  PLEASE_ENTER_STREET_NUMBER: 'Please enter street number',
  PLEASE_ENTER_STREET_DIRECTION: 'Please enter street direction',
  PLEASE_ENTER_STREET_NAME: 'Please enter street name',
  PLEASE_ENTER_CITY: 'Please enter city',
  PLEASE_ENTER_STATE: 'Please enter state',
  PLEASE_ENTER_ZIP_CODE: 'Please enter zip code',
  PLEASE_ENTER_EMAIL: 'Please enter email',
  PLEASE_ENTER_CONTACT_NUMBER: 'Please enter contact number',
  PLEASE_ENTER_PORT_IN_NUMBER: 'Please enter port in number',
};

export const usersData = [
  { id: 1, name: 'John Doe', position: 'Manager', department: 'Sales' },
  {
    id: 2,
    name: 'Jane Smith',
    position: 'Developer',
    department: 'Engineering',
  },
  { id: 3, name: 'Mike Johnson', position: 'Analyst', department: 'Finance' },
];

export const AddADFormData = {
  firstName: '',
  lastName: '',
  fatherFirstName: '',
  fatherLastName: '',
  gender: 'Male',
  dob: '',
  identityNo: '',
  proofOfIdentity: { name: '', size: '', documentLink: '', type: '' },
  nationality: '',
  postalCode: '',
  country: '',
  email: '',
  proofOfAddress: { name: '', size: '', documentLink: '', type: '' },
  bankName: '',
  paymentMethods: 'Bank',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  accountHolderName: '',
  signatureFirstName: '',
  signatureLastName: '',
  signature: '',
  dateSigned: '',
  isTermsAccepted: false,
};

export const OnboardingInputMaxLength = {
  personalDetails: {
    name: 30,
    dob: 10,
    gender: 10,
    postalCode: 10,
  },
  bankDetails: {
    accountNumber: 17,
    routingNumber: 9,
    accountHolderName: 30,
  },
  wireTransferDetails: {
    wireTransferRecipientName: 30,
    wireTransferBankName: 35,
    wireTransferAccountNumber: 17,
    wireTransferRoutingNumber: 9,
    wireTransferBankAddress: 100,
    iban: 34,
    bicCode: 11,
  },
  zelleDetails: {
    email: 254,
    phone: 15,
  },
  declarationDetails: {
    signatureName: 30,
  },
};

export const tabImages = [
  { icon: '/formdashboard-sidebar-image.png', alt: 'Form dashboard Banner' },
  { icon: '/personalDetails-sidebar-image.png', alt: 'Persoal details Banner' },
  { icon: '/proofOfIdentity-sidebar-image.png', alt: 'Proof of Identity Banner' },
  { icon: '/proofOfAddress-sidebar-image.png', alt: 'Proof of Address Banner' },
  { icon: '/paymentMethod-sidebar-image.png', alt: 'Payment Details Banner' },
  { icon: '/declaration-sidebar-image.png', alt: 'Declaration Bannerr' },
];

export const sidebarTabs = [
  'Personal details',
  'Proof of identity',
  'Proof of address',
  'Payment details',
  'Declaration',
];
export const NewAddADFormData = {
  name: '',
  gender: 'Male',
  dob: '',
  postalCode: '',
  identities: [],
  proofOfAddress: [],
  bankName: '',
  paymentMethods: 'Bank',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  accountHolderName: '',
  signatureName: '',
  signature: '',
  isTermsAccepted: false,
};

export const GenderOptions = ['Male', 'Female', 'Rather not say', 'Custom'];

export const ReferOptions = ['He', 'She', 'Other'];

export const AddressOptions = ['NID Card', 'Passport', 'Residencial proof'];

export const IdentityOptions = ['NID Card', 'Passport', 'Driving License'];

export const AccountTypeOptions = ['Personal', 'Business', 'Other'];

export const FormTabs = [
  {
    text: 'Personal details',
    icon: '/account-circle.svg',
  },
  {
    text: 'Identity details',
    icon: '/proof-of-identity-icon.svg',
  },
  {
    text: 'Proof of address',
    icon: '/location_on.svg',
  },
  {
    text: 'Payment details',
    icon: '/account_balance.svg',
  },
  {
    text: 'Declaration',
    icon: '/signature.svg',
  },
];

export const withdrawalNewRequestsData = [
  { user: 'example@gmail.com', type: 'AD', paymentMethod: 'Bank', withdrawalAmount: '450' },
  { user: 'example@gmail.com', type: 'AD', paymentMethod: 'Bank', withdrawalAmount: '450' },
  { user: 'example@gmail.com', type: 'AD', paymentMethod: 'Bank', withdrawalAmount: '450' },
  { user: 'example@gmail.com', type: 'AD', paymentMethod: 'Bank', withdrawalAmount: '450' },
];

export const withdrawalHistoryData = [
  {
    user: 'example@gmail.com',
    type: 'AD',
    paymentMethod: 'Bank',
    withdrawalAmount: '450',
    status: 'Approved & paid',
  },
  {
    user: 'example@gmail.com',
    type: 'AD',
    paymentMethod: 'Bank',
    withdrawalAmount: '450',
    status: 'Rejected',
  },
  {
    user: 'example@gmail.com',
    type: 'AD',
    paymentMethod: 'Bank',
    withdrawalAmount: '450',
    status: 'Approved & paid',
  },
  {
    user: 'example@gmail.com',
    type: 'AD',
    paymentMethod: 'Bank',
    withdrawalAmount: '450',
    status: 'Approved & paid',
  },
];

export const withdrawalMoneyFormData = {
  withdrawalMethod: '',
  bankDetails: {
    accountNumber: '12345 567 6756',
    routingNumber: '123435(ABC Bank)',
    accountType: 'Personal',
    accountHolderName: 'Al Mamun',
  },
  zelleDetails: { email: '', phoneNumber: '' },
  wireTransferDetails: { bankName: '', swiftCode: '', accountNumber: '', accountHolderName: '' },
  withdrawalAmount: '',
};

export const streetDirectionOption = [
  'North',
  'East',
  'South',
  'West',
  'North-East',
  'North-West',
  'South-East',
  'South-West',
];

export const familyOption = ['A', 'B'];

export const statusClasses = {
  active: {
    bg: 'bg-Success-100',
    dot: 'bg-Success-500',
    text: 'text-Success-500',
  },
  inactive: {
    bg: 'bg-Neutral-100',
    dot: 'bg-Neutral-400',
    text: 'text-Neutral-400',
  },
  pending: {
    bg: 'bg-Warning-50',
    dot: 'bg-Warning-500',
    text: 'text-Warning-500',
  },
  'Invitation Sent': {
    bg: 'bg-Warning-50',
    dot: 'bg-Warning-500',
    text: 'text-Warning-500',
  },
};

export const earningsData = {
  ad: [
    { amount: '103', description: 'Sim card', percentage: '2.0', type: 'increase' },
    { amount: '152', description: 'Activation', percentage: '2.0', type: 'decrease' },
    { amount: '68', description: 'Payment Fee', percentage: '0.6', type: 'decrease' },
    { amount: '496.5', description: 'Monthly Subs', percentage: '1.0', type: 'increase' },
  ],
  md: [
    { amount: '65', description: 'Earned today', percentage: '2.0', type: 'increase' },
    { amount: '103', description: 'New Activation', percentage: '2.0', type: 'increase' },
    { amount: '105', description: 'Existing subscription', percentage: '1.7', type: 'decrease' },
  ],
};

//dashboard charts colours
export const greenChart = {
  color: '#19C84D',
  bg: '#C9F8D7',
  gridLineColour: '#3AE76E',
  gradientStart: 'rgba(25, 200, 77, 0.05)',
  gradientEnd: 'rgba(25, 200, 77, 0.30)',
};
export const blueChart = {
  color: '#009DFF',
  bg: '#D6EFFF',
  gridLineColour: '#85D0FF',
  gradientStart: 'rgba(0, 157, 255, 0.05)',
  gradientEnd: 'rgba(13, 162, 255, 0.3)',
};
export const purpleChart = {
  color: '#6F47FF',
  bg: '#D7CCFF',
  gridLineColour: '#AF99FF',
  gradientStart: 'rgba(110, 75, 255, 0.05)',
  gradientEnd: 'rgba(110, 75, 255, 0.3)',
};
export const whiteChart = {
  color: '#585B5F',
  bg: '#FFF',
  gridLineColour: '#DFE0E2',
  gradientStart: 'rgba(88, 91, 95, 0.05) ',
  gradientEnd: 'rgba(88, 91, 95, 0.3)',
};
