import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import PopupComponent from '../common/popup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import WalletService from '../services/wallet';

function ConfirmWithdraw({ formData, setFormData, walletDetails, setSelectedTab }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state) => state.user);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleWithdrawRequest = async () => {
    if (!formData.withdrawalAmount) {
      console.log('amount is required');
      return;
    }
    try {
      const payload = {
        userId: user._id,
        amount: formData?.withdrawalAmount,
        paymentMethod: 'Bank',
        details: 'Withdrawal request',
      };

      const response = await new WalletService().withdrawMoney(payload);
      setSelectedTab((prev) => prev + 1);
    } catch (error) {
      toast.error(error?.message, 4000);
    }
  };
  return (
    <>
      <div className="w-full flex flex-col gap-10 h-full">
        <FormSectionTitle
          heading={'Review withdraw and request'}
          subHeading={'Check information before hit the withdraw button.'}
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <p className="text-Neutral-500">Withdraw amount</p>
            <p className="text-Neutral-900 font-bold">{formData.withdrawalAmount}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-Neutral-500">Withdraw to</p>
            <p className="text-Neutral-900 font-bold">{formData.withdrawalMethod}</p>
          </div>
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex whitespace-nowrap justify-center items-center rounded-3xl bg-Primary-400  py-3 px-8 font-bold text-base text-white"
            onClick={() => handleOpenModal()}>
            Confirm withdraw
          </button>
        </div>
      </div>
      {isModalOpen && (
        <PopupComponent>
          <img src="dollar-success-icon.svg" alt="withdraw-Icon" className="w-16 h-16" />

          <p className="text-Neutral-900 font-semibold text-xl text-center">
            Confirm withdraw request?
          </p>

          <div className="flex items-center justify-between gap-2 w-full">
            <button
              onClick={() => handleCloseModal()}
              className="py-3 basis-1/2 px-6 text-center bg-Primary-100 rounded-3xl text-black text-base font-bold">
              No
            </button>
            <button
              onClick={()=>handleWithdrawRequest()}
              className="py-3 basis-1/2 px-6 text-center whitespace-nowrap bg-Primary-500 rounded-3xl text-white text-base font-bold">
              Confirm
            </button>
          </div>
        </PopupComponent>
      )}
    </>
  );
}

export default ConfirmWithdraw;
