import React, { useState, useEffect, useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { updateUser } from '../slices/userSlice';

const Header = ({
  dropdownQuery,
  setDropdownQuery,
  searchInput,
  setSearchInput,
  fetchLatestSubscribersData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const profileContainerRef = useRef(null);
  const searchBtnRef = useRef(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSearchBtn, setShowSearchBtn] = useState(false);
  const user = useSelector((state) => state.user);
  const roleName = user?.roleId?.name;

  useEffect(() => {
    let handler = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
      // Profile dropdown outside click
      if (profileContainerRef.current && !profileContainerRef.current.contains(e.target)) {
        setProfileDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleDropdownQuery = (selectedItem) => {
    setDropdownQuery(selectedItem);
    setDropdownOpen(false);
  };
  const handleLogout = () => {
    dispatch(updateUser({ ...user, isLoggedIn: false }));
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('user');
    if (user?.roleId?.name === 'Admin') {
      navigate('/admin');
    } else {
      navigate('/');
    }
  };

  return (
    <div className="w-full py-6 bg-[#E9F9FF] z-50">
      <div className="  w-full p-2 bg-white rounded-[50px] flex items-center gap-1 z-50">
        <div
          className={`mr-auto py-2 pl-4 pr-2 bg-Primary-50 rounded-[20px] relative flex items-center gap-1 z-30 cursor-pointer ${dropdownOpen ? 'border-1' : ''}`}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          ref={dropdownRef}>
          <p className="text-Neutral-900 text-base">{dropdownQuery}</p>
          <div className="h-6 w-6">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all duration-200 ${dropdownOpen ? 'rotate-180' : ''}`}
            />
          </div>
          {dropdownOpen && (
            <div className="absolute top-15 left-0 p-1 w-full bg-[#FFFFFF] rounded-[20px] overflow-hidden z-20 flex flex-col items-center shadow-2xl">
              <p
                className={`w-full py-2 text-center cursor-pointer rounded-[20px] hover:bg-[#E7F6FC] ${dropdownQuery === 'MDN' ? 'bg-[#FFFFFF]' : ''}`}
                onClick={() => handleDropdownQuery('MDN')}>
                MDN
              </p>
              <p
                className={`w-full py-2 text-center cursor-pointer rounded-[20px] hover:bg-[#E7F6FC] ${dropdownQuery === 'Name' ? 'bg-[#FFFFFF]' : ''}`}
                onClick={() => handleDropdownQuery('Name')}>
                Name
              </p>
              <p
                className={`w-full py-2 text-center cursor-pointer rounded-[20px] hover:bg-[#E7F6FC] ${dropdownQuery === 'Email' ? 'bg-[#FFFFFF]' : ''}`}
                onClick={() => handleDropdownQuery('Email')}>
                Email
              </p>
              <p
                className={`w-full py-2 text-center cursor-pointer rounded-[20px] hover:bg-[#E7F6FC] ${dropdownQuery === 'Status' ? 'bg-[#FFFFFF]' : ''}`}
                onClick={() => handleDropdownQuery('Status')}>
                Status
              </p>
            </div>
          )}
        </div>

        <div className="relative p-2 bg-white w-full" ref={searchBtnRef}>
          <img
            src="./search.svg"
            alt="Search-Icon"
            className="absolute left-2 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400"
          />

          <input
            type="text"
            placeholder="Search for a subscriber"
            className="w-full pl-10 placeholder:text-[#A0A2A7] outline-none"
            value={searchInput}
            onFocus={() => setShowSearchBtn(true)}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && fetchLatestSubscribersData()}
          />
        </div>

        <img
          src="./notifications-icon.svg"
          alt="Notifications-Icon"
          className="h-6 w-6 cursor-pointer hover:bg-opacity-80"
        />
        <div className="relative" ref={profileContainerRef}>
          {user?.image ? (
            <img
              src={user?.image}
              alt={user?.name}
              className="w-10 h-10  object-cover cursor-pointer"
            />
          ) : (
            <div
              className={`w-10 h-10 cursor-pointer ${roleName === 'Admin' ? 'bg-Error-500' : roleName === 'Master Distributor' ? 'bg-purple-500' : 'bg-Primary-500'}  rounded-full flex items-center justify-center flex-shrink-0  text-white text-xl font-extrabold`}
              onClick={() => setProfileDropdown(!profileDropdown)}>
              {user?.name
                ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                    .split(' ')
                    .map((word) => word[0])
                    .join('')
                    .substring(1, 2)
                    .toUpperCase()}`
                : ''}
            </div>
          )}
          {profileDropdown && (
            <div className="p-4 rounded-2xl absolute top-15 right-0 min-w-56 items-start bg-white flex flex-col shadow-2xl  gap-4">
              <div className="flex gap-3 items-center">
                {user?.image ? (
                  <img
                    src={user?.image}
                    alt={user?.name}
                    className="w-10 h-10  object-cover cursor-pointer"
                  />
                ) : (
                  <div
                    className={`w-10 h-10 ${roleName === 'Admin' ? 'bg-Error-500' : roleName === 'Master Distributor' ? 'bg-purple-500' : 'bg-Primary-500'}  rounded-full flex items-center flex-shrink-0 justify-center text-white text-xl font-extrabold`}
                    onClick={() => setProfileDropdown(!profileDropdown)}>
                    {user?.name
                      ? `${user?.name.substring(0, 1).toUpperCase()}${user?.name
                          .split(' ')
                          .map((word) => word[0])
                          .join('')
                          .substring(1, 2)
                          .toUpperCase()}`
                      : ''}
                  </div>
                )}
                <div className="flex flex-col gap-1 items-start">
                  <div className="text-base text-Neutral-900">{user?.name || user?.email}</div>
                  <div
                    className={`${roleName === 'Admin' ? 'bg-Error-50 text-Error-500' : roleName === 'Master Distributor' ? 'bg-purple-50 text-purple-500' : 'text-Primary-500  bg-Primary-100'}  px-2 py-1 rounded-2xl whitespace-nowrap `}>
                    {roleName}
                  </div>
                </div>
              </div>
              <Link
                to={'/profile'}
                className="flex items-center justify-start py-2 gap-2 cursor-pointer">
                <img src="/dropdown-profile-icon.svg" alt="profile icon" className="w-6 h-6" />
                <p className="text-Neutral-500 font-bold text-base">View profile</p>
              </Link>
              <div
                className="flex items-center justify-start py-2 gap-2 cursor-pointer"
                onClick={handleLogout}>
                <img src="/dropdown-logout-icon.svg" alt="logout icon" className="w-6 h-6" />
                <p className="text-Neutral-500 font-bold text-base">Logout</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
