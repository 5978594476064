import React, { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { TbLoader3 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from '../utils/dateUtils';
import { useDispatch } from 'react-redux';
import { updateSelectedDistributor } from '../slices/userSlice';
import { BiExport } from 'react-icons/bi';

// import UserService from "../services/user";

const ADTableComp = ({
  distributors,
  user,
  totalDistributors,
  pagination,
  setPagination,
  loading,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  // const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedDistributors = Array.isArray(distributors)
    ? [...distributors].sort((a, b) => {
        if (!sortConfig.key) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      })
    : [];

  const filteredDistributors =
    Array.isArray(distributors) && distributors.length
      ? sortedDistributors.filter(
          (distributor) =>
            distributor.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            distributor.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            distributor.status?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      : [];

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalDistributors) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const exportToCSV = () => {
    const headers = ['Name', 'Email', 'Status', 'Active Subscribers', 'Commissions'];
    const rows = filteredDistributors.map((dist) => [
      dist.name,
      dist.email,
      dist.status,
      dist.activeSubscribers || 0,
      dist.myCommissions || 0.0,
    ]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'distributors.csv');
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  const handleRowClick = (distributor) => {
    dispatch(updateSelectedDistributor(distributor));
    navigate('/view-details');
  };

  const statusClasses = {
    active: {
      bg: 'bg-Success-100',
      dot: 'bg-Success-500',
      text: 'text-Success-500',
    },
    inactive: {
      bg: 'bg-Neutral-100',
      dot: 'bg-Neutral-400',
      text: 'text-Neutral-400',
    },
    pending: {
      bg: 'bg-Warning-50',
      dot: 'bg-Warning-500',
      text: 'text-Warning-500',
    },

    'Invitation Sent': {
      bg: 'bg-Neutral-100',
      dot: 'bg-Neutral-400',
      text: 'text-Neutral-400',
    },
    rejected: {
      bg: 'bg-Error-50',
      dot: 'bg-Error-500',
      text: 'text-Error-500',
    },
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className=" w-full pt-8 pb-4 flex justify-between items-center">
        <h2 className="text-2xl font-extrabold text-black">AD list</h2>

        <button className="pl-4 py-3 pr-3 bg-Primary-100 rounded-3xl flex justify-center items-center gap-3 cursor-pointer hover:bg-opacity-90">
          <p className="text-base text-Primary-500 font-semibold">Export</p>
          <img src="./chevron-down-blue.svg" alt="Chevron-Down Icon" className="h-4 w-4" />
        </button>
      </div>
      <div className="flex flex-col bg-white rounded-2xl px-2">
          <table className="w-full table-fixed border-collapse">
            <thead className="">
              <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('name')}>
                  AD Name
                </th>
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('email')}>
                  Email
                </th>
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('activeSubscribers')}>
                  Subscribers
                </th>
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('myCommissions')}>
                  Commissions
                </th>
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('addedon')}>
                  Added on
                </th>
                <th
                  className="text-left text-xs  p-4 font-medium cursor-pointer w-1/6"
                  onClick={() => requestSort('status')}>
                  Status
                </th>
              </tr>
            </thead>
            {loading ? (
              <div className="flex justify-center items-center h-80">
                <TbLoader3 className="animate-spin text-black h-13 w-13 absolute left-45per top-45per" />
              </div>
            ) : (
              <tbody>
                {filteredDistributors.length > 0 ? (
                  filteredDistributors.map((distributor, index) => (
                    <tr
                      key={index}
                      className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50"
                      onClick={() => handleRowClick(distributor)}>
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {distributor?.name || 'N/A'}
                      </td>
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {distributor.email}
                      </td>

                      <td className="p-4 text-Neutral-700 text-base">
                        {distributor.totalSubscribers || '_'}
                      </td>
                      <td className="p-4 text-Neutral-700 text-base">
                        ${distributor.totalCommissions || '_'}
                      </td>
                      <td className="p-4 text-Neutral-700 text-base">
                        {formatTimestamp(distributor.addedOn)}
                      </td>
                      <td className="p-4">
                        <div
                          className={`inline-flex items-center gap-1 p-2 rounded-5-5xl ${statusClasses[distributor?.status]?.bg || ''}`}>
                          <div
                            className={`w-2 h-2 rounded-full ${statusClasses[distributor?.status]?.dot || ''}`}></div>
                          <div
                            className={`font-semibold text-base capitalize whitespace-nowrap ${statusClasses[distributor?.status]?.text || ''}`}>
                            {distributor?.status}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center py-3">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        <div className="flex justify-end items-center mt-4">
          <div className="flex items-center gap-10">
            <div className="flex items-center">
              <span>Rows per page:</span>
              <div
                className="relative"
                onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                ref={paginationRef}>
                <img
                  src="./chevron_backward.svg"
                  alt="Chevron-Down Icon"
                  className={`absolute right-[15px] top-[17px] transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                  style={{ transitionDuration: '100ms' }}
                />
                <select
                  value={pagination.rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="ml-2 py-2 pl-[16px] w-[68px] h-[40px] outline-none border-none rounded-[24px] appearance-none">
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <span>
              {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
              {pagination.currentPage * pagination.rowsPerPage > totalDistributors
                ? totalDistributors
                : pagination.currentPage * pagination.rowsPerPage}{' '}
              of {totalDistributors}
            </span>
            <div>
              <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                &lt;
              </button>
              <button className="p-2 rounded-lg" onClick={handleNextPage}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ADTableComp;
