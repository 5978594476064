import React from 'react';
import { SiZelle } from 'react-icons/si';
import { NavLink, useNavigate } from 'react-router-dom';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import { FaCheck } from 'react-icons/fa6';

const WithdrawalMethod = ({ formData, setFormData, setSelectedTab}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col gap-10 h-full">
      <FormSectionTitle
        heading={'Select method'}
        subHeading={'Select your preferred payment method'}
      />
      <div className="flex flex-col gap-4">
        <div
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Bank' })}
          className={` rounded-2xl flex items-center gap-3 p-4 ${formData.withdrawalMethod === 'Bank' ? 'bg-white' : ''}`}>
          <img src="Bank-dark-icon.svg" alt="bank icon" />
          <div className="flex flex-col gap-1 grow">
            <p className="text-Neutral-900 font-bold">Bank</p>
            <p className="text-Neutral-500">7829 *** 1647</p>
          </div>
          <div>
            {formData.withdrawalMethod === 'Bank' ? (
             <div
             className={`h-6 w-6 border-2 box-border rounded-full text-sm font-bold flex justify-center items-center 
               border-green-600 text-white bg-green-600
           `}>
             <FaCheck />
           </div>
            ) : (
              <div className="cursor-pointer flex items-center justify-center px-4 py-2 rounded-3xl bg-Primary-100">
                <p className="text-Primary-700 font-bold"> Connect</p>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Zelle' })}
          className={` rounded-2xl flex items-center gap-3 p-4 ${formData.withdrawalMethod === 'Zelle' ? 'bg-white' : ''}`}>
          <img src="zelle-dark-icon.svg" alt="zelle icon" />
          <div className="flex flex-col gap-1 grow">
            <p className="text-Neutral-900 font-bold">Zelle</p>
            <p className="text-Neutral-500">7829 *** 1647</p>
          </div>
          <div>
            {formData.withdrawalMethod === 'Zelle' ? (
               <div
               className={`h-6 w-6 border-2 box-border rounded-full text-sm font-bold flex justify-center items-center 
                 border-green-600 text-white bg-green-600
             `}>
               <FaCheck />
             </div>
            ) : (
              <div className="cursor-pointer flex items-center justify-center px-4 py-2 rounded-3xl bg-Primary-100">
                <p className="text-Primary-700 font-bold"> Connect</p>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => setFormData({ ...formData, withdrawalMethod: 'Wire Transfer' })}
          className={` rounded-2xl flex items-center gap-3 p-4 ${formData.withdrawalMethod === 'Wire Transfer' ? 'bg-white' : ''}`}>
          <img src="wire-transfer-dark-icon.svg" alt="wire icon" />
          <div className="flex flex-col gap-1 grow">
            <p className="text-Neutral-900 font-bold">Wire</p>
            <p className="text-Neutral-500">7829 *** 1647</p>
          </div>
          <div>
            {formData.withdrawalMethod === 'Wire Transfer' ? (
              <div
                className={`h-6 w-6 border-2 box-border rounded-full text-sm font-bold flex justify-center items-center 
                  border-green-600 text-white bg-green-600
              `}>
                <FaCheck />
              </div>
            ) : (
              <div className="cursor-pointer flex items-center justify-center px-4 py-2 rounded-3xl bg-Primary-100">
                <p className="text-Primary-700 font-bold"> Connect</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center   mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
          onClick={ ()=> navigate(-1)}>
          Back
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={() => setSelectedTab((prev) => prev + 1)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default WithdrawalMethod;
