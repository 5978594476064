import React from 'react';

function CustomInputField({ id, labelText, maxLength, type, placeholder, value, onChange, error }) {
  const handleOnChange = (e) => {
    const inputValue = e.target.value;
      // Validate number input: allow only positive numbers
      if (type === 'number') {
        const regex = /^[0-9]*$/;
           // If input value doesn't match the number pattern, return (disallow invalid input)
           if (!regex.test(inputValue)) {
            return; // Ignore invalid input (non-number characters)
          }
    
          // Allow only positive numbers
          if (Number(inputValue) < 0) {
            return; // Ignore negative numbers
          }
    
          // Enforce maxLength for numbers
          if (maxLength && inputValue.length > maxLength) {
            return; // Ignore input that exceeds the maxLength
          }
        }

    // Validate text input: allow only letters
    if (type === 'text') {
      const regex = /^[a-zA-Z\s]*$/; // Only allows letters
      if (!regex.test(inputValue)) {
        console.log("inside regex text");
        
        return; // Ignore invalid input (non-letter characters)
      }
      // Enforce maxLength for text
      if (maxLength && inputValue.length > maxLength) {
        console.log("inside max length input length");
        
        return; // Ignore input that exceeds the maxLength
      }
    }
    

    onChange(e); // Call the onChange function passed as prop
  };
  return (
    <div className="flex flex-col items-start gap-1 xxl:gap-2">
      <label htmlFor={id} className="text-Neutral-500">
        {labelText}
      </label>
      <div className="flex flex-col items-start w-full">
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border ${error ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
        />
        {error && <p className="text-Error-500 text-sm first-letter:capitalize">{error}</p>}
      </div>
    </div>
  );
}

export default CustomInputField;
