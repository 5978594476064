import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TbLoader3 } from 'react-icons/tb';
import Utils from '../utility';
import { NavLink, useNavigate } from 'react-router-dom';
import UserService from '../services/user';
import { removeUser } from '../slices/userSlice';
import WalletService from '../services/wallet';
import WithdrawRequestModal from './withdraw-request-modal';

const EarningList = ({
  loading,
  transactions,
  WithdrawRequests,
  sortConfig,
  setSortConfig,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalTransactions,
  totalRequestedAmount,
  totalPaidAmount,
  exportAdminTransactionsToCSV,
  exportToCSV,
  handleRequest,
}) => {
  const user = useSelector((state) => state.user);
  console.log('WithdrawRequests', WithdrawRequests);

  const [error, setError] = useState(null);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [selectedNewRequest, setSelectedNewRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const paginationRef = useRef(null);
  const [walletDetails, setWalletDetails] = useState([]);

  useEffect(() => {
    async function fetchWalletDetails() {
      try {
        // CHECKING IF TOKEN IS EXPIRE OR NOT
        if (Utils.checkTokenExpireOrNot()) {
          dispatch(removeUser({}));
          return;
        }
        const walletDetails = await new WalletService().getUserWallet(user?._id);
        console.log('walletDetails', walletDetails);

        setWalletDetails(walletDetails);
      } catch (error) {
        console.error(error);
      }
    }
    fetchWalletDetails();
  }, [dispatch, user?._id]);

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < totalTransactions) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const statusClasses = {
    rejected: {
      bg: 'bg-Error-50',
      dot: 'bg-Error-500',
      text: 'text-Error-500',
    },
    approved: {
      bg: 'bg-Success-100',
      dot: 'bg-Success-500',
      text: 'text-Success-500',
    },
    pending: {
      bg: 'bg-Neutral-50',
      dot: 'bg-Neutral-500',
      text: 'text-Neutral-400',
    },
  };

  const OpenWithdrawModal = (data) => {
    setSelectedNewRequest(data);
    setOpenWithdrawModal(true);
  };

  const ADCard = () => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-1/2 bg-white p-6 rounded-2xl flex items-center gap-4">
            <div className="flex items-center justify-center p-4 rounded-xl bg-Success-100">
              <img src="/wallet-icon.svg" alt="wallet icon" />
            </div>
            <div className="flex flex-col grow gap-3">
              <p className="text-xl text-Neutral-700 font-semibold">Available balance</p>
              <p className="text-Neutral-900 text-3xl font-extrabold">
                {walletDetails?.balance === null ||
                walletDetails?.balance === undefined ||
                isNaN(walletDetails?.balance)
                  ? '0.00'
                  : new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(walletDetails?.balance)}
              </p>
            </div>
            <div className="flex items-center">
              <p>4%</p>
              <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className="h-6 w-6" />
            </div>
          </div>
          <div className="w-1/2 bg-white p-6 rounded-2xl flex items-center gap-4">
            <div className="flex items-center justify-center p-4 rounded-xl bg-Error-100">
              <img src="/paid-icon.svg" alt="paid icon" />
            </div>
            <div className="flex flex-col grow gap-3">
              <p className="text-xl text-Neutral-700 font-semibold">Total paid</p>
              <p className="text-Neutral-900 text-3xl font-extrabold">
                {walletDetails?.balance === null ||
                walletDetails?.balance === undefined ||
                isNaN(walletDetails?.balance)
                  ? '0.00'
                  : new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(walletDetails?.balance)}
              </p>
            </div>
            <div className="flex items-center">
              <p>4%</p>
              <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className="h-6 w-6" />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="bg-white p-4 w-[25%] flex flex-col rounded-2xl">
            <p className="text-Neutral-500"> Sim</p>
            <p className="text-xl font-extrabold text-Neutral-800">$100</p>
          </div>
          <div className="bg-white p-4 w-[25%] flex flex-col rounded-2xl">
            <p className="text-Neutral-500"> Activation</p>
            <p className="text-xl font-extrabold text-Neutral-800">$100</p>
          </div>
          <div className="bg-white p-4 w-[25%] flex flex-col rounded-2xl">
            <p className="text-Neutral-500"> Payment Fee</p>
            <p className="text-xl font-extrabold text-Neutral-800">$100</p>
          </div>
          <div className="bg-white p-4 w-[25%] flex flex-col rounded-2xl">
            <p className="text-Neutral-500"> Monthly Subs</p>
            <p className="text-xl font-extrabold text-Neutral-800">$100</p>
          </div>
        </div>
      </div>
    );
  };
  const MDCard = () => {
    return (
      <div className="flex gap-4">
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">Available balance</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">
              {walletDetails?.balance === null ||
              walletDetails?.balance === undefined ||
              isNaN(walletDetails?.balance)
                ? '0.00'
                : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(walletDetails?.balance)}
            </p>
          </div>
          <div className="flex items-center">
            <p>4%</p>
            <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className="h-6 w-6" />
          </div>
        </div>
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">This month</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">
              {walletDetails?.balance === null ||
              walletDetails?.balance === undefined ||
              isNaN(walletDetails?.balance)
                ? '0.00'
                : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(walletDetails?.balance)}
            </p>
          </div>
          <div className="flex items-center">
            <p>4%</p>
            <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className="h-6 w-6" />
          </div>
        </div>
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">Total earnings</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">
              {walletDetails?.balance === null ||
              walletDetails?.balance === undefined ||
              isNaN(walletDetails?.balance)
                ? '0.00'
                : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(walletDetails?.balance)}
            </p>
          </div>
          <div className="flex items-center">
            <p>4%</p>
            <img src="./arrow-up-green.svg" alt="Arrow-Up Icon" className="h-6 w-6" />
          </div>
        </div>
      </div>
    );
  };
  const AdminCard = () => {
    return (
      <div className="flex gap-4">
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <img src="/earning-new-request-icon.svg" alt="new request icon" />
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">New requests</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">
              {WithdrawRequests?.length || '_'}
            </p>
          </div>
        </div>
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <img src="/earning-request-amount-icon.svg" alt="request amount icon" />
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">Requested amount</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">${totalRequestedAmount}</p>
          </div>
        </div>
        <div className="basis-1/3 bg-white p-6 rounded-2xl flex items-center gap-4">
          <img src="/earning-total-paid-icon.svg" alt="new request icon" />
          <div className="flex flex-col grow gap-3">
            <p className="text-xl text-Neutral-700 font-semibold">Total paid</p>
            <p className="text-Neutral-900 text-3xl font-extrabold">${totalPaidAmount}</p>
          </div>
        </div>
      </div>
    );
  };
  const AdMdTable = () => {
    return (
      <>
        <h3 className="text-2xl font-extrabold mt-12 text-black">Transactions</h3>

        <div className="flex flex-col bg-white rounded-2xl px-2 mt-6 overflow-x-auto relative">
          <div className={`overflow-x-auto relative`}>
            <table className="w-full border-collapse table-fixed">
              <thead className="">
                <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                  <th
                    className="text-left w-1/5 text-xs p-4 font-medium cursor-pointer"
                    onClick={() => requestSort('date')}>
                    Date
                  </th>

                  <th
                    className="text-left w-1/5 text-xs p-4 font-medium cursor-pointer"
                    onClick={() => requestSort('description')}>
                    Description
                  </th>
                  <th
                    className="text-left w-1/5 text-xs  p-4 font-medium cursor-pointer"
                    onClick={() => requestSort('userId')}>
                    Trx. ID
                  </th>

                  <th
                    className="text-left w-1/5 text-xs  p-4 font-medium cursor-pointer"
                    onClick={() => requestSort('amount')}>
                    Amount
                  </th>
                  <th
                    className="text-left w-1/5 text-xs  p-4 font-medium cursor-pointer"
                    onClick={() => requestSort('status')}>
                    Status
                  </th>
                </tr>
              </thead>

              {loading ? (
                <div className="flex justify-center items-center w-full h-80">
                  <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
                </div>
              ) : (
                <tbody>
                  {transactions?.length > 0 ? (
                    transactions.map((transaction, index) => (
                      <tr
                        key={index}
                        className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                        <td className="p-4 text-Neutral-700 text-base">{'7 Oct, 2024'}</td>

                        <td className="p-4 text-Neutral-700 text-base truncate">
                          {transaction.description}
                        </td>
                        <td className="p-4 text-Neutral-700 text-base truncate">
                          {transaction._id}
                        </td>
                        <td className="p-4 text-Neutral-700 text-base truncate">
                          {transaction.type === 'Credit' ? '+' : '-'}${transaction.amount}
                        </td>

                        <td className="p-4">
                          <div
                            className={`inline-flex items-center gap-1 p-2 rounded-5-5xl ${statusClasses[transaction?.status]?.bg || ''}`}>
                            <div
                              className={`font-semibold text-base capitalize whitespace-nowrap ${statusClasses[transaction?.status]?.text || ''}`}>
                              {transaction?.status}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {/* Dynamically calculate colSpan based on the number of columns */}
                      <td colSpan={'5'} className="text-center py-3">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-end items-center pl-4 bg-white">
            <div className="flex items-center gap-[26px]">
              <div className="flex items-center gap-2">
                <span className="text-Neutral-500">Rows per page:</span>
                <div
                  className="flex items-center"
                  onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                  ref={paginationRef}>
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="text-Neutral-500 outline-none border-none rounded-[24px] appearance-none">
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <img
                    src="/arrow_drop_down.svg"
                    alt="arrow-Down Icon"
                    className={`transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                    style={{ transitionDuration: '100ms' }}
                  />
                </div>
              </div>
              <span className="text-Neutral-500">
                {' '}
                {(currentPage - 1) * rowsPerPage + 1} -{' '}
                {currentPage * rowsPerPage > totalTransactions
                  ? totalTransactions
                  : currentPage * rowsPerPage}{' '}
                of {totalTransactions}
              </span>
              <div>
                <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                  &lt;
                </button>
                <button className="p-2 rounded-lg" onClick={handleNextPage}>
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const NewRequestTable = () => {
    return (
      <div>
        <h3 className="text-2xl font-extrabold text-black">New requests</h3>

        <div className="flex flex-col bg-white rounded-2xl px-2 mt-6 overflow-x-auto relative">
          <div className={`overflow-x-auto relative`}>
            <table className="w-full border-collapse table-fixed">
              <thead className="">
                <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                  <th className="text-left w-1/4 text-xs p-4 font-medium cursor-pointer">
                    Description
                  </th>

                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Trx. ID
                  </th>

                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Amount
                  </th>
                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Status
                  </th>
                </tr>
              </thead>

              {loading ? (
                <div className="flex justify-center items-center w-full h-80">
                  <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
                </div>
              ) : (
                <tbody>
                  {WithdrawRequests?.length > 0 ? (
                    WithdrawRequests.map((data, index) => (
                      <tr
                        key={index}
                        className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                        <td className="p-4 truncate">
                          <div>
                            <div className="inline-flex items-center justify-start gap-1">
                              <div
                                className={`py-1 px-2 rounded-[21px] ${data?.role?.name === 'Master Distributor' ? 'bg-[#EFEBFF] text-[#6F47FF]' : 'bg-Primary-100 text-Primary-600'} font-bold text-sm  `}>
                                {data?.role?.name === 'Master Distributor' ? 'MD' : 'AD'}
                              </div>
                              <p className="text-Neutral-900 text-base">{data?.user?.name}</p>
                            </div>
                            <p className="text-Neutral-700 text-sm">{data?.user?.email}</p>
                          </div>
                        </td>
                        <td className="p-4 text-Neutral-700 text-base truncate">{data?._id}</td>
                        <td className="p-4 text-Neutral-700 text-base">${data?.amount}</td>
                        <td className="p-4 text-Neutral-700 text-base">
                          <div
                            className="bg-Primary-100 rounded-3xl py-2 px-4 text-center cursor-pointer text-Primary-500 font-bold text-base inline-flex"
                            onClick={() => OpenWithdrawModal(data)}>
                            Approve
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {/* Dynamically calculate colSpan based on the number of columns */}
                      <td colSpan={'4'} className="text-center py-3">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-end items-center pl-4 bg-white">
            <div className="flex items-center gap-[26px]">
              <div className="flex items-center gap-2">
                <span className="text-Neutral-500">Rows per page:</span>
                <div
                  className="flex items-center"
                  onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                  ref={paginationRef}>
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="text-Neutral-500 outline-none border-none rounded-[24px] appearance-none">
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <img
                    src="/arrow_drop_down.svg"
                    alt="arrow-Down Icon"
                    className={`transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                    style={{ transitionDuration: '100ms' }}
                  />
                </div>
              </div>
              <span className="text-Neutral-500">
                {(currentPage - 1) * rowsPerPage + 1} -{' '}
                {currentPage * rowsPerPage > totalTransactions
                  ? totalTransactions
                  : currentPage * rowsPerPage}{' '}
                of {totalTransactions}
              </span>
              <div>
                <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                  &lt;
                </button>
                <button className="p-2 rounded-lg" onClick={handleNextPage}>
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PaymentTable = () => {
    return (
      <div>
        <h3 className="text-2xl font-extrabold text-black">Payments</h3>

        <div className="flex flex-col bg-white rounded-2xl px-2 mt-6 overflow-x-auto relative">
          <div className={`overflow-x-auto relative`}>
            <table className="w-full border-collapse table-fixed">
              <thead className="">
                <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                  <th className="text-left w-1/4 text-xs p-4 font-medium cursor-pointer">
                    Description
                  </th>

                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Trx. ID
                  </th>

                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Amount
                  </th>
                  <th className="text-left w-1/4 text-xs  p-4 font-medium cursor-pointer">
                    Status
                  </th>
                </tr>
              </thead>

              {loading ? (
                <div className="flex justify-center items-center w-full h-80">
                  <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-50per left-45per" />
                </div>
              ) : (
                <tbody>
                  {transactions?.length > 0 ? (
                    transactions.map((transaction, index) => (
                      <tr
                        key={index}
                        className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                        <td className="p-4 text-Neutral-700 text-base truncate">
                          {transaction.description}
                        </td>
                        <td className="p-4 text-Neutral-700 text-base truncate">
                          {transaction._id}
                        </td>
                        <td className="p-4 text-Neutral-700 text-base truncate">
                          ${transaction.amount}
                        </td>
                        <td className="p-4">
                          <div
                            className={`inline-flex items-center gap-1 p-2 rounded-5-5xl ${statusClasses[transaction?.status]?.bg || ''}`}>
                            <div
                              className={`w-2 h-2 shrink-0 rounded-full ${statusClasses[transaction?.status]?.dot || ''}`}></div>
                            <div
                              className={`font-semibold text-base capitalize ${statusClasses[transaction?.status]?.text || ''}`}>
                              {transaction?.status}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {/* Dynamically calculate colSpan based on the number of columns */}
                      <td colSpan={'4'} className="text-center py-3">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          <div className="flex justify-end items-center pl-4 bg-white">
            <div className="flex items-center gap-[26px]">
              <div className="flex items-center gap-2">
                <span className="text-Neutral-500">Rows per page:</span>
                <div
                  className="flex items-center"
                  onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                  ref={paginationRef}>
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="text-Neutral-500 outline-none border-none rounded-[24px] appearance-none">
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <img
                    src="/arrow_drop_down.svg"
                    alt="arrow-Down Icon"
                    className={`transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                    style={{ transitionDuration: '100ms' }}
                  />
                </div>
              </div>
              <span className="text-Neutral-500">
                {' '}
                {(currentPage - 1) * rowsPerPage + 1} -{' '}
                {currentPage * rowsPerPage > totalTransactions
                  ? totalTransactions
                  : currentPage * rowsPerPage}{' '}
                of {totalTransactions}
              </span>
              <div>
                <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                  &lt;
                </button>
                <button className="p-2 rounded-lg" onClick={handleNextPage}>
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const AdminTable = () => {
    return (
      <div className="w-full flex mt-8 mb-3 flex-col gap-8">
        <NewRequestTable />
        <PaymentTable />
      </div>
    );
  };

  return (
    <>
      <div className="flex max-w-[1080px] w-full mx-auto flex-col h-full  font-jakarta">
        <div className="flex flex-col gap-6 mt-5">
          {/* top heading section  */}
          <div className="flex justify-between items-center w-full">
            <h5 className="text-2xl font-extrabold text-black">Earnings</h5>
            {user?.roleId?.name !== 'Admin' && (
              <div className="flex items-center gap-2">
                {user?.roleId?.name === 'Authorized Distributor' && (
                  <NavLink
                    to="/add-money"
                    className="flex items-center justify-center hover:bg-opacity-85 cursor-pointer rounded-3xl py-2 px-4 bg-Primary-100">
                    <p className="text-black font-bold">Add money</p>
                  </NavLink>
                )}

                <NavLink
                  to="/withdraw-money"
                  className="flex items-center justify-center hover:bg-opacity-85 cursor-pointer rounded-3xl py-2 px-4 bg-Primary-400">
                  <p className="text-black font-bold">Withdraw</p>
                </NavLink>
              </div>
            )}
          </div>
          {user?.roleId?.name === 'Authorized Distributor' && <ADCard />}
          {user?.roleId?.name === 'Master Distributor' && <MDCard />}
          {user?.roleId?.name === 'Admin' && <AdminCard />}
        </div>
        {user?.roleId?.name !== 'Admin' && <AdMdTable />}
        {user?.roleId?.name === 'Admin' && <AdminTable />}
      </div>
      {openWithdrawModal && selectedNewRequest && (
        <WithdrawRequestModal
          data={selectedNewRequest}
          handleRequest={handleRequest}
          onClose={() => setOpenWithdrawModal(false)}
        />
      )}
    </>
  );
};

export default EarningList;
