import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/user';
import Utils from '../utility';
import { useDispatch } from 'react-redux';

import Cookies from '../managers/sessionManager';
import { updateUser } from '../slices/userSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import CenterWrapper from '../common/CenterWrapper';
import CustomInputField from '../common/onboarding/CustomInputField ';
import { TbLoader3 } from 'react-icons/tb';

function AdminLogin() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const passwordInputRef = useRef(null);
  const [errors, setErrors] = useState({});

  const [formdata, setFormdata] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    let handler = (e) => {
      if (passwordInputRef.current && !passwordInputRef.current.contains(e.target)) {
        setInputFocused(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleInputChange = (field, name) => (e) => {
    const { id, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${name} is required`, // Set the error if the input is empty
      }));
    } else {
      // Clear the specific error for the input being changed
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null, // Clear the error for this specific field
      }));
    }
  };

  const handleLogin = async () => {
    setErrors({});
    const newErrors = {};
    if (!formdata.email) {
      newErrors.email = 'email is required';
    }
    if (!formdata.password) {
      newErrors.password = 'email is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      return;
    }
    setLoading(true);
    try {
      // Perform sign-in request
      const response = await new UserService().logIn(formdata);

      // Extract user data and token from the response
      const { user, token } = response;

      // Update Redux state with the user data
      dispatch(updateUser({ ...user, isLoggedIn: true }));

      // Store the token in localStorage or cookies (not shown here)
      localStorage.setItem('ACCESS_TOKEN', token);
      new Cookies().set('ACCESS_TOKEN', token);

      // Redirect to the dashboard
      //  history.push("/dashboard");
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setLoginError('Incorrect email or password.');
      } else if (error.response && error.response.status === 403) {
        setLoginError('Access not provided.');
      } else {
        Utils.failureToastMessage('Incorrect email or password');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full min-h-screen flex bg-white font-jakarta">
        <div className="xxl:max-w-[480px] hidden lg:flex lg:max-w-[360px] w-full  justify-between flex-col lg:px-10 lg:pt-4 lg:pb-10 xxl:px-16 xxl:py-6">
          <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="  max-w-[171px] w-full" />

          <img
            src="/sidebar-login-image.png"
            alt="desktop-image"
            className="lg:max-w-[232px] xxl:max-w-[432px]"
          />
        </div>
        <CenterWrapper>
          {
            <div className="w-full max-w-[480px] h-full flex flex-col justify-center items-center gap-8">
              <img
                src="/sidebar-login-image.png"
                alt="desktop-image"
                className="w-[252px] h-[252px] mx-auto lg:hidden"
              />
              <h3 className="text-[39px] font-extrabold text-center ">
                Run your business anytime, anywhere with EZ Mobile Portal.
              </h3>

              <div className="flex flex-col gap-6 w-full">
                <div className="flex flex-col items-start gap-2">
                  <label htmlFor="email" className="text-Neutral-500">
                    Email
                  </label>
                  <input
                    id="email"
                    type="text"
                    placeholder={'Enter your gmail id'}
                    value={formdata?.email}
                    onChange={handleInputChange('email', 'email')}
                    className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border ${errors?.email ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
                  />
                  {errors?.email && (
                    <p className="text-Error-500 text-sm first-letter:capitalize">
                      {errors?.email}
                    </p>
                  )}
                </div>
                <div className="flex flex-col items-start gap-2">
                  <label htmlFor="Password" className="text-Neutral-500">
                    Password
                  </label>
                  <div className="relative w-full">
                    <input
                      id="Password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      value={formdata.password}
                      onChange={handleInputChange('password', 'password')}
                      className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border pr-10 ${errors?.password ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
                    />
                    {showPassword ? (
                      <FaEyeSlash
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6 text-Neutral-900 cursor-pointer"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <FaEye
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6 text-Neutral-900 cursor-pointer"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                  {errors?.password && (
                    <p className="text-Error-500 text-sm first-letter:capitalize">
                      {errors?.password}
                    </p>
                  )}
                  {loginError && (
                    <p className="text-Error-500 text-sm first-letter:capitalize">{loginError}</p>
                  )}
                </div>
                <div
                  className="w-full py-3 px-6 rounded-3xl bg-Primary-400 text-center flex items-center justify-center text-black font-bold text-base cursor-pointer"
                  onClick={handleLogin}>
                  {loading ? <TbLoader3 className="animate-spin text-black" /> : 'Sign in'}
                </div>
              </div>
            </div>
          }
        </CenterWrapper>
      </div>
    </>
  );
}

export default AdminLogin;
