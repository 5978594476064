import React from 'react';
import { FaCheck } from 'react-icons/fa'; // Import FaCheck for the checkmark icon

function Sidebar({ selectedTab, setSelectedTab }) {
  return (
    <>
      <div className="flex flex-col h-full w-full  px-10 pb-10 lg:px-16 lg:py-6 relative">
        <img src="/tittle-logo.svg" alt="EZ-CRM Logo" className="max-w-[171px] w-full" />

        <div className="flex flex-col gap-4 lg:mt-12 mt-8">
          {/* Subscriber Details */}
          <div>
            <div className="flex items-center gap-4 py-3 cursor-pointer relative">
              <div
                className={`h-6 w-6 border-2  box-border rounded-full text-sm font-bold flex justify-center items-center ${
                  selectedTab === 0
                    ? 'border-[#009DFF] text-[#009DFF]' // Blue circle for current tab
                    : selectedTab > 1
                      ? 'border-Success-600 text-white bg-Success-600'
                        : 'border-Neutral-100 text-Neutral-500'
                }`}>
                {selectedTab > 1 ? <FaCheck /> : 1} {/* Display checkmark or number */}
              </div>
              <p
                className={`text-base ${
                  selectedTab === 0 || selectedTab === 1
                    ? 'text-Primary-500 font-bold'
                    : 'text-Neutral-200 font-normal'
                }`}>
                Subscriber Details
              </p>
            </div>

            {selectedTab === 0 || selectedTab === 1 ? (
              <div className="flex flex-col">
                <div className="flex justify-between items-center cursor-pointer">
                  <p
                    className={`pl-10 py-3 text-base ${
                      selectedTab === 0
                        ? 'text-Neutral-900 font-bold'
                        : 'text-Neutral-500 font-normal'
                    }`}>
                    Identity
                  </p>
                  {selectedTab === 0 && (
                    <img
                      src="./onboarding-sidebar-right-arrow.svg"
                      alt="Right-Arrow Icon"
                      className="h-6 w-6 hover:opacity-80"
                    />
                  )}
                </div>
                <div className="flex justify-between items-center h-12 cursor-pointer">
                  <p
                    className={`pl-10 py-3 text-base ${
                      selectedTab === 1
                        ? 'text-Neutral-900 font-bold'
                        : 'text-Neutral-500 font-normal'
                    }`}>
                    Address
                  </p>
                  {selectedTab === 1 && (
                    <img
                      src="./onboarding-sidebar-right-arrow.svg"
                      alt="Right-Arrow Icon"
                      className="h-6 w-6 hover:opacity-80"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>

          {/* Subscription Details */}
          <div>
            <div className="flex items-center gap-4 py-3 cursor-pointer relative">
              <div
                className={`h-6 w-6 border-2  box-border rounded-full text-sm font-bold flex justify-center items-center ${
                  selectedTab === 1
                    ? 'border-[#009DFF] text-[#009DFF]' // Blue circle for current tab
                    : selectedTab > 3
                      ? 'border-Success-600 text-white bg-Success-600'
                      : 'border-Primary-500 text-Primary-500'
                }`}>
                {selectedTab > 3 ? <FaCheck /> : 2} {/* Display checkmark or number */}
              </div>
              <p
                className={`text-base ${
                  selectedTab === 2 || selectedTab === 3
                    ? 'text-Primary-500 font-bold'
                    : 'text-Neutral-200 font-normal'
                }`}>
                Subscription Details
              </p>
            </div>

            {selectedTab === 2 || selectedTab === 3 ? (
              <div className="flex flex-col">
                <div className="flex justify-between items-center cursor-pointer">
                  <p
                    className={`pl-10 py-3 text-base ${
                      selectedTab === 2
                        ? 'text-Neutral-900 font-bold'
                        : 'text-Neutral-500 font-normal'
                    }`}>
                    Option
                  </p>
                  {selectedTab === 2 && (
                    <img
                      src="./onboarding-sidebar-right-arrow.svg"
                      alt="Right-Arrow Icon"
                      className="h-6 w-6 hover:opacity-80"
                    />
                  )}
                </div>
                <div className="flex justify-between items-center h-12 cursor-pointer">
                  <p
                    className={`pl-10 py-3 text-base ${
                      selectedTab === 3
                        ? 'text-Neutral-900 font-bold'
                        : 'text-Neutral-500 font-normal'
                    }`}>
                    Details
                  </p>
                  {selectedTab === 3 && (
                    <img
                      src="./onboarding-sidebar-right-arrow.svg"
                      alt="Right-Arrow Icon"
                      className="h-6 w-6 hover:opacity-80"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>

          {/* Wireless Plan */}
          <div>
            <div className="flex items-center gap-4 py-3 cursor-pointer relative">
              <div
                className={`h-6 w-6 border-2  box-border rounded-full text-sm font-bold flex justify-center items-center ${
                  selectedTab === 4
                    ? 'border-[#009DFF] text-[#009DFF]' // Blue circle for current tab
                    : selectedTab > 4
                      ? 'border-Success-600 text-white bg-Success-600'
                      : 'border-Primary-500 text-Primary-500'
                }`}>
                {selectedTab > 4 ? <FaCheck /> : 3} {/* Display checkmark or number */}
              </div>
              <p
                className={`text-base ${
                  selectedTab === 4 ? 'text-Primary-500 font-bold' : 'text-Neutral-200 font-normal'
                }`}>
                Wireless Plan
              </p>
              {selectedTab === 4 && (
                <img
                  src="/onboarding-sidebar-right-arrow.svg"
                  alt="Right-Arrow Icon"
                  className="h-6 w-6 hover:opacity-80 ml-auto"
                />
              )}
            </div>
          </div>

          {/* Checkout */}
          <div>
            <div className="flex items-center gap-4 py-3 cursor-pointer relative">
              <div
                className={`h-6 w-6 border-2 box-border rounded-full text-sm font-bold flex justify-center items-center ${
                  selectedTab === 5
                    ? 'border-[#009DFF] text-[#009DFF]' // Blue circle for current tab
                    : selectedTab > 5
                      ? 'border-green-600 text-white bg-green-600' // Green checkmark for completed tab
                      : 'border-Primary-500 text-Primary-500' // Grey for incomplete
                }`}>
                {selectedTab > 5 ? <FaCheck /> : 4} {/* Display checkmark or number */}
              </div>
              <p
                className={`text-base ${
                  selectedTab === 5 ? 'text-Primary-500 font-bold' : 'text-Neutral-200 font-normal'
                }`}>
                Checkout
              </p>
              {selectedTab === 5 && (
                <img
                  src="/onboarding-sidebar-right-arrow.svg"
                  alt="Right-Arrow Icon"
                  className="h-6 w-6 hover:opacity-80 ml-auto"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
