import React, { useState, useEffect } from 'react';
import SubscriberTableComp from './subscriberTable';
import ADTableComp from './AdTable';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, updateDistributors } from '../slices/userSlice';
import WalletService from '../services/wallet';
import { useNavigate } from 'react-router-dom';
import LineChartComponent from '../dashboard/line-chart-component';
import { getGradient } from '../utils/lineChart';
import UserService from '../services/user';

const roleConfig = {
  'Authorized Distributor': {
    title: 'Subscriber Management',
    text: 'Add new subscriber',
    path: '/add-subscriber',
  },
  'Master Distributor': {
    title: 'AD Management',
    text: 'Add New AD',
    path: '/add?role=ad',
  },
  default: {
    title: '',
    text: '',
    path: '',
  },
};

const AdMdManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const [roleData, setRoleData] = useState(roleConfig.default);
  const [subscribersLoading, setSubscribersLoading] = useState(false);
  const [dropdownQuery, setDropdownQuery] = useState('name');
  const [searchInput, setSearchInput] = useState('');
  const [totalDistributors, setTotalDistributors] = useState(0);
  const [subscribers, setSubscribers] = useState([]);


  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    sortConfig: { key: null, direction: 'ascending' },
  });

  useEffect(() => {
    const newRoleData = roleConfig[user?.roleId?.name] || roleConfig.default;
    setRoleData(newRoleData);
  }, [user?.roleId?.name]);
  const [adminCardsData, setAdminCardsData] = useState({
    totalSubscribers: 0,
    orders: 0,
    conversionRate: 0,
  });

  const fetchMdPortalData = async (userId) => {
    try {
      setSubscribersLoading(true);
      const queryParams = `?skip=${
        (pagination.currentPage - 1) * pagination.rowsPerPage
      }&limit=${pagination.rowsPerPage}&${dropdownQuery}=${searchInput}`;
      const response = await new UserService().getMdPortalDistributorsData(userId, queryParams);
      setSubscribersLoading(false);

      const updatedSubscribersData = [];
      response.users.forEach((item) => {
        updatedSubscribersData.push({
          ...item,
          name: item.firstName + ' ' + item.lastName,
          number: item.contactNumber,
        });
      });
      setTotalDistributors(response.totalCount);
      setSubscribersLoading(false);
      setSubscribers(updatedSubscribersData);
      dispatch(updateDistributors(updatedSubscribersData));
    } catch (error) {
      console.error('Error fetching subscribers data:', error);
    }
  };

  const fetchAdPortalData = async (userId) => {
    try {
      setSubscribersLoading(true);
      const queryParams = `?skip=${
        (pagination.currentPage - 1) * pagination.rowsPerPage
      }&limit=${pagination.rowsPerPage}&${dropdownQuery}=${searchInput}`;
      const response = await new WalletService().getActiveSubscribersData(userId, queryParams);
      setSubscribersLoading(false);
      const updatedSubscribersData = [];
      response.subscribersList.forEach((item) => {
        updatedSubscribersData.push({
          ...item,
          name: item.firstName + ' ' + item.lastName,
          number: item.contactNumber,
        });
      });
      setTotalDistributors(response.totalCount);
      setSubscribersLoading(false);
      setSubscribers(updatedSubscribersData);
      dispatch(updateDistributors(updatedSubscribersData));
    } catch (error) {
      console.error('Error fetching subscribers data:', error);
    } finally {
      setSubscribersLoading(false);
    }
  };

  useEffect(() => {
    user?.roleId?.name === 'Master Distributor' && fetchMdPortalData(user?._id);
    user?.roleId?.name === 'Authorized Distributor' && fetchAdPortalData(user?._id);
  }, [pagination.currentPage]);

  const renderADCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <TotalAdChart />
      <ActiveSubscribers />
      <ActiveSubscriptions />
    </div>
  );

  const greenChart = {
    color: '#19C84D',
    bg: '#FFF',
    gridLineColour: '#DFE0E2',
    gradientStart: 'rgba(25, 200, 77, 0.05)',
    gradientEnd: ' rgba(25, 200, 77, 0.30)',
  };
  const redChart = {
    color: '#F83A5D',
    bg: '#FFF',
    gridLineColour: '#DFE0E2',
    gradientStart: 'rgba(245, 96, 125, 0.05)',
    gradientEnd: ' rgba(245, 96, 125, 0.30)',
  };

  const TotalADData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const ActiveSubscribersData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, redChart);
        },
        borderColor: redChart.color,
        tension: 0.4,
        pointBackgroundColor: redChart.color,
        pointBorderColor: redChart.color,
        pointHoverBackgroundColor: redChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };
  const ActiveSubscriptionsData = {
    labels: ['january', 'february', 'march', 'april', 'may', 'june'],
    datasets: [
      {
        label: 'Sales',
        data: [0, 10, 5, 2, 20, 30],
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea, greenChart);
        },
        borderColor: greenChart.color,
        tension: 0.4,
        pointBackgroundColor: greenChart.color,
        pointBorderColor: greenChart.color,
        pointHoverBackgroundColor: greenChart.color,
        pointHoverBorderColor: 'white',
        clip: false,
      },
    ],
  };

  const TotalAdChart = () => {
    return (
      <LineChartComponent
        title={'Total ADs'}
        activeData={0}
        totalData={0}
        percent={'0'}
        chartData={TotalADData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };
  const ActiveSubscribers = () => {
    return (
      <LineChartComponent
        title={'Active subscribers'}
        activeData={0}
        totalData={0}
        percent={'0'}
        chartData={ActiveSubscribersData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };
  const ActiveSubscriptions = () => {
    return (
      <LineChartComponent
        title={'Active subscriptions'}
        activeData={0}
        totalData={0}
        percent={'0'}
        chartData={ActiveSubscriptionsData}
        chartColour={greenChart}
        yearBGColour={'#F4F5F5'}
      />
    );
  };

  const renderMDCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <TotalAdChart />
      <ActiveSubscribers />
      <ActiveSubscriptions />
    </div>
  );

  return (
    <div className="p-4 z-10 font-jakarta w-full h-full bg-[#E9F9FF]">
      <div className="flex flex-col">
        <div className="flex  items-center justify-between mb-4">
          <h2 className="text-2xl font-extrabold text-black">{roleData.title}</h2>

          {roleData.text.trim() && (
            <button
              className="bg-Primary-400 font-bold hover:bg-opacity-85 text-black px-6 py-3 rounded-3xl"
              onClick={() => navigate(roleData.path)}>
              {roleData.text}
            </button>
          )}
        </div>
        {user && user?.roleId?.name === 'Authorized Distributor' && renderADCards()}
        {user && user?.roleId?.name === 'Master Distributor' && renderMDCards()}
        {user && user?.roleId?.name === 'Master Distributor' && (
          <ADTableComp
            user={user}
            distributors={subscribers}
            subscribersLoading={subscribersLoading}
            totalDistributors={totalDistributors}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {user && user?.roleId?.name === 'Authorized Distributor' && (
          <SubscriberTableComp
            subscribers={subscribers}
            setSubscribers={setSubscribers}
            subscribersLoading={subscribersLoading}
            userId={user._id}
            totalDistributors={totalDistributors}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
      </div>
    </div>
  );
};

export default AdMdManagement;
