import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PersonalDetails from './personal-details';
import AddressDetails from './address-details';
import WalletService from '../services/wallet';
import SubscriptionDetails from './subscription-details';
import DeviceSimDetails from './device-sim-details';
import Sidebar from './sidebar';
import WireLessPlan from './wireless-plan';
import Checkout from './checkout';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddNewSubscriber = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  const [products, setProducts] = useState([]);
  const [allproducts, setAllProducts] = useState(null);
  const [wirelessPlanType, setWirelessPlanType] = useState(0);
  const [plans, setPlans] = useState([]);
  const [sortedPlans, setSortedPlans] = useState([]);
  const [subscriber, setSubscriber] = useState({});

  const [selectedPlan, setSelectedPlan] = useState(null);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    zipCode: '305002',
    city: '',
    state: '',
    streetName: '',
    streetDirection: '',
    portingType: 'New Sim',
    oldNumber: '',
    IMEI: '356680110951190',
    ICCID: '',
    streetNumber: '1',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const fetchProductsData = async () => {
    try {
      const response = await new WalletService().getAvailablePlans({
        imei: formData.IMEI, // Accessing IMEI from formData
        mvnoId: 11,
      });
      setPlans(response);

      let updatedProductsData = [];
      if (wirelessPlanType === 0) {
        updatedProductsData = response?.filter((item) => item.teaserType === 'Custom Overage');
      } else {
        updatedProductsData = response?.filter((item) => item.teaserType !== 'Custom Overage');
      }
      setSortedPlans(updatedProductsData);
      setSelectedTab((prev) => prev + 1);
    } catch (error) {
      console.error('Error while fetching products data', error);
    }
  };
  const getCRMProducts = async () => {
    try {
      const response = await new WalletService().getProducts();
      setProducts(response);
    } catch (error) {
      console.error('Error fetching CRM products:', error);
    }
  };

  useEffect(() => {
    getCRMProducts();
  }, []);

  const handlePlansSorting = (index) => {
    let updatedPlansData = [];
    if (index === 0) {
      updatedPlansData = plans.filter((item) => item.teaserType === 'Custom Overage');
    } else {
      updatedPlansData = plans.filter((item) => item.teaserType !== 'Custom Overage');
    }

    setSortedPlans(updatedPlansData);
    setWirelessPlanType(index);
    setSelectedPlan(null);
  };

  const startSubscriberActivation = async () => {
    if (formData.portingType === 'existing') {
      console.log('inside existing');

      startPortInActivation();
      return;
    }
    // if (!selectedPlan) {

    //   console.error('No plan selected.');
    //   return;
    // }
    // setSelectedPlan(sortedPlans[0]);
    console.log('1');

    const simCardPurchaseProduct = products.find((product) => product.name === 'SIM Card Purchase');
    const customerActivationProduct = products.find(
      (product) => product.name === 'Customer Activation'
    );
    const wirelessServicesProduct = products.find(
      (product) => product.name === 'Wireless Services'
    );

    if (!simCardPurchaseProduct || !customerActivationProduct || !wirelessServicesProduct) {
      console.error('Required CRM products not found.');
      // setLoading(false);
      return;
    }

    const requestData = {
      imei: formData?.IMEI,
      iccid: formData?.ICCID,
      carrier: 'AT&T',
      product: {
        deviceType: selectedPlan?.deviceType,
        productFamily: selectedPlan?.productFamily,
        size: selectedPlan?.size,
        serviceType: selectedPlan?.serviceType,
        offerId: selectedPlan?.offerId,
        offerName: selectedPlan?.offerName,
        soc: selectedPlan?.soc,
      },
      mid: subscriber.mid,
      subscriberId: subscriber?._id,
      adUserId: user?._id,
      simCardPurchaseProductId: simCardPurchaseProduct?._id,
      customerActivationProductId: customerActivationProduct?._id,
      wirelessServicesProductId: wirelessServicesProduct?._id,
      planId: selectedPlan?._id,
      isNewSubscriber: true,
      isPortIn: false,
    };

    try {
      // Check if the user's typed ICCID matches one in the iccids array
      // const matchedIccid = iccids.find((item) => item.iccid === formData?.ICCID);
      const matchedIccid = false;

      let activateRequestData = {};
      let subscriberAccountData = {};

      if (matchedIccid) {
        activateRequestData = {
          accountId: subscriberAccountData?.accountId,
          tempSubscriptionId: matchedIccid?.id,
          subscriptionId: matchedIccid?.subscriptionId,
        };
      } else {
        const response = await new WalletService().startPlanActivation(requestData);
        activateRequestData = {
          accountId: response?.accountId,
          tempSubscriptionId: response?.tempSubscriptionId,
          subscriptionId: response?._id,
        };
      }

      const result = await activateSubscriberPlan(activateRequestData);
      // setLoading(false);
      if (result.success) {
        // setActivationSuccess(true);
        // history.push('/dashboard');
      }
      return true;
    } catch (error) {
      console.error('Error starting subscriber activation:', error);
      // setLoading(false);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      return error;
    }
  };

  const startPortInActivation = async () => {
    if (!selectedPlan) {
      console.error('No plan selected.');
      // setLoading(false);
      return;
    }
    const simCardPurchaseProduct = products.find((product) => product.name === 'SIM Card Purchase');
    const customerActivationProduct = products.find(
      (product) => product.name === 'Customer Activation'
    );
    const wirelessServicesProduct = products.find(
      (product) => product.name === 'Wireless Services'
    );

    if (!simCardPurchaseProduct || !customerActivationProduct || !wirelessServicesProduct) {
      console.error('Required CRM products not found.');
      // setLoading(false);
      return;
    }

    const requestData = {
      oldService: {
        billingAccountNumber: '287337652357',
        billingAccountPassword: '5277',
        firstName: 'beesu',
        lastName: 'reddy',
      },
      accountDetails: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        streetNumber: '1',
        streetDirection: formData.streetDirection,
        streetName: formData.streetName,
        city: formData.city,
        state: formData.state,
        zipCode: formData.zipCode,
        email: formData.email,
        contactNumber: formData.contactNumber,
        mvnoId: 11,
      },
      activationDetails: {
        imei: formData?.IMEI,
        iccid: formData?.ICCID,
        carrier: 'AT&T',
        product: {
          deviceType: selectedPlan?.deviceType,
          offerId: selectedPlan?.offerId,
          offerName: selectedPlan?.offerName,
          productFamily: selectedPlan?.productFamily,
          soc: selectedPlan?.soc,
          size: selectedPlan?.size,
          serviceType: selectedPlan?.serviceType,
          retailName: selectedPlan?.retailName || '',
          retailPrice: selectedPlan?.retailPrice || '0.0',
        },
      },
      msisdn: subscriber?.mid,
      subscriberId: subscriber?._id,
      adUserId: user?._id,
      simCardPurchaseProductId: simCardPurchaseProduct?._id,
      customerActivationProductId: customerActivationProduct?._id,
      wirelessServicesProductId: wirelessServicesProduct?._id,
      planId: selectedPlan?._id,
      isNewSubscriber: false,
      isPortIn: true,
    };

    try {
      const response = await new WalletService().portInStart(requestData);
      const activateRequestData = {
        accountId: response?.accountId,
        tempPortInId: '370',
        subscriptionId: response?.subscriberId,
        oldService: {
          billingAccountNumber: '287337652357',
          billingAccountPassword: '5277',
          firstName: 'beesu',
          lastName: 'reddy',
        },
        msisdn: response?.msisdn,
      };
      if (response) {
        await portInMake(activateRequestData);
        // setLoading(false);
        // setActivationSuccess(true);
      }

      return;
    } catch (error) {
      console.error('Error starting port-in activation:', error);
      // setLoading(false);
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      return error;
    }
  };
  const portInMake = async (requestData) => {
    try {
      await new WalletService().portInMake(requestData);
    } catch (error) {
      console.error('Error activating port in', error);
      // setLoading(false);
      return error;
    }
  };

  const activateSubscriberPlan = async (requestData) => {
    try {
      await new WalletService().activateSubscriberPlan(requestData);
    } catch (error) {
      console.error('Error activating plan', error);
      // setLoading(false);
      return error;
    }
  };

  return (
    <div className="mx-auto w-full  min-h-screen flex  bg-[#E9F9FF] relative font-jakarta">
      <div
        className="absolute top-8 right-8 p-3 rounded-lg bg-white hover:cursor-pointer"
        onClick={() => navigate('/dashboard')}>
        <MdClose className="w-5 h-5" />
      </div>
      <div className="hidden lg:block fixed top-0 left-0 h-full lg:w-[22.5rem] xxl:w-[30rem] max-w-[480px] z-40 bg-white overflow-y-auto custom-scrollbar">
        <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
      <div className="flex-1 flex justify-center lg:ml-[22.5rem] xxl:ml-[480px] mt-[5%] xxl:mt-[8%] relative">
        {selectedTab === 0 && (
          <PersonalDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 1 && (
          <AddressDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            setSubscriber={setSubscriber}
          />
        )}
        {selectedTab === 2 && (
          <SubscriptionDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
          />
        )}
        {selectedTab === 3 && (
          <DeviceSimDetails
            selectedTab={selectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setSelectedTab={setSelectedTab}
            fetchProductsData={fetchProductsData}
          />
        )}
        {selectedTab === 4 && (
          <WireLessPlan
            sortedPlans={sortedPlans}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            setSelectedTab={setSelectedTab}
            wirelessPlanType={wirelessPlanType}
            setWirelessPlanType={setWirelessPlanType}
            handlePlansSorting={handlePlansSorting}
            startSubscriberActivation={startSubscriberActivation}
          />
        )}
        {selectedTab === 5 && (
          <Checkout
            formData={formData}
            products={products}
            selectedPlan={selectedPlan}
            setSelectedTab={setSelectedTab}
            startSubscriberActivation={startSubscriberActivation}
          />
        )}
      </div>
    </div>
  );
};

export default AddNewSubscriber;
