import React, { useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { TbLoader3 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const SubscriberTableComp = ({
  subscribers = [],
  subscribersLoading,
  userId,
  tableRole,
  totalDistributors,
  pagination,
  setPagination,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [paginationDropdownOpen, setPaginationDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const paginationRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let handler = (e) => {
      if (paginationRef.current && !paginationRef.current.contains(e.target)) {
        setPaginationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);
  console.log('subscribers', subscribers);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedSubscribers = [...subscribers].sort((a, b) => {
    if (!pagination.sortConfig.key) return 0;
    if (a[pagination.sortConfig.key] < b[pagination.sortConfig.key]) {
      return pagination.sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[pagination.sortConfig.key] > b[pagination.sortConfig.key]) {
      return pagination.sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const filteredSubscribers = sortedSubscribers.filter(
    (subscriber) =>
      subscriber.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.email?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      subscriber.number?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleNextPage = () => {
    if (pagination.currentPage * pagination.rowsPerPage < totalDistributors) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const exportToCSV = () => {
    const headers = ['Name', 'Number', 'ICCID', 'IMEI', 'Email'];
    const rows = filteredSubscribers.map((sub) => [sub.name, sub.email, sub.number, sub.status]);
    const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'subscribers.csv');
  };

  const handleRowsPerPageChange = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: Number(event.target.value),
      currentPage: 1,
    });
  };

  const handleSubscriberDetails = (subscriberDetails) => {
    navigate(`/subscriber-details/${subscriberDetails?.mid}`);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className=" w-full pt-8 pb-4 flex justify-between items-center">
        <h2 className="text-2xl font-extrabold text-black">Subscribers</h2>

        <button className="pl-4 py-3 pr-3 bg-Primary-100 rounded-3xl flex justify-center items-center gap-3 cursor-pointer hover:bg-opacity-90">
          <p className="text-base text-Primary-500 font-semibold">Export</p>
          <img src="./chevron-down-blue.svg" alt="Chevron-Down Icon" className="h-4 w-4" />
        </button>
      </div>

      <div className="flex flex-col bg-white rounded-2xl px-2">
        <div className={`overflow-x-auto relative`}>
          <table className="w-full table-fixed  border-collapse">
            <thead className="">
              <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
                <th
                  className="text-left text-xs w-[25%] p-4 font-medium cursor-pointer"
                  onClick={() => requestSort('name')}>
                  NAME
                </th>
                <th
                  className="text-left text-xs w-[25%] p-4 font-medium cursor-pointer"
                  onClick={() => requestSort('email')}>
                  EMAIL
                </th>
                <th
                  className="text-left text-xs w-[25%] font-medium p-4 cursor-pointer"
                  onClick={() => requestSort('number')}>
                  PHONE
                </th>
                <th
                  className="text-left text-xs w-[25%] p-4 font-medium cursor-pointer"
                  onClick={() => requestSort('status')}>
                  STATUS
                </th>
              </tr>
            </thead>

            {subscribersLoading ? (
              <div className="flex justify-center items-center h-96">
                <TbLoader3 className="animate-spin text-black h-13 w-13 absolute top-45per left-45per" />
              </div>
            ) : (
              <tbody>
                {filteredSubscribers.length > 0 ? (
                  filteredSubscribers.map((subscriber, index) => (
                    <tr
                      key={index}
                      className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50"
                      onClick={() => handleSubscriberDetails(subscriber)}>
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {subscriber.firstName + ' ' + subscriber.lastName}
                      </td>
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {subscriber.email}
                      </td>
                      <td className="p-4 text-Neutral-700 text-base truncate">
                        {subscriber.number}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-3">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="flex justify-end items-center mt-4">
          <div className="flex items-center gap-10">
            <div className="flex items-center">
              <span className="text-Neutral-500">Rows per page:</span>
              <div
                className="flex items-center"
                onClick={() => setPaginationDropdownOpen(!paginationDropdownOpen)}
                ref={paginationRef}>
                <select
                  value={pagination.rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="text-Neutral-500 outline-none border-none rounded-[24px] appearance-none">
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
                <img
                  src="/arrow_drop_down.svg"
                  alt="arrow-Down Icon"
                  className={`transition-all ${paginationDropdownOpen ? 'rotate-180' : ''}`}
                  style={{ transitionDuration: '100ms' }}
                />
              </div>
            </div>
            <span className="text-Neutral-500">
              {(pagination.currentPage - 1) * pagination.rowsPerPage + 1} -{' '}
              {pagination.currentPage * pagination.rowsPerPage > totalDistributors
                ? totalDistributors
                : pagination.currentPage * pagination.rowsPerPage}{' '}
              of {totalDistributors}
            </span>
            <div>
              <button className="p-2 rounded-lg mr-2" onClick={handlePrevPage}>
                &lt;
              </button>
              <button className="p-2 rounded-lg" onClick={handleNextPage}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberTableComp;
