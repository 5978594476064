import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar';

const SidebarLayout = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === 'true';
  });
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('sidebarCollapsed', !collapsed);
  };

  return (
    <div className="flex min-h-screen h-full w-full bg-[#E9F9FF] font-jakarta">
      <Sidebar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
        <div
    className={`flex-grow transition-all h-full duration-300  ${collapsed ? 'ml-25' : 'ml-[280px]'}`}
  >
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;
