import React, { useEffect, useState } from 'react';
import WalletService from '../services/wallet';
import { useSelector } from 'react-redux';
import { TbLoader3 } from 'react-icons/tb';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ProductInfo = () => {
  const [products, setProducts] = useState([]);
  const [wirelessPlanType, setWirelessPlanType] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(date);
  }

  useEffect(() => {
    fetchProductsData();
  }, [wirelessPlanType]);

  const fetchProductsData = async () => {
    try {
      setLoading(true);
      const response = await new WalletService().getProductsData();
      setLoading(false);

      const updatedProductsData = [...response.tetherPlans, ...response.nonTetherPlans];

      // if (wirelessPlanType === 0) {
      //   updatedProductsData = response.filter((item) => item.teaserType === 'Custom Overage');
      // } else {
      //   updatedProductsData = response.filter((item) => item.teaserType !== 'Custom Overage');
      // }
      setProducts(updatedProductsData);
      setSelectedPlan(null);
    } catch (error) {
      console.error('Error while fetching products data', error);
    }
  };



  return (
    <div className="max-w-[1232px] w-full flex flex-col gap-4 items-start mx-auto p-6">
      <div className="flex justify-between items-center w-full">
        <h2 className="text-Neutral-900 text-2xl font-extrabold">Product Catalogue</h2>

        {user?.roleId?.name === 'Admin' && (
          <button
            onClick={()=>navigate('/add-product')}
            className="bg-Primary-400 text-black font-bold text-base text-center px-6 py-3 rounded-3xl">
           Add new product
          </button>
        )}
      </div>
      <div className="flex items-center gap-2">
        <div className="py-2 px-4 rounded-xl bg-Primary-100 flex gap-1 items-center">
          <p className="text-black font-bold">Family</p>
          <div className="h-4 w-4">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all text-Neutral-900 duration-200`}
            />
          </div>
        </div>
        <div className="py-2 px-4 rounded-xl bg-Primary-100 flex gap-1 items-center">
          <p className="text-black font-bold">Talk & Text</p>
          <div className="h-4 w-4">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all text-Neutral-900 duration-200`}
            />
          </div>
        </div>
        <div className="py-2 px-4 rounded-xl bg-Primary-100 flex gap-1 items-center">
          <p className="text-black font-bold">Approach</p>
          <div className="h-4 w-4">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all text-Neutral-900 duration-200`}
            />
          </div>
        </div>
        <div className="py-2 px-4 rounded-xl bg-Primary-100 flex gap-1 items-center">
          <p className="text-black font-bold">Billing</p>
          <div className="h-4 w-4">
            <MdKeyboardArrowDown
              className={`w-full h-full transition-all text-Neutral-900 duration-200`}
            />
          </div>
        </div>
      </div>

      {/* <div className="mb-4 flex items-center">
        <span className="font-bold text-lg mr-4">Wireless Plans</span>
        <div className="flex gap-4">
          <button
            className={`px-4 py-2 rounded ${
              wirelessPlanType === 0 ? 'bg-black text-white' : 'text-black'
            }`}
            onClick={() => setWirelessPlanType(0)}>
            Tether
          </button>
          <button
            className={`px-4 py-2 rounded ${
              wirelessPlanType === 1 ? 'bg-black text-white' : 'text-black'
            }`}
            onClick={() => setWirelessPlanType(1)}>
            No Tether
          </button>
        </div>
      </div> */}

      <div className="flex flex-col w-full gap-4">
        {products.map((product, index) => (
          <div
            key={product._id}
            className={`p-4 w-full flex gap-4  bg-white rounded-2xl cursor-pointer ${
              selectedPlan === index ? 'border-Primary-500 border-2' : ''
            }`}
            onClick={() => setSelectedPlan(index === selectedPlan ? null : index)}>
            <div className="flex flex-col gap-4 w-[25%]">
              <div className="flex flex-col gap-[2px]">
                <p className='text-Neutral-300 text-sm'>Family</p>
                <p className='text-Neutral-700'>{product.productFamily}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Name</p>
                <p className='text-Neutral-700 '>{product.offerName}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Creation Date</p>
                <p className='text-Neutral-700 '>{convertTimestampToDate(product.addedOn)}</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[25%]">
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Commercial name</p>
                <p className='text-Neutral-700 '>{product.displayName}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Commercial description</p>
              <p className='text-Neutral-700 '>Unlimited Talk </p>
              <p className='text-Neutral-700 '>Unlimited Text</p>
              <p className='text-Neutral-700 '> 5G Data</p>
              <p className='text-Neutral-700 '>Canada & Mexico</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[25%]">
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Size</p>
                <p className='text-Neutral-700 '>{product.size}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Wholesale price</p>
                <p className='text-Neutral-700 '>${product.amount}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Retail price</p>
                <p className='text-Neutral-700 '>${product.amount}</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[25%]">
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Talk & Text</p>
                <p className='text-Neutral-700 '>{product.serviceType}</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Approach</p>
                <p className='text-Neutral-700 '>Hard Cut-off</p>
              </div>
              <div className="flex flex-col gap-[2px]">
              <p className='text-Neutral-300 text-sm'>Billing</p>
                <p className='text-Neutral-700'>Flat</p>
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default ProductInfo;
