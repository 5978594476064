import React, { useState } from 'react';
import PopupComponent from '../common/popup';
import { MdClose } from 'react-icons/md';

function WithdrawRequestModal({ data, handleRequest, onClose }) {
  console.log('paymentPlatforms', data?.paymentPlatforms[0]?.paymentMethods);
  const bankData = data.paymentPlatforms[0].paymentMethods.find(
    (item) => item.paymentMethodType === 'Bank'
  );
  const zelleData = data?.paymentPlatforms[0]?.paymentMethods?.find(
    (item) => item.paymentMethodType === 'Zelle'
  );

  const handleWithdraw = (status, data) => {
    handleRequest(status, data);
    onClose();
  };

  return (
    <PopupComponent>
      {/* close button  */}
      <div className="w-full flex items-center justify-between">
        <p className="text-xl font-extrabold text-Neutral-900">Withdrawal request</p>
        <div className="ml-auto p-3  hover:cursor-pointer" onClick={onClose}>
          <MdClose className="w-6 h-6" />
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <p className="text-Neutral-500 text-base text-center">Amount</p>
        <p className="text-Neutral-900 text-2xl font-extrabold text-center">
          ${data?.amount || '__'}
        </p>
        <p className="text-Error-500 text-base text-center">
          Make sure you pay before tapping approve
        </p>
      </div>
      <div className="flex flex-col gap-2 w-full items-start">
        <div className="flex flex-col">
          <p className="text-Neutral-500 text-sm">Name</p>
          <p className="text-Neutral-900 text-base">{data?.user?.name || '__'}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-Neutral-500 text-sm">Email</p>
          <p className="text-Neutral-900 text-base">{data?.user?.email || '__'}</p>
        </div>
        <div className="p-2 lg:p-4 w-full bg-Primary-50 rounded-2xl flex flex-col gap-2">
          <div>
            <img src={'/account_balance.svg'} alt={'bank icon'} />
          </div>
          {bankData && (
            <div className="flex flex-col ">
              <p className="text-Neutral-500 text-sm">{bankData?.paymentMethodType} details</p>
              <p className="text-Neutral-900 text-base">{bankData?.bankDetails?.accountNumber}</p>
              <p className="text-Neutral-900 text-base">
                {bankData?.bankDetails?.routingNumber} ({bankData?.bankDetails?.bankName})
              </p>
            </div>
          )}
        </div>
        <div className=" p-2 lg:p-4 w-full bg-Primary-50 rounded-2xl flex flex-col gap-2">
          <div>
            <img src={'/account_balance.svg'} alt={'bank icon'} />
          </div>
          {zelleData && (
            <div className="flex flex-col ">
              <p className="text-Neutral-500 text-sm">{zelleData?.paymentMethodType} details</p>
              <p className="text-Neutral-900 text-base">{zelleData?.zelleDetails?.email}</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 w-full">
        <button
          className="py-3 px-6  text-center bg-Primary-400 rounded-3xl text-black text-base font-bold"
          onClick={() => handleWithdraw('approved', data)}>
          Pay & approve
        </button>
        <button
          className="py-3 px-6  text-center bg-Error-50 rounded-3xl text-Error-500 text-base font-bold"
          onClick={() => handleWithdraw('rejected', data)}>
          Reject request
        </button>
      </div>
    </PopupComponent>
  );
}

export default WithdrawRequestModal;
