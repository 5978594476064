import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardComponent from './dashboard';
import WalletService from '../services/wallet';
import Header from '../header/header';

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [dropdownQuery, setDropdownQuery] = useState('MDN');
  const [searchInput, setSearchInput] = useState('');
  const [responseData, setresponseData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAdminDashboardData = async () => {
    try {
      setLoading(true);
      const response = await new WalletService().getAdminDashboardData();
      setresponseData(response);
      console.log('response', response);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAuthorizedDashboardData = async () => {
    try {
      setLoading(true);
      const response = await new WalletService().getAuthorizedDashboardData();
      setresponseData(response);
      console.log('response', response);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    user?.roleId?.name === 'Admin' && fetchAdminDashboardData();
    user?.roleId?.name === 'Authorized Distributor' && fetchAuthorizedDashboardData();
  }, []);

  return (
    <div className="h-full w-full bg-[#E9F9FF]  font-jakarta">
      <div className="max-w-[1032px] mx-auto sticky top-0 mb-3 z-50">
        <Header
          dropdownQuery={dropdownQuery}
          setDropdownQuery={setDropdownQuery}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchLatestSubscribersData={() => console.log('fetchlatest subscriber is called')}
        />
      </div>
      <div className="w-full mx-auto max-w-[1280px] h-full mb-5">
        <DashboardComponent
          responseData={responseData}
          latestMdsData={responseData?.latestMD}
          latestAdsData={responseData?.latestAD}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Dashboard;
