import React from 'react';
import { FaCheck } from 'react-icons/fa6';

function Confirmation({ selectedTab, formData, setFormData, handleChange, setSelectedTab }) {
  return (
    <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
      <div className="w-full rounded-3xl bg-white p-4 flex flex-col gap-4">
        <p className="font-bold text-Neutral-700 text-base">EZ Plus</p>
        <p className="font-bold text-Primary-500 text-base">Unlimited</p>
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-start gap-1">
            <FaCheck />
            <p className="text-Neutral-300 font-normal text-base">Unlimited talk & text</p>
          </div>
          <div className="flex items-center justify-start gap-1">
            <FaCheck />
            <p className="text-Neutral-300 font-normal text-base">5G with auto pay</p>
          </div>
        </div>
        <div className="flex items-center justify-start ">
          <p className="font-bold text-Neutral-700 text-base">$53.99</p>
          <p className="text-neutral-300 text-base font-normal">/ month</p>
        </div>
        <div className="flex items-center justify-start ">
          <p className="font-bold text-Neutral-700 text-base">$48.50 with auto pay</p>
        </div>
      </div>
      <div className="w-full p-4 rounded-2xl flex-col justify-start items-start gap-4 inline-flex">
        <div className="self-stretch justify-start items-start gap-4 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Family
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                Airespring throttle
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Name
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                EZ-Cut-Off Data Only Device 10 GB
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Creation date
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                4 Oct 2024
              </div>
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Commercial name
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                EZ Mobile 10GB
              </div>
            </div>
            <div className="self-stretch  flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
                <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                  Commercial description
                </div>
                <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                  Unlimited Talk
                  <br />
                  Unlimited Text
                  <br />
                  5G Data
                  <br />
                  Canada & Mexico
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-4 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch  flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Size
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                10GB
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Wholesale price
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                $31.50
              </div>
            </div>
            <div className="self-stretch  flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Retail price
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                $47.00
              </div>
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
            <div className="self-stretch  flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Talk & Text
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                Unlimited
              </div>
            </div>
            <div className="self-stretch  flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Approach
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                Hard Cut-off
              </div>
            </div>
            <div className="self-stretch  flex-col justify-start items-start gap-0.5 flex">
              <div className="self-stretch text-[#a0a2a7] text-sm font-normal  leading-[21px]">
                Billing
              </div>
              <div className="self-stretch text-[#45474a] text-base font-normal  leading-normal">
                Flat
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center   mb-16 mt-auto">
        <div
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900 cursor-pointer"
          onClick={() => setSelectedTab((prev) => prev - 1)}>
          Back
        </div>
        <div
          className="flex py-3 px-8 justify-center items-center rounded-3xl bg-Primary-400 h-12 font-bold text-base text-white whitespace-nowrap  cursor-pointer"
        >
         Create product
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
