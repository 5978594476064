import React, { useState, useEffect, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Management from './management';
import Product from './product';
import Earnings from './earning';
import WithdrawMoneyComponent from './withdraw-money';
import InvitationValidation from './invitationLink/invitationPage';
import NotFound from './common/notFound';
import AdminLogin from './auth/admin-login';
import InitialSignup from './auth/initial-signup';
import NewAddADComponent from './onboarding';
import ProtectedRoutes from './auth/ProtectedRoutes';
import AddNewSubscriber from './add-new-subscriber';
import Profile from './profile';
import SidebarLayout from './sidebar/sidebarLayout';
import AddMoneyComponent from './user';
import AddNewDistributor from './add-new-distributor';
import SubscriberDetails from './subscriber-details';
import ViewAllInfo from './view-all-info';
import AddProduct from './add-product';
import DistributorDetails from './distributor-details/distributor-details';
import Dashboard from './dashboard';
import SuccessMessage from './message-pages/success-message';

const AppRoutes = () => {
  const user = useSelector((state) => state.user);

  return (
    <Suspense>
      <Routes>
        {/* Public routes */}
        <Route
          path="/"
          element={user?.isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
        />

        <Route path="/admin" element={<AdminLogin />} />

        {/*  */}
        <Route path="/sign-up" element={<InvitationValidation />} />
        <Route path="/login" element={<InitialSignup />} />
        <Route path="/add-subscriber" element={<AddNewSubscriber />} />
        <Route path="/sign-in" element={<InitialSignup type={'signup'} />} />

        {/* Protected routes */}

        <Route element={<SidebarLayout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/management"
            element={
              <ProtectedRoutes>
                <Management />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/subscriber-details/:selectedSubscriberMid"
            element={
              <ProtectedRoutes>
                <SubscriberDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/view-details"
            element={
              <ProtectedRoutes>
                <DistributorDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/view-all-info"
            element={
              <ProtectedRoutes>
                <ViewAllInfo />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoutes>
                <Profile />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/product"
            element={
              <ProtectedRoutes>
                <Product />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/earning"
            element={
              <ProtectedRoutes>
                <Earnings />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/add"
            element={
              <ProtectedRoutes>
                <AddNewDistributor />
              </ProtectedRoutes>
            }
          />
        </Route>
        {user?.roleId?.name === 'Admin' && (
          <Route
            path="/add-product"
            element={
              <ProtectedRoutes>
                <AddProduct />
              </ProtectedRoutes>
            }
          />
        )}
        {user?.roleId?.name === 'Authorized Distributor' && (
          <Route
            path="/add-money"
            element={
              <ProtectedRoutes>
                <AddMoneyComponent />
              </ProtectedRoutes>
            }
          />
        )}

        <Route
          path="/withdraw-money"
          element={
            <ProtectedRoutes>
              <WithdrawMoneyComponent />
            </ProtectedRoutes>
          }
        />

        {user?.roleId?.name === 'Authorized Distributor' && (
          <Route
            path="/ad"
            element={
              <ProtectedRoutes>
                <NewAddADComponent />
              </ProtectedRoutes>
            }
          />
        )}
        {user?.roleId?.name === 'Master Distributor' && (
          <Route
            path="/md"
            element={
              <ProtectedRoutes>
                <NewAddADComponent />
              </ProtectedRoutes>
            }
          />
        )}
        {('isApprove' in user && !user.isApprove && user.roleId?.name !== 'Master Distributor') &&  <Route path="pending-approval" element={<SuccessMessage />} /> }
       

        {/* Fallback route for not found pages */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
