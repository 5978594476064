import { useSelector } from "react-redux";

const EarningSection = ({ responseData ,showdata}) => {
    const user = useSelector((state) => state.user);

    return (
      <div className=" w-full h-full bg-white rounded-2xl">
        <div className="px-6  flex flex-col w-full">
          <div className="flex justify-between items-center py-6">
            <h3 className="text-xl text-Neutral-500 font-semibold">EZ Earnings</h3>
            <img
              src="/three-dots-vertical-icon.svg"
              alt="Three-Dots Icon"
              className="h-6 w-6 cursor-pointer hover:opacity-80"
            />
          </div>

          <div className="flex flex-col pt-3 pb-6 gap-6">
            {/* wallet & paid section  */}
            <div className="flex justify-between ">
              <div className="flex items-center gap-3">
                <img src="/earnings-wallet-icon.svg" alt="Wallet-Icon" />
                <div className="flex flex-col">
                  <h4 className="text-2xl text-Neutral-500 font-semibold">${responseData?.wallet || '_'}</h4>
                  <p className="text-sm text-Neutral-300 font-normal">Wallet</p>
                </div>
              </div>

              {user?.roleId?.name === 'Authorized Distributor' && (
                <div className="flex items-center gap-3">
                  <img src="/earnings-paid-icon.svg" alt="Wallet-Icon" />
                  <div className="flex flex-col">
                    <h4 className="text-2xl text-Neutral-500 font-semibold">${responseData?.paid || '_'}</h4>
                    <p className="text-sm text-Neutral-300 font-normal">Paid</p>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-3">
              {showdata?.map((item, index) => (
                <div className="flex justify-between items-center" key={index}>
                  <div className="flex flex-col">
                    <h4 className="text-xl text-Neutral-500 font-semibold">${item.amount}</h4>
                    <p className="text-sm text-Neutral-400 font-normal">{item.description}</p>
                  </div>

                  <div className="flex items-center gap-[2px]">
                    <img
                      src={
                        item.type === 'increase' ? './arrow-up-green.svg' : './arrow-down-red.svg'
                      }
                      alt="Arrow-Up Icon"
                      className="h-[24px] w-[24px]"
                    />
                    <p className="text-sm text-Neutral-400 font-normal">{item.percentage}%</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-between mt-auto items-center pt-3 pb-6">
            <p className="text-sm text-Neutral-300 font-normal">
              You have done 3.2% more sales. Check all your earning states here.
            </p>
            <img
              src="/chevron-right-icon-blue.svg"
              alt="Chevron-Right Icon"
              className="h-10 w-10 cursor-pointer hover:opacity-90"
            />
          </div>
        </div>
      </div>
    );
};
  export default EarningSection