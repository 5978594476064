import React from 'react';

function PopupComponent({ children }) {
  return (
    <div className="fixed inset-0 flex items-center font-jakarta justify-center bg-black bg-opacity-50 z-50">
      <div className="p-6 w-[30rem] bg-white rounded-3xl flex flex-col items-center gap-8 font-jakarta relative">
        {children}
      </div>
    </div>
  );
}

export default PopupComponent;
