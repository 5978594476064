import React from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';

function Commission({ selectedTab, formData, setFormData, handleChange, setSelectedTab }) {
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle heading={'Set commissions for distributors'} />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="ADCommission" className="text-Neutral-500">
              AD Commission
            </label>
            <div className="relative w-full">
              <input
                id="ADCommission"
                type="text"
                placeholder="Enter ad commission"
                value={formData.ADCommission}
                onChange={handleChange}
                className={`h-12 w-full bg-white focus:bg-white pl-4 py-1 pr-1 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
              />
              <div className="absolute flex items-center gap-1 right-1 top-1/2 transform -translate-y-1/2">
                {/* For $ symbol */}
                <div
                  className={`max-h-10 max-w-10 p-[14px] box-border rounded-lg text-base font-bold flex items-center justify-center text-center cursor-pointer ${
                    formData.ADCommissionType === '$'
                      ? 'bg-Neutral-900 text-white'
                      : 'bg-Neutral-50 text-Neutral-500'
                  }`}
                  onClick={() => setFormData({ ...formData, ADCommissionType: '$' })}>
                  $
                </div>
                {/* For % symbol */}
                <div
                  className={`max-h-10 max-w-10 box-border p-[14px] ml-1 rounded-lg text-base font-bold flex items-center justify-center text-center cursor-pointer ${
                    formData.ADCommissionType === '%'
                      ? 'bg-Neutral-900 text-white'
                      : 'bg-Neutral-50 text-Neutral-500'
                  }`}
                  onClick={() => setFormData({ ...formData, ADCommissionType: '%' })}>
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="MDCommission" className="text-Neutral-500">
              MD Commission
            </label>
            <div className="relative w-full">
              <input
                id="MDCommission"
                type="text"
                placeholder="Enter md commission"
                value={formData.MDCommission}
                onChange={handleChange}
                className={`h-12 w-full bg-white focus:bg-white pl-4 py-1 pr-1 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
              />
              <div className="absolute flex items-center gap-1 right-1 top-1/2 transform -translate-y-1/2">
                {/* For $ symbol */}
                <div
                  className={`max-h-10 max-w-10 p-[14px] box-border rounded-lg text-base font-bold flex items-center justify-center text-center cursor-pointer ${
                    formData.MDCommissionType === '$'
                      ? 'bg-Neutral-900 text-white'
                      : 'bg-Neutral-50 text-Neutral-500'
                  }`}
                  onClick={() => setFormData({ ...formData, MDCommissionType: '$' })}>
                  $
                </div>
                {/* For % symbol */}
                <div
                  className={`max-h-10 max-w-10 box-border p-[14px] ml-1 rounded-lg text-base font-bold flex items-center justify-center text-center cursor-pointer ${
                    formData.MDCommissionType === '%'
                      ? 'bg-Neutral-900 text-white'
                      : 'bg-Neutral-50 text-Neutral-500'
                  }`}
                  onClick={() => setFormData({ ...formData, MDCommissionType: '%' })}>
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 py-3 px-8 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default Commission;
