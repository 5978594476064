import React, { useRef, useState } from 'react';
import { MdOutlineFileUpload, MdOutlineDone, MdError, MdClose } from 'react-icons/md';

import { FaFile } from 'react-icons/fa6';
import UserService from '../services/user';
import { AddressOptions } from '../constants';
import Utils from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../slices/userSlice';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import Dropdown from '../common/onboarding/Dropdown';
import { TbLoader3 } from 'react-icons/tb';
import StepIndicator from '../common/StepIndicator';

function AddressDetails({
  selectedTab,
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  totalSteps = 5,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [initialFormData, setInitialFormData] = useState(formData);
  const [selectedType, setSelectedType] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState(formData.proofOfAddress);
  const [uploadedTypes, setUploadedTypes] = useState(
    formData.proofOfAddress.map((item) => item.type)
  );
  const [errors, setErrors] = useState({});

  const addressProofFileRef = useRef(null);

  const handleAddressProof = (selectedAddressProof) => {
    setSelectedType(selectedAddressProof);
  };

  const handleFileUpload = async (fileList) => {
    if (!selectedType || !fileList.length) return;

    // Check if the document type has already been uploaded
    if (uploadedTypes.includes(selectedType)) {
      console.log('You have already uploaded a document of this type.');

      Utils.failureToastMessage('You have already uploaded a document of this type.');
      return;
    }

    const file = fileList[0];
    const newUpload = {
      name: file.name,
      size: file.size,
      type: selectedType,
      status: 'uploading',
    };

    setUploadingFiles([...uploadingFiles, newUpload]);
    setUploadedTypes([...uploadedTypes, selectedType]); // Add to uploadedTypes

    const reqFormData = new FormData();
    reqFormData.append('fileName', file.name);
    reqFormData.append('files', file);
    reqFormData.append('userType', user?.roleId?.name === 'Admin' ? 'MD' : 'AD');

    try {
      const res = await new UserService().fileUpload(reqFormData);

      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.name === file.name
            ? { ...item, status: 'uploaded', documentLink: res?.cdnUrl }
            : item
        )
      );

      setFormData((prevData) => ({
        ...prevData,
        addressDetails: {
          ...prevData.addressDetails, // Keep existing address details
          proofOfAddress: [
            ...prevData.addressDetails.proofOfAddress, // Keep existing proof of address documents
            {
              type: selectedType,
              name: file.name,
              size: file.size,
              documentLink: res?.cdnUrl,
              status: 'uploaded',
            },
          ],
        },
      }));
      setErrors({});
    } catch (error) {
      setUploadingFiles((prevFiles) =>
        prevFiles.map((item) => (item.name === file.name ? { ...item, status: 'failed' } : item))
      );
      console.error('File upload failed:', error);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = uploadingFiles.filter((_, i) => i !== index);
    setUploadingFiles(updatedFiles);

    const updatedProofs = formData.proofOfAddress.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      addressDetails: {
        ...prevData.addressDetails, // Keep existing address details
        proofOfAddress: updatedProofs,
      },
    }));

    // Remove from uploadedTypes if file type is removed
    const removedType = formData.proofOfAddress[index]?.type;
    if (removedType) {
      setUploadedTypes(uploadedTypes.filter((type) => type !== removedType));
    }
    if (updatedProofs.length === 0) {
      setErrors({
        Document: 'Document is required',
      });
    } else {
      setErrors({});
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileUpload(event.dataTransfer.files);
  };

  const handleSubmit = async () => {
    setErrors({});
    const newErrors = {};
    if (formData.proofOfAddress.length === 0) {
      newErrors.Document = 'document is required';
    }
    // If there are errors, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      // No changes, don't call API
      console.log('No changes detected, skipping API call');
      setSelectedTab((prev) => prev + 1);
      return;
    }
    const updatedFormData = {
      userId: user?._id,
      proofOfAddress: formData?.proofOfAddress,
    };
    try {
      setLoading(true);
      const response = await new UserService().updateProfile(updatedFormData);
      console.log('response', response);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        setInitialFormData(formData);
        dispatch(updateUser(updatedUserData));
        setSelectedTab((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-10 lg:gap-12">
        <FormSectionTitle
          heading={'Proof of address'}
          subHeading={'Provide documents to proof your residence'}
        />
        <div className="flex flex-col gap-4">
          <Dropdown
            label="Type of document"
            placeHolder="Select your document type"
            selectedValue={selectedType}
            ShowCheckBox={true}
            options={AddressOptions}
            onSelect={handleAddressProof}
            isDisabledOption={(option) => uploadedTypes.includes(option)} // Disable if uploaded
            error={errors.Document}
          />

          <div
            className="h-149px w-full bg-white border border-solid border-[#DFE0E2] rounded-2xl flex flex-col gap-2 justify-center items-center cursor-pointer"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => addressProofFileRef.current.click()}>
            <MdOutlineFileUpload className="h-10 w-10 text-[#009DFF]" />
            <input
              type="file"
              hidden
              ref={addressProofFileRef}
              onChange={(e) => handleFileUpload(e.target.files)}
            />
            <h4 className="text-[#6C6F75] font-bold">Browse files</h4>
            <p className="text-[#A0A2A7]">Drag and drop files here</p>
          </div>
          {/* Uploaded, Failed, and Uploading Files */}
          {uploadingFiles.map((item, index) => (
            <div key={index} className="flex justify-between items-center rounded-xl gap-2 px-4">
              <div className="flex items-center gap-4">
                {item.status === 'Failed' ? (
                  <MdError className="text-Error-500  w-6 h-6" />
                ) : item.status === 'uploading' ? (
                  <FaFile className="text-Neutral-900 w-6 h-6" />
                ) : (
                  <MdOutlineDone className="text-Success-500 w-6 h-6" />
                )}
                <div className="flex flex-col items-start">
                  <p className="text-[#45474A]">{item.name}</p>
                  <p
                    className={`text-sm px-1 leading-5 ${item.status === 'failed' ? 'text-Error-500' : 'text-Neutral-300'}`}>
                    {item.status === 'uploading'
                      ? 'Uploading...'
                      : item.status === 'Failed'
                        ? 'Failed'
                        : ''}
                    {item.type} - {Math.ceil(item.size / 1000)} KB
                  </p>
                </div>
              </div>
              <MdClose
                className="text-[#6C6F75] cursor-pointer  w-6 h-6"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between items-center absolute w-[480px] bottom-[10%] lg:bottom-[8%]">
          <div className="lg:hidden">
            <StepIndicator currentStep={selectedTab} totalSteps={totalSteps} />
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              className="flex justify-center items-center rounded-3xl bg-white w-25 h-12 font-bold text-base text-Neutral-900"
              onClick={() => setSelectedTab((prev) => prev - 1)}>
              Back
            </button>
            <button
              className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
              onClick={handleSubmit}>
              {loading ? <TbLoader3 className="animate-spin text-black" /> : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressDetails;
