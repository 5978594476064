import React from 'react';
import CustomInputField from '../common/onboarding/CustomInputField ';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';

function CommercialDetails({ selectedTab, formData, setFormData, handleChange, setSelectedTab }) {
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle heading={'Provide commercial details'} />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="commercialName" className="text-Neutral-500">
              Commercial name
            </label>
            <input
              id="commercialName"
              type="text"
              placeholder="Enter commercial name"
              value={formData.commercialName}
              onChange={handleChange}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
          </div>
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="commercialDescription" className="text-Neutral-500">
              Commercial description
            </label>
            <input
              id="commercialDescription"
              type="text"
              placeholder="Enter commercial description"
              value={formData.commercialDescription}
              onChange={handleChange}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
          </div>
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="size" className="text-Neutral-500">
              Size
            </label>
            <input
              id="size"
              type="text"
              placeholder="Enter size"
              value={formData.size}
              onChange={handleChange}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border border-Neutral-100 outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
          </div>
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 py-3 px-8 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default CommercialDetails;
