import React from 'react'
import MessageWrapper from '../common/MessageWrapper'

function SuccessMessage() {
  return (
     <MessageWrapper
      heading={'Thanks for signing up.'}
      subheading={
        'We’ll approve and send you an invitation to submit your information for joining ezmobile.'
      }
      paragraph={"You can close this tab"}
    />
  )
}

export default SuccessMessage