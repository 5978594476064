import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';

const validateImei = (imei) => {
  const imeiRegex = /^\d{15}$/;
  return imeiRegex.test(imei);
};

function DeviceSimDetails({
  formData,
  setFormData,
  handleChange,
  setSelectedTab,
  fetchProductsData,
}) {
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Update formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    // Perform validation for IMEI
    if (id === 'IMEI') {
      if (!validateImei(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imei: 'IMEI must be a 15-digit number',
        }));
      } else {
        // Clear IMEI error if valid
        setErrors((prevErrors) => {
          const { imei, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Device & Sim details'}
          subHeading={'Provide details about the device and sim card'}
        />
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'IMEI'}
            labelText={'IMEI'}
            type={'number'}
            placeholder={'Enter your IMEI'}
            value={formData.IMEI}
            onChange={handleInputChange}
            error={errors.emei}
          />
          <CustomInputField
            id={'ICCID'}
            labelText={'ICCID'}
            type={'number'}
            placeholder={'Enter your ICCID'}
            value={formData.ICCID}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex bg-[#FFF7EB] rounded-2xl p-4 gap-4 items-center">
          <div className="p-3 rounded-full bg-[#FFE6C2] flex-shrink-0">
            <img src="/warning-icon.svg" alt="warning-icon" className="w-6 h-6" />
          </div>
          <p className="text-[#FF990A] flex-grow">
            IMEI can be found in the device by dialing *#06# ICCID can be found in the SIM card
          </p>
        </div>

        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => fetchProductsData()}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default DeviceSimDetails;
