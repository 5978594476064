import React, { useCallback, useEffect, useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import WalletService from '../services/wallet';
import { debounce } from '../utils/Debounce';

function SubscriptionDetails({ formData, setFormData, handleChange, setSelectedTab }) {
  const [eligibilityMessage, setEligibilityMessage] = useState('');
  const [isEligible, setIsEligible] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      oldNumber: value,
    }));
  };
  const debouncedPortInCheck = useCallback(
    debounce(async () => {
      try {
        const requestData = {
          msisdn: formData.oldNumber,
          zipCode: formData.zipCode,
        };
        const response = await new WalletService().portInCheck(requestData);

        if (response.success) {
          setEligibilityMessage('The number is eligible for port in');
          setIsEligible(true); // Number is portable, so set eligibility to true
        } else {
          setEligibilityMessage('The number is not eligible for port in');
          setIsEligible(false); // Number is not portable, so set eligibility to false
        }
      } catch (error) {
        console.error('Error checking eligibility', error);
        setEligibilityMessage('Error checking eligibility');
        setIsEligible(false);
      }
    }, 2000), // 2000ms debounce delay
    [formData.oldNumber, formData.zipCode]
  );

  // Trigger the debounced function when oldNumber changes
  useEffect(() => {
    if (formData.oldNumber) {
      debouncedPortInCheck();
    }
  }, [formData.oldNumber, debouncedPortInCheck]);
  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Select subscription options'}
          subHeading={'Choose between port-in an existing number or getting a new SIM'}
        />
        <div className="relative flex items-center justify-start gap-2 rounded-[3rem] bg-white mr-auto w-max">
          {/* Background slider */}
          <div
            className={`absolute top-0 bottom-0 left-0 w-1/2 bg-Neutral-900 rounded-[3rem] transition-all duration-300 ${formData.portingType === 'Port in' ? 'translate-x-full' : 'translate-x-0'}`}></div>

          {/* Buttons */}
          <button
            className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.portingType === 'New Sim' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => setFormData((prev) => ({ ...prev, portingType: 'New Sim' }))}>
            New Sim
          </button>
          <button
            className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.portingType === 'Port in' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
            onClick={() => setFormData((prev) => ({ ...prev, portingType: 'Port in' }))}>
            Port in
          </button>
        </div>

        {formData.portingType === 'Port in' && (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start gap-2">
              <label htmlFor="oldNumber" className="text-Neutral-500">
                Old number
              </label>
              <input
                id="oldNumber"
                type="text"
                placeholder="Enter your old number"
                value={formData.oldNumber}
                onChange={handleInputChange}
                className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border
                  ${
                    isEligible === null
                      ? 'border-Neutral-100' // Default border color
                      : isEligible
                        ? 'border-Success-400 focus:border-Success-400' // Green border if eligible
                        : 'border-Error-300 focus:border-Error-300' // Red border if not eligible
                  }
                outline-none text-Neutral-900 focus:border-2`}
              />
              {/* Display eligibility message */}
              {eligibilityMessage && (
                <p className={`text-sm mt-2 ${isEligible ? 'text-Success-600' : 'text-Error-500'}`}>
                  {eligibilityMessage}
                </p>
              )}
            </div>
          </div>
        )}
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button
            className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => setSelectedTab((prev) => prev + 1)}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default SubscriptionDetails;
