import React, { useState, useMemo, useCallback } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const Calendar = ({ currentDate: propCurrentDate, setCurrentDate }) => {
  const today = new Date();

  // If propCurrentDate is undefined or an empty string, fallback to today
  const currentDate = propCurrentDate ? new Date(propCurrentDate) : today;

  const [view, setView] = useState("date");
  const [viewedDate, setViewedDate] = useState(currentDate);

  const currentYear = viewedDate.getFullYear();
  const currentMonth = viewedDate.getMonth();

  // Calendar label based on view
  const calendarLabel = useMemo(() => {
    if (view === "date") {
      return `${viewedDate.toLocaleString("default", {
        month: "long",
      })} ${currentYear}`;
    } else if (view === "month") {
      return currentYear;
    } else {
      const startYear = Math.floor(currentYear / 12) * 12;
      return `${startYear} - ${startYear + 11}`;
    }
  }, [view, viewedDate, currentYear]);

  const daysInCurrentMonth = useMemo(
    () => new Date(currentYear, currentMonth + 1, 0).getDate(),
    [currentYear, currentMonth]
  );
  const daysInPrevMonth = useMemo(
    () => new Date(currentYear, currentMonth, 0).getDate(),
    [currentYear, currentMonth]
  );
  const firstDayOfMonth = useMemo(
    () => new Date(currentYear, currentMonth, 1).getDay(),
    [currentYear, currentMonth]
  );
  const lastDayOfMonth = useMemo(
    () => new Date(currentYear, currentMonth, daysInCurrentMonth).getDay(),
    [currentYear, currentMonth, daysInCurrentMonth]
  );
  const leadingDays = firstDayOfMonth;
  const trailingDays = 6 - lastDayOfMonth;

  // Create the days array and memoize for performance
  const days = useMemo(() => {
    const daysArray = [];
    // Add previous month's trailing days
    for (let i = leadingDays - 1; i >= 0; i--) {
      daysArray.push({ day: daysInPrevMonth - i, isCurrentMonth: false });
    }
    // Add current month's days
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      const dayDate = new Date(currentYear, currentMonth, day);
      daysArray.push({
        day,
        isCurrentMonth: true,
        isToday: dayDate.toDateString() === today.toDateString(),
        isSelected: dayDate.toDateString() === currentDate.toDateString(),
        isFuture: dayDate > today,
      });
    }
    // Add next month's leading days
    for (let day = 1; day <= trailingDays; day++) {
      daysArray.push({ day, isCurrentMonth: false });
    }
    return daysArray;
  }, [
    leadingDays,
    trailingDays,
    daysInCurrentMonth,
    daysInPrevMonth,
    currentYear,
    currentMonth,
    today,
    currentDate,
  ]);

  // Handlers using useCallback for memoization
  const handleDayClick = useCallback(
    (e) => {
      const day = Number(e.target.dataset.day);
      if (!isNaN(day)) {
        const selectedDate = new Date(currentYear, currentMonth, day);
        if (selectedDate <= today) {
          setCurrentDate(selectedDate);
        }
      }
    },
    [currentYear, currentMonth, today, setCurrentDate]
  );

  const handleMonthClick = useCallback(
    (e) => {
      const month = Number(e.target.dataset.month);
      if (!isNaN(month)) {
        const selectedMonthDate = new Date(currentYear, month);
        if (selectedMonthDate <= today) {
          setViewedDate(selectedMonthDate);
          setView("date");
        }
      }
    },
    [currentYear, today]
  );

  const handleYearClick = useCallback(
    (e) => {
      const year = Number(e.target.dataset.year);
      if (!isNaN(year)) {
        const selectedYearDate = new Date(year, 0);
        if (selectedYearDate <= today) {
          setViewedDate(selectedYearDate);
          setView("month");
        }
      }
    },
    [today]
  );

  // Centralized header click handler
  const handleHeaderClick = useCallback(() => {
    setView((prevView) =>
      prevView === "date" ? "month" : prevView === "month" ? "year" : "date"
    );
  }, []);

  // Centralized render handler
  const renderBody = useMemo(() => {
    if (view === "date") {
      return (
        <div className="grid grid-cols-7 gap-2" onClick={handleDayClick}>
          {days.map((dayData, i) => (
            <div
              key={i}
              data-day={dayData.day}
              className={`w-10 h-10 flex justify-center items-center rounded-xl transition-colors duration-200
                ${dayData.isSelected ? "bg-Primary-400 font-bold" : ""}
                ${
                  dayData.isFuture
                    ? "text-Neutral-200 cursor-not-allowed"
                    : dayData.isCurrentMonth
                    ? "text-Neutral-900 cursor-pointer hover:bg-Primary-100"
                    : "text-Neutral-200"
                }`}
            >
              {dayData.day}
            </div>
          ))}
        </div>
      );
    } else if (view === "month") {
      return (
        <div
          className="grid grid-cols-3 gap-2 w-[296px] h-[216px] my-4 mx-auto"
          onClick={handleMonthClick}
        >
          {Array.from({ length: 12 }).map((_, month) => {
            const monthDate = new Date(currentYear, month);
            const isFuture = monthDate > today;
            const isSelectedMonth =
              currentDate.getFullYear() === currentYear &&
              currentDate.getMonth() === month;
            return (
              <div
                key={month}
                data-month={month}
                className={`flex justify-center capitalize items-center rounded-3xl ${
                  isSelectedMonth
                    ? "bg-Primary-400 font-bold"
                    : "hover:bg-Primary-100"
                } ${
                  isFuture
                    ? "cursor-not-allowed text-Neutral-200"
                    : "cursor-pointer text-neutral-900"
                }`}
              >
                {new Date(0, month).toLocaleString("default", {
                  month: "short",
                })}
              </div>
            );
          })}
        </div>
      );
    } else if (view === "year") {
      const startYear = Math.floor(currentYear / 12) * 12;
      return (
        <div
          className="grid grid-cols-3 gap-2 w-[304px] h-[216px] my-4 mx-auto"
          onClick={handleYearClick}
        >
          {Array.from({ length: 12 }).map((_, i) => {
            const year = startYear + i;
            const isFuture = year > today.getFullYear();
            const isSelectedYear = currentDate.getFullYear() === year;
            return (
              <div
                key={year}
                data-year={year}
                className={`flex items-center justify-center rounded-3xl ${
                  isSelectedYear
                    ? "bg-Primary-400 text-Neutral-900 font-bold"
                    : "hover:bg-Primary-100"
                } ${
                  isFuture
                    ? "cursor-not-allowed text-Neutral-200"
                    : "cursor-pointer"
                }`}
              >
                {year}
              </div>
            );
          })}
        </div>
      );
    }
  }, [
    view,
    handleDayClick,
    handleMonthClick,
    handleYearClick,
    days,
    currentDate,
    currentYear,
    today,
  ]);

  const Header = () => {
    const isNextDisabled = useMemo(() => {
      if (view === "date") {
        // In date view, disable the button if viewed month is the same as the current month and year
        return (
          currentYear === today.getFullYear() &&
          currentMonth >= today.getMonth()
        );
      } else if (view === "month") {
        // In month view, disable the button if viewed year is the same as the current year
        return currentYear >= today.getFullYear();
      } else if (view === "year") {
        // In year view, disable the button if viewed year range includes the current year
        const startYear = Math.floor(currentYear / 12) * 12;
        return startYear + 12 > today.getFullYear();
      }
    }, [view, currentYear, currentMonth, today]);

    return (
      <div className="flex h-12 justify-between items-center">
        {/* Previous Button */}
        <div
          className="p-3 cursor-pointer"
          onClick={() =>
            view === "date"
              ? setViewedDate(
                  new Date(viewedDate.setMonth(viewedDate.getMonth() - 1))
                )
              : setViewedDate(
                  new Date(
                    viewedDate.setFullYear(
                      viewedDate.getFullYear() - (view === "year" ? 12 : 1)
                    )
                  )
                )
          }
        >
          <MdOutlineKeyboardBackspace className="w-6 h-6" />
        </div>

        {/* Calendar Label */}
        <div onClick={handleHeaderClick} className="cursor-pointer font-bold">
          {calendarLabel}
        </div>

        {/* Next Button */}
        <div
          className={`p-3 ${
            isNextDisabled
              ? "cursor-not-allowed text-gray-400"
              : "cursor-pointer"
          }`}
          onClick={() => {
            if (!isNextDisabled) {
              view === "date"
                ? setViewedDate(
                    new Date(viewedDate.setMonth(viewedDate.getMonth() + 1))
                  )
                : setViewedDate(
                    new Date(
                      viewedDate.setFullYear(
                        viewedDate.getFullYear() + (view === "year" ? 12 : 1)
                      )
                    )
                  );
            }
          }}
        >
          <MdOutlineKeyboardBackspace className="rotate-180 w-6 h-6" />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white font-jakarta rounded-2xl shadow-lg p-3 flex flex-col gap-2">
      <Header />
      {view === "date" && (
        <div className="flex justify-between items-center gap-2 h-9 cursor-not-allowed">
          {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map((day, i) => (
            <div
              key={i}
              className="uppercase text-[13px] font-normal leading-[19.5px] tracking-[0.1em] text-Neutral-300"
            >
              {day}
            </div>
          ))}
        </div>
      )}
      {renderBody}
    </div>
  );
};

export default Calendar;
