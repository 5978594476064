import React, { useState } from 'react';
import { OnboardingInputMaxLength } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../slices/userSlice';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';
import UserService from '../services/user';
import { toast } from 'react-toastify';

function EditWireDetails({ formData, setFormData, setSelectedTab }) {
  const [initialFormData, setInitialFormData] = useState(formData);
  const [loading, setLoading] = useState(false);

  const {
    wireTransferRecipientName,
    wireTransferBankName,
    wireTransferAccountNumber,
    wireTransferRoutingNumber,
    wireTransferBankAddress,
    iban,
    bicCode,
  } = OnboardingInputMaxLength.wireTransferDetails;

  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
    const handlewireTransferMethodType = (value) => {
      console.log("value",value);
      
    setFormData((prev) => ({
      ...prev,
      paymentDetails: {
        ...prev.paymentDetails,
        wireTransferMethodType: value,
      },
    }));
    setErrors({});
  };

  const handleInputChange = (field, name) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      paymentDetails: {
        ...prevData.paymentDetails,
        [field]: value,
      },
    }));

    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${name} is required`, // Set error if the input is empty
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null, // Clear the specific error
      }));
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    const isDomestic = formData.wireTransferMethodType === 'Domestic';

    // Common fields for both Domestic and International
    if (!formData.wireTransferRecipientName.trim()) {
      newErrors.wireTransferRecipientName = "Recipient's name is required";
    }
    if (!formData.wireTransferBankName.trim()) {
      newErrors.wireTransferBankName = 'Bank name is required';
    }

    // Conditional validation for Domestic transfer
    if (isDomestic) {
      if (!formData.wireTransferAccountNumber.trim()) {
        newErrors.wireTransferAccountNumber = 'Bank account number is required';
      }
      if (!formData.wireTransferRoutingNumber.trim()) {
        newErrors.wireTransferRoutingNumber = 'Routing number is required';
      }
    } else {
      // Conditional validation for International transfer
      if (!formData.wireTransferBankAddress.trim()) {
        newErrors.wireTransferBankAddress = 'Bank address is required';
      }
      if (!formData.iban.trim()) {
        newErrors.iban = 'IBAN is required';
      }
      if (!formData.bicCode.trim()) {
        newErrors.bicCode = 'Bank SWIFT/BIC code is required';
      }
    }

    // If there are errors, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      // No changes, don't call API
      setSelectedTab(0);
      return;
    }
    const wireTransferDetails =
      formData.wireTransferMethodType === 'Domestic'
        ? {
            domesticDetails: {
              recipientName: formData.wireTransferRecipientName,
              bankName: formData.wireTransferBankName,
              accountNumber: formData.wireTransferAccountNumber,
              routingNumber: formData.wireTransferRoutingNumber,
            },
          }
        : {
            internationalDetails: {
              recipientName: formData.wireTransferRecipientName,
              bankName: formData.wireTransferBankName,
              bankAddress: formData.wireTransferBankAddress,
              iban: formData.iban,
              bicCode: formData.bicCode,
            },
          };

    const updatedFormData = {
      userId: user?._id,
      paymentMethods: [
        {
          paymentMethodType: 'Wire Transfer',
          wireTransferDetails: {
            wireTransferMethodType: formData.wireTransferMethodType,
            ...wireTransferDetails,
          },
        },
      ],
    };

    try {
      setLoading(true);
      const response = await new UserService().updatePaymentDetails(updatedFormData);
      const updatedUserData = { ...user, ...updatedFormData, isPaymentAdded: true };
      if (response) {
        setInitialFormData(formData);
        dispatch(updateUser(updatedUserData));
        setSelectedTab(0);
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" max-w-[480px] w-full flex flex-col gap-8 mx-auto  mt-5">
      <FormSectionTitle
        heading={'Edit your Wire information'}
     
      />

      <div className="relative flex items-center justify-start gap-2 rounded-[3rem] bg-white mr-auto w-max">
        {/* Background slider */}
        <div
          className={`absolute top-0 bottom-0 left-0 w-1/2 bg-Neutral-900 rounded-[3rem] transition-all duration-300 ${formData.wireTransferMethodType === 'International' ? 'translate-x-full' : 'translate-x-0'}`}></div>

        {/* Buttons */}
        <button
          className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.wireTransferMethodType === 'Domestic' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
          onClick={() => handlewireTransferMethodType('Domestic')}>
          Domestic
        </button>
        <button
          className={`relative py-3 px-8 rounded-[27px] transition-all duration-300 z-10 ${formData.wireTransferMethodType === 'International' ? 'text-Primary-400 font-bold' : 'text-Neutral-500'}`}
          onClick={() => handlewireTransferMethodType('International')}>
          International
        </button>
      </div>
      {formData.wireTransferMethodType === 'Domestic' ? (
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'wireTransferRecipientName'}
            labelText={'Recipient’s name'}
            maxLength={wireTransferRecipientName}
            type={'text'}
            placeholder={"Enter your recipient's name"}
            value={formData.wireTransferRecipientName}
            onChange={handleInputChange('wireTransferRecipientName', 'Recipient’s name')}
            error={errors.wireTransferRecipientName}
          />
          <CustomInputField
            id={'wireTransferBankName'}
            labelText={'Bank name'}
            maxLength={wireTransferBankName}
            type={'text'}
            placeholder={'Enter your bank name'}
            value={formData.wireTransferBankName}
            onChange={handleInputChange('wireTransferBankName', 'Bank name')}
            error={errors.wireTransferBankName}
          />
          <CustomInputField
            id={'wireTransferAccountNumber'}
            labelText={'Bank account number'}
            maxLength={wireTransferAccountNumber}
            type={'number'}
            placeholder={'Enter your bank account number'}
            value={formData.wireTransferAccountNumber}
            onChange={handleInputChange('wireTransferAccountNumber', 'Bank account number')}
            error={errors.wireTransferAccountNumber}
          />
          <CustomInputField
            id={'wireTransferRoutingNumber'}
            labelText={'Routing number'}
            maxLength={wireTransferRoutingNumber}
            type={'number'}
            placeholder={'Enter your routing number'}
            value={formData.wireTransferRoutingNumber}
            onChange={handleInputChange('wireTransferRoutingNumber', 'Routing number')}
            error={errors.wireTransferRoutingNumber}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'wireTransferRecipientName'}
            labelText={'Recipient’s name'}
            maxLength={wireTransferRecipientName}
            type={'text'}
            placeholder={"Enter your recipient's name"}
            value={formData.wireTransferRecipientName}
            onChange={handleInputChange('wireTransferRecipientName', 'Recipient’s name')}
            error={errors.wireTransferRecipientName}
          />
          <CustomInputField
            id={'wireTransferBankName'}
            labelText={'Bank name'}
            maxLength={wireTransferBankName}
            type={'text'}
            placeholder={'Enter your bank name'}
            value={formData.wireTransferBankName}
            onChange={handleInputChange('wireTransferBankName', 'Bank name')}
            error={errors.wireTransferBankName}
          />
          <CustomInputField
            id={'wireTransferBankAddress'}
            labelText={'Bank address'}
            maxLength={wireTransferBankAddress}
            type={'text'}
            placeholder={'Enter your bank address'}
            value={formData.wireTransferBankAddress}
            onChange={handleInputChange('wireTransferBankAddress', 'Bank address')}
            error={errors.wireTransferBankAddress}
          />
          <CustomInputField
            id={'iban'}
            labelText={'IBAN'}
            maxLength={iban}
            type={'text'}
            placeholder={'Enter your IBAN'}
            value={formData.iban}
            onChange={handleInputChange('iban', 'IBAN')}
            error={errors.iban}
          />
          <CustomInputField
            id={'bicCode'}
            labelText={'Bank SWIFT/BIC code'}
            maxLength={bicCode}
            type={'text'}
            placeholder={'Enter your BIC code'}
            value={formData.bicCode}
            onChange={handleInputChange('bicCode', 'Bank SWIFT/BIC code')}
            error={errors.bicCode}
          />
        </div>
      )}
      <div className="flex justify-between items-center  mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Error-500"
          onClick={() => setSelectedTab(0)}>
          Cancel
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default EditWireDetails;
