import React, { useState } from 'react';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';

function PersonalDetails({ selectedTab, formData, setFormData, handleChange, setSelectedTab }) {
  const [errors, setErrors] = useState({});

  // Email validation function
  const validateEmail = (email) => {
    const gmailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return gmailPattern.test(email);
  };

  // Modified handleChange function to include email validation
  const handleInputChange = (field, name) => (e) => {
    const value = e.target.value;

    // Update formData state
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Initialize error state for the specific field
    let error = null;

    // Check if the field is empty
    if (!value.trim()) {
      error = `${name} is required`;
    } else if (field === 'email') {
      // If field is email, perform extra validation for a valid Gmail format
      const gmailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
      if (!gmailPattern.test(value)) {
        error = 'Please enter a valid Gmail address';
      }
    }

    // Update the errors state with the new error (or clear if valid)
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error, // Set specific error for the field, or clear if no error
    }));
  };

  const handleNext = () => {
    const newErrors = {};

    // Check if required fields are empty
    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
    }
    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
    }
    if (!formData.contactNumber) {
      newErrors.contactNumber = 'Contact number is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid Gmail address';
    }

    // If there are errors, set them; otherwise, proceed to the next tab
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSelectedTab((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-10 xxl:gap-12 w-full max-w-[480px] h-full">
        <FormSectionTitle
          heading={'Subscriber’s Personal details'}
          subHeading={'Enter basic information about the new subscriber.'}
        />
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'firstName'}
            labelText={'First name'}
            type={'text'}
            placeholder={'Enter your first name'}
            value={formData.firstName}
            onChange={handleInputChange('firstName', 'firstName')}
            error={errors?.firstName}
          />
          <CustomInputField
            id={'lastName'}
            labelText={'Last name'}
            type={'text'}
            placeholder={'Enter your last name'}
            value={formData.lastName}
            onChange={handleInputChange('lastName', 'lastName')}
            error={errors?.lastName}
          />
          <CustomInputField
            id={'contactNumber'}
            labelText={'Contact number'}
            type={'number'}
            placeholder={'Enter your contact number'}
            value={formData.contactNumber}
            onChange={handleInputChange('contactNumber', 'Contact number')}
            error={errors?.contactNumber}
          />
          <div className="flex flex-col items-start gap-2">
            <label htmlFor="email" className="text-Neutral-500">
              Email
            </label>
            <input
              id="email"
              type="text"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange('email', 'Email')}
              className={`h-12 w-full bg-white focus:bg-white px-4 py-4.5 rounded-xl border ${errors?.email ? 'border-Error-500' : 'border-Neutral-100'} outline-none text-Neutral-900 focus:border-2 focus:border-Primary-500`}
            />
            {errors?.email && (
              <p className="text-Error-500 text-sm first-letter:capitalize">{errors?.email}</p>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center   mb-16 mt-auto">
          <button className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Neutral-900">
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={() => handleNext()}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
