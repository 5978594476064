import React from 'react';
import { TbLoader3 } from 'react-icons/tb';
import { formatTimestamp } from '../utils/dateUtils';

function AdTable({  showTabledata, loading }) {
  return (
    <div className="flex h-full w-full flex-col bg-white rounded-2xl px-2">
      <div className=" w-full p-6 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-Neutral-500">Latest subscribers </h2>

        <button
          className="pl-4 py-3 pr-3 bg-Primary-50 rounded-3xl flex justify-center items-center gap-3 cursor-pointer hover:bg-opacity-90"
         >
          <p className="text-base text-Primary-500 font-semibold">Export</p>
          <img src="./chevron-down-blue.svg" alt="Chevron-Down Icon" className="h-4 w-4" />
        </button>
      </div>

      <table className="w-full px-2 table-fixed relative border-collapse">
        <thead className="">
          <tr className="text-Neutral-700 uppercase border-b border-Neutral-50">
            {['MDN', 'Plan', 'Creation date', 'Billing cycle'].map((item) => (
              <th className="text-left text-xs w-[25%]  p-4 font-medium cursor-pointer">{item}</th>
            ))}
          </tr>
        </thead>
        {loading ? (
          <div className="flex justify-center items-center h-80">
            <TbLoader3 className="animate-spin text-black h-13 w-13  absolute left-45per top-45per" />
          </div>
        ) : (
          <tbody>
            {showTabledata?.length > 0 ? (
              showTabledata.map((distributor, index) => (
                <tr key={index} className="hover:cursor-pointer rounded-2xl hover:bg-Primary-50">
                  <td className="p-4 text-Neutral-700 text-base">{distributor?.mdn || '__'}</td>

                  <td className="p-4 text-Neutral-700 text-base">{distributor.Plan || '__'}</td>

                  <td className="p-4 text-Neutral-700 text-base">
                    {formatTimestamp(distributor?.addedOn) || '__'}
                  </td>
                  <td className="p-4 text-Neutral-700 text-base">
                    {formatTimestamp(distributor?.Billingcycle) || '__'}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-3">
                  'No data found'
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default AdTable;
