import React, { useCallback, useEffect, useState, useRef } from 'react';
import { calculateAge, formatDate, parseDate } from '../utils/dateUtils';
import Calendar from '../utils/Calender';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import { GenderOptions } from '../constants';
import CustomInputField from '../common/onboarding/CustomInputField ';
import { IoMdCalendar } from 'react-icons/io';
import Dropdown from '../common/onboarding/Dropdown';
import UserService from '../services/user';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../slices/userSlice';
import { toast } from 'react-toastify';

function EditPersonalDetails({ formData, setFormData, setSelectedTab }) {
  const user = useSelector((state) => state.user);
const dispatch=useDispatch()

  const [errors, setErrors] = useState({});
  const [initialFormData, setInitialFormData] = useState(formData);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const handleInputChange = (field, name) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        [field]: value,
      },
    }));
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${name} is required`, // Set the error if the input is empty
      }));
    } else {
      // Clear the specific error for the input being changed
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null, // Clear the error for this specific field
      }));
    }
  };

  const handleGender = (selectedGender) => {
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        gender: selectedGender,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      gender: selectedGender ? null : 'Gender is required',
    }));
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalendarOpen(false); // Close the calendar if clicked outside
      }
    },
    [calendarRef]
  );

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup on unmount
    };
  }, [calendarOpen, handleClickOutside]);

  const handleDateSelection = (selectedDate) => {
    const age = calculateAge(selectedDate);
    if (age < 18) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "DOB can't be less than 18 years",
      }));
      return; // Stop further execution if age is invalid
    }
    const formattedDate = formatDate(selectedDate, 'dd-MM-yyyy');

    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails,
        dob: formattedDate,
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      dob: null,
    }));
    setDobInput(formattedDate); // Update local input state
    setCalendarOpen(false);
  };

  const handleDateInputChange = (e) => {
    let value = e.target.value;

    // Allow only numbers and dashes to be entered, and prevent other characters
    const regex = /^[0-9-]*$/;

    // If input does not match the regex, return and do not update the state
    if (!regex.test(value)) {
      return;
    }

    // Restrict input to a maximum length of 10 characters (mm-dd-yyyy)
    if (value.length > 10) {
      return;
    }

    // Validate format (dd-mm-yyyy) when the input reaches 10 characters
    if (value.length === 10) {
      const isValidFormat = /^\d{2}-\d{2}-\d{4}$/.test(value);
      if (!isValidFormat) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: 'Invalid date format. Use mm-dd-yyyy.',
        }));
        return; // Do not update the state if the format is invalid
      } else {
        const age = calculateAge(value);
        if (age < 18) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dob: "DOB can't be less than 18 years",
          }));
          return;
        }

        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: null, // Clear the error if the format is valid
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: 'Please enter the date in mm-dd-yyyy format.',
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      personalDetails: {
        ...prevData.personalDetails, // Spread the existing personal details
        dob: value, // Update gender with the new value
      },
    }));
    // Set the value back to state if it matches the expected input
    setDobInput(value);
  };

  const validateAllFields = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = 'Invalid email';
    if (!formData.phone || !/^\d{10}$/.test(formData.phone))
      newErrors.phone = 'Phone must be 10 digits';
    if (!formData.postalCode || !/^\d{5}$/.test(formData.postalCode))
      newErrors.postalCode = 'Postal code must be 5 digits';
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!formData.dob) newErrors.dob = 'DOB is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateAllFields()) {
      return;
    }
    const parsedDate = parseDate(dobInput, 'dd-MM-yyyy');
    const formattedDate = formatDate(parsedDate, 'yyyy-MM-dd');
    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      // No changes, don't call API
      setSelectedTab((prev) => prev + 1);
      return;
    }
    const updatedFormData = {
      userId: user._id,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      gender: formData.gender,
      dob: formattedDate,
      postalCode: formData.postalCode,
    };
    try {
      // setLoading(true);
      const response = await new UserService().updateFirstTimeProfile(updatedFormData);
      const updatedUserData = { ...user, ...updatedFormData };

      if (response) {
        dispatch(updateUser(updatedUserData));
        setInitialFormData(formData);
        setSelectedTab(0)
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      // setLoading(false);
   
    }
  };

  const dateFromString = new Date(formData?.dob?.split('T')[0]);
  const formattedDate = formatDate(dateFromString, 'dd-MM-yyyy');
  const [dobInput, setDobInput] = useState(formattedDate || '');
  const dateObject = parseDate(formData.dob, 'dd-MM-yyyy') || new Date();

  return (
    <div className=" max-w-[480px] w-full flex flex-col gap-8 mx-auto  mt-5">
      <FormSectionTitle heading={'Edit personal details'} />
      <div className="flex flex-col gap-8">
        <CustomInputField
          id={'name'}
          labelText={'Name'}
          type={'text'}
          placeholder={'Enter your name'}
          value={formData.name}
          onChange={handleInputChange('name', 'name')}
        />
        <CustomInputField
          id={'email'}
          labelText={'Email'}
          type={'text'}
          placeholder={'Enter your email'}
          value={formData.email}
          onChange={handleInputChange('email', 'email')}
        />
        <CustomInputField
          id={'phone'}
          labelText={'Phone'}
          type={'text'}
          placeholder={'Enter your phone'}
          value={formData.phone}
          onChange={handleInputChange('phone', 'name')}
        />
        <Dropdown
          label="Gender"
          placeHolder="Select your gender"
          selectedValue={formData.gender}
          options={GenderOptions}
          onSelect={handleGender}
        />

        <div className="flex flex-col gap-2 relative" ref={calendarRef}>
          <label htmlFor="dob" className="text-Neutral-500">
            Date of Birth
          </label>

          <div className="flex flex-col items-start w-full">
            <div
              className={`flex items-center h-12 bg-white w-full px-4 py-4.5 rounded-xl  border border-Neutral-100`}>
              <input
                type="text"
                id="dob"
                placeholder="DD-MM-YYYY"
                value={dobInput}
                onChange={handleDateInputChange}
                className="fl  w-full bg-white focus:bg-white  outline-none  text-Neutral-900"
              />
              <IoMdCalendar
                className="h-6 w-6 ml-2 cursor-pointer"
                onClick={() => setCalendarOpen(!calendarOpen)}
              />
            </div>
            {errors?.dob && <p className="text-Error-500 text-sm">{errors?.dob}</p>}
          </div>

          {calendarOpen && (
            <div className="absolute bottom-[10px] right-0 z-10 mt-2">
              <Calendar
                currentDate={dateObject} // Pass the native Date object to the Calendar
                setCurrentDate={handleDateSelection}
              />
            </div>
          )}
        </div>
        <CustomInputField
          id={'postalCode'}
          labelText={'Postal code'}
          type={'number'}
          placeholder={'Enter your postal code'}
          value={formData.postalCode}
          onChange={handleInputChange('postalCode', 'Postal code')}
          error={errors?.postalCode}
        />
      </div>
      <div className="flex justify-between items-center  mb-16 mt-auto">
        <button
          className="flex justify-center items-center rounded-3xl bg-white py-3 px-8 font-bold text-base text-Error-500"
          onClick={() => setSelectedTab(0)}>
          Cancel
        </button>
        <button
          className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
          onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default EditPersonalDetails;
