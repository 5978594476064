import React, { useState, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import UserService from '../services/user';
import { MdCheckBoxOutlineBlank, MdDelete, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {  updateUser } from '../slices/userSlice';
import { toast } from 'react-toastify';
import { MdOutlineFileUpload } from 'react-icons/md';
import FormSectionTitle from '../common/onboarding/FormSectionTitle';
import CustomInputField from '../common/onboarding/CustomInputField ';
import { useNavigate } from 'react-router-dom';
import { IoIosCheckbox } from 'react-icons/io';
import StepIndicator from '../common/StepIndicator';
import { TbLoader3 } from 'react-icons/tb';
import { OnboardingInputMaxLength } from '../constants';

const Declaration = ({
  formData,
  setFormData,
  handleChange,
  selectedTab,
  setSelectedTab,
  totalSteps = 5,
}) => {
  const [imageURL, setImageURL] = useState(formData?.signature ? formData?.signature : null);
  const [showSignaturePlaceholder, setShowSignaturePlaceholder] = useState(true);
  const [errors, setErrors] = useState({});

  const {  signatureName } = OnboardingInputMaxLength.declarationDetails;


  const sigCanvas = useRef({});
  const fileUploadRef = useRef(null);
  const navigate = useNavigate();

  const [initialFormData, setInitialFormData] = useState(formData);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSaveCanvas = () => {
    const signatureDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setImageURL(signatureDataUrl);
    setFormData((prevData) => ({
      ...prevData,
      declarationDetails: {
        ...prevData.declarationDetails, // Spread the existing personal details
        signature: signatureDataUrl, // Update gender with the new value
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      signature: null,
    }));

  };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      declarationDetails: {
        ...prevData.declarationDetails,
        [field]: value,
      },
    }));

    if (!value) {
      setErrors({
        [field]: 'Name is required',
      });
    } else {
      // Clear the specific error for the input being changed
      setErrors(null);
    }
  };

  const handleDeleteSignature = () => {
    setShowSignaturePlaceholder(true);
    setImageURL(null);
    setFormData((prevData) => ({
      ...prevData,
      declarationDetails: {
        ...prevData.declarationDetails, // Spread the existing personal details
        signature: null, // Update gender with the new value
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      signature: 'Signature is required', // Set the error if the input is empty
    }));

  };

  const handleUploadSignature = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const reqFormData = new FormData();
    reqFormData.append('fileName', file?.name);
    reqFormData.append('files', file);
    reqFormData.append('userType', user?.roleId?.name === 'Admin' ? 'MD' : 'AD');

    try {
      const res = await new UserService().fileUpload(reqFormData);
      setFormData((prevData) => ({
        ...prevData,
        declarationDetails: {
          ...prevData.declarationDetails, // Spread the existing personal details
          signature: res?.cdnUrl, // Update gender with the new value
        },
      }));
      setImageURL(res?.cdnUrl);
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleSubmit = async () => {
    setErrors({});
    const newErrors = {};
    if (!formData.signature) {
      newErrors.signature = 'signature is required';
    }
    if (!formData.signatureName) {
      newErrors.signatureName = 'signature name is required';
    }
    if (!formData.isTermsAccepted) {
      newErrors.isTermsAccepted = 'Term of service is required';
    }
    // If there are errors, set them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
      navigate('/dashboard');
      return;
    }
    const updatedFormData = {
      userId: user._id,
      isTermsAccepted: formData.isTermsAccepted,
      signature: formData.signature,
      signatureName: formData.signatureName,
      isInformationSubmitted: true,
    };
    try {
      setLoading(true);
      const response = await new UserService().updateProfile(updatedFormData);

      const updatedUserData = { ...updatedFormData, ...response };
      if (response) {
        setInitialFormData(formData);
        dispatch(updateUser(updatedUserData));
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to Submit Details', 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-10 lg:gap-8 xxl:gap-12">
        <FormSectionTitle
          heading={'Accept terms & conditions'}
          subHeading={'Provide signature and accept our T&C and privacy policies.'}
        />
        <div className="flex flex-col gap-8">
          <CustomInputField
            id={'signatureName'}
            labelText={'Name'}
            maxLength={signatureName}
            type={'text'}
            placeholder={'Enter your signature name'}
            value={formData.signatureName}
            onChange={handleInputChange('signatureName')}
            error={errors?.signatureName}
          />

          <div className="flex flex-col">
            <div className="flex items-center gap-[10px] relative">
              {!imageURL && (
                <div className="relative bg-white w-full rounded-2xl">
                  {showSignaturePlaceholder && (
                    <div className="flex flex-col justify-center items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                      <img src="./signature-icon.svg" alt="Signature-Icon" className="h-10 w-10" />
                      <p className="text-[#A0A2A7] text-[13px] uppercase">Sign Here</p>
                    </div>
                  )}
                  <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{ className: 'signatureCanvas' }}
                    onBegin={() => setShowSignaturePlaceholder(false)}
                    onEnd={handleSaveCanvas}
                  />
                </div>
              )}
              <div
                onClick={() => fileUploadRef.current.click()}
                className="w-12 h-12 rounded-xl p-3 bg-[#E7F6FC] absolute bottom-2 right-2 flex
                  justify-center items-center z-50 cursor-pointer">
                <MdOutlineFileUpload />
              </div>

              {imageURL && (
                <div className="relative bg-[#FDFDFD] h-[150px] w-full rounded-[12px] border border-solid border-[#DDDEDE] flex justify-center items-center">
                  <img src={imageURL} alt="Signature" className="h-[40%]" />
                </div>
              )}

              {imageURL && (
                <MdDelete
                  className="absolute right-[-30px] cursor-pointer h-[20px] w-[20px] text-Neutral-900"
                  onClick={handleDeleteSignature}
                />
              )}
            </div>
            {errors?.signature && (
              <p className="text-Error-500 text-sm text-left">{errors?.signature}</p>
            )}
          </div>
          <input
            type="file"
            className="hidden"
            ref={fileUploadRef}
            onChange={handleUploadSignature}
          />
          <div className="flex gap-2 py-3">
            <div
              className="w-4 h-4 flex pt-2 items-center justify-center"
              onClick={() =>
                setFormData((prevData) => ({
                  ...prevData,
                  declarationDetails: {
                    ...prevData.declarationDetails, // Spread the existing personal details
                    isTermsAccepted: !prevData.declarationDetails.isTermsAccepted, // Update gender with the new value
                  },
                }))
              }>
              {formData.isTermsAccepted ? (
                <IoIosCheckbox className="text-Neutral-900 w-5 h-5" />
              ) : (
                <MdOutlineCheckBoxOutlineBlank className="text-Neutral-900 w-5 h-5" />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between w-full">
                <label htmlFor="declaration">
                  I accept all the <span className="font-bold">terms of service</span> and
                  <span className="font-bold"> privacy policy</span>.
                </label>
              </div>

              <p className="text-[#6C6F71] text-sm w-[456px]">
                {isExpanded ? (
                  <>
                    I hereby declare that the information provided in this form is accurate and
                    complete. I confirm that any information is found incorrect and/or incomplete
                    that leads a violation of regulations may initiate legal actions, and I accept
                    that I am the responsible party for any and all charges, penalties, and
                    violations.
                  </>
                ) : (
                  'I hereby declare that the information provided in this...'
                )}
                <span
                  className="text-[#009DFF] text-sm cursor-pointer leading-[21px]"
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}>
                  {isExpanded ? ' Show less' : ' Show more'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between items-center absolute w-[480px] bottom-[10%] lg:bottom-[8%]">
          <div className='lg:hidden'>
          <StepIndicator currentStep={selectedTab} totalSteps={totalSteps} />
          </div>
          <div className="flex justify-between items-center w-full">
          <button
            className="flex justify-center items-center rounded-3xl bg-white w-25 h-12 font-bold text-base text-Neutral-900"
            onClick={() => setSelectedTab((prev) => prev - 1)}>
            Back
          </button>
          <button
            className="flex justify-center items-center rounded-3xl bg-Primary-400 w-25 h-12 font-bold text-base text-white"
            onClick={handleSubmit}>
            {loading ? <TbLoader3 className="animate-spin text-black" /> : 'Finish'}
          </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Declaration;
